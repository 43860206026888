import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import update from "immutability-helper";
import { CircleLoader } from "react-spinners";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import {
  Grid,
  List,
  ListItem,
  Card,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import Chart from "react-apexcharts";
import AGGrid from "../Grid";
import { DayPickerRangeController } from "react-dates";

import ResponsesModal from "./components/ResponsesModal";

// Responsive //
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "125px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

const themes = [
  {
    id: 0,
    name: "Off to a Good Start",
    subtext: "Starts in August or September",
  },
  {
    id: 1,
    name: "Helping with Homework",
    subtext: "Starts in September or October",
  },
  {
    id: 2,
    name: "Conference Conversations",
    subtext: "Starts in October or November",
  },
  {
    id: 3,
    name: "Doctor's Orders",
    subtext: "Starts in November or March",
  },
  {
    id: 4,
    name: "Make Time for Reading Together",
    subtext: "Starts in December",
  },
  {
    id: 5,
    name: "Spreading Kindness",
    subtext: "Starts in January",
  },
  {
    id: 6,
    name: "Arts in Education",
    subtext: "Starts in February",
  },
  {
    id: 7,
    name: "Math Matters",
    subtext: "Starts in March or April",
  },
  {
    id: 8,
    name: "Positive Parenting",
    subtext: "Starts in April or May",
  },
  {
    id: 9,
    name: "Celebrating Parents",
    subtext: "Starts in May or June",
  },
];

function ActivityAnalytics(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [timeFrame, setTimeFrame] = useState("August");
  const [analytics, setAnalytics] = useState(null);
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRangeFocus, setDateRangeFocus] = useState("startDate");

  const [responseModal, setResponseModal] = useState(false);
  const [responseModalTitle, setResponseModalTitle] = useState("");
  const [responsesInfo, setResponsesInfo] = useState([]);
  const [responsesActivityId, setResponsesActivityId] = useState("");
  const handleResponsesOpen = async (responses, activityId, activityName) => {
    setResponseModalTitle(activityName);
    setResponsesInfo(responses);
    setResponsesActivityId(activityId);
    setResponseModal(true);
  };
  const handleResponsesClose = async () => {
    setResponseModalTitle("");
    setResponsesInfo([]);
    setResponseModal(false);
  };

  useEffect(() => {
    async function fetchData(dateRange, sceneId) {
      try {
        const goal = moment().set({ date: 15 }).valueOf();
        const closestMonth = dateRange.reduce((prev, curr) =>
          Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
        );

        setTimeFrame(closestMonth);

        const startDate = moment(closestMonth).startOf("month");
        const endDate = moment(closestMonth).endOf("month");

        const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
          sceneId,
          startDate,
          endDate
        );
        setAnalytics(result);
        setInitialLoad(false);
      } catch (err) {
        console.log(err);
      }
    }

    if (props.app.dateRange && props.scene) {
      /* Closest date */
      fetchData(props.app.dateRange, props.scene.id);
    }
  }, [props.app.dateRange, props.scene]);

  const [chartView, setChartView] = useState("Activity Views");
  const changeChartView = (chartType) => {
    setChartView(chartType);
  };
  const changeTimeFrame = async (timeFrame) => {
    const sceneId = props.scene ? props.scene.id : null;
    if (!sceneId) {
      return;
    }
    if (timeFrame === "Custom" || timeFrame === "School Year") {
      return;
    }
    // Reset Date Range
    else {
      setDateRangeValue({
        startDate: null,
        endDate: null,
      });
    }
    setTimeFrame(timeFrame);

    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  const handleCloseDateRange = () => {
    setDateRangeDialog(false);
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
  };
  const handleDateRangePick = async () => {
    setTimeFrame("Custom");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = dateRangeValue.startDate
      ? moment(dateRangeValue.startDate).startOf("day")
      : dateRangeValue.endDate;
    const endDate = dateRangeValue.endDate
      ? moment(dateRangeValue.endDate).endOf("day")
      : moment(dateRangeValue.startDate).endOf("day");

    if (startDate === null && endDate === null) {
      return handleCloseDateRange();
    }

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
    setDateRangeDialog(false);
  };
  const handleSchoolYearPick = async () => {
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
    setTimeFrame("School Year");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = props.app.dateRange
      ? props.app.dateRange[0]
        ? moment(props.app.dateRange[0]).startOf("month")
        : null
      : null;
    const endDate = props.app.dateRange
      ? props.app.dateRange.length
        ? moment(props.app.dateRange[props.app.dateRange.length - 1]).endOf(
            "month"
          )
        : null
      : null;

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  const handleSendFeedback = async (response, feedback) => {
    const activityIndex = analytics.activites.findIndex(
      (i) => i.id === responsesActivityId
    );
    const activityItem = analytics.activites[activityIndex];

    /* Backend */
    await ParentPartiesAPIs.createUserNotifcation({
      activityName: activityItem.name,
      activityId: responsesActivityId,
      schoolId: props.scene.id,
      responseId: response.id,
      userId: response.userId,
      feedback,
      feedbackEmail: props.user ? props.user.email.address : "",
      responses: response.responses,
    });

    if (activityIndex > -1) {
      /* Find Response */
      const responseIndex = activityItem.responses.findIndex(
        (res) => res.id === response.id
      );
      if (responseIndex > -1) {
        const newAnalytics = update(analytics, {
          activites: {
            [activityIndex]: {
              responses: {
                [responseIndex]: {
                  $set: {
                    ...activityItem.responses[responseIndex],
                    feedback,
                    feedbackEmail: props.user ? props.user.email.address : "",
                  },
                },
              },
            },
          },
        });

        setAnalytics(newAnalytics);
      }
    }
  };

  let chartOptions = {
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
    // labels: dateRange,
    xaxis: {
      type: "datetime",
      labels: {
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      strokeDashArray: "5",
      borderColor: "rgba(125, 138, 156, 0.3)",
    },
    legend: {
      show: true,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: "MMMM yyyy",
      },
    },
  };

  const handleRenderChart = (chartView) => {
    switch (chartView) {
      case "Activity Views":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Activity Views",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityViews
                      ? analytics.charts.activityViews.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      case "Activites Completed by Type":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
              colors: [
                "#018FFB",
                "#01E396",
                "#FEB019",
                "#FF4460",
                "#785DD0",
                "#000",
              ],
            }}
            series={[
              {
                name: "Video",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity0
                    : []
                  : [],
              },
              {
                name: "Multiple Choice",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity1
                    : []
                  : [],
              },
              {
                name: "General",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity2
                    : []
                  : [],
              },
              {
                name: "File",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity4
                    : []
                  : [],
              },
              {
                name: "Link",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity5
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "Total Points":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Total Points",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.totalPoints
                      ? analytics.charts.totalPoints.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      default:
        return null;
    }
  };

  return !props.user ? null : props.scene ? (
    <>
      {initialLoad || props.app.isLoading ? (
        <Grid container className="my-spacing-6">
          <Grid item xs={12}>
            <div className="d-flex flex-row justify-content-center">
              <CircleLoader
                className="d-flex align-self-center"
                color={"#18a9a9"}
                loading={true}
                size={40}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Reporting
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/guardians-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Guardians</span>
                  <div className="divider" />
                </ListItem>
                <ListItem button disableRipple className="px-2" selected>
                  <span className="font-size-sm">Activities</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/evaluation-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Parent Evaluations</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/weekly-scorecard-analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Weekly Scorecards</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/monthly-analytics/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Monthly Analytics"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  className="pr-2"
                  component={NavLink}
                  to={`/analytics/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">{"Reports"}</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-4 mb-2">
            <div
              className="heading-4 text-left"
              style={{
                textTransform: "none",
                marginRight: "10px",
                marginBottom: 0,
                color: "#000",
              }}
            >
              {"Activity Analytics"}
            </div>
            {props.app.dateRange ? (
              <FormControl
                variant="outlined"
                margin="dense"
                style={{ boxShadow: "none" }}
              >
                <Select
                  autoWidth={false}
                  value={timeFrame}
                  renderValue={(value) => {
                    return value === "Custom"
                      ? `${
                          dateRangeValue.startDate
                            ? moment(dateRangeValue.startDate).format("ll")
                            : ""
                        }${
                          dateRangeValue.endDate
                            ? `${
                                dateRangeValue.startDate
                                  ? ` - ${moment(dateRangeValue.endDate).format(
                                      "ll"
                                    )}`
                                  : moment(dateRangeValue.endDate).format("ll")
                              }`
                            : ""
                        }`
                      : value === "School Year"
                      ? "School Year"
                      : moment(value).format("MMMM YYYY");
                  }}
                  onChange={(e) => {
                    changeTimeFrame(e.target.value);
                  }}
                  classes={{
                    select: classes.selectStyles,
                  }}
                  input={
                    <OutlinedInput
                      classes={{
                        root: classes.fixInput,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                      }}
                    />
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {(props.app.dateRange ? props.app.dateRange : []).map(
                    (date) => (
                      <MenuItem value={date} dense key={date}>
                        {moment(date).format("MMMM YYYY")}
                      </MenuItem>
                    )
                  )}
                  <MenuItem
                    value={"School Year"}
                    dense
                    onClick={() => {
                      handleSchoolYearPick();
                    }}
                  >
                    School Year
                  </MenuItem>
                  <MenuItem
                    value={"Custom"}
                    dense
                    onClick={() => {
                      setDateRangeDialog(true);
                    }}
                  >
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </div>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Card
                className="p-3"
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{ boxShadow: "none" }}
                >
                  <Select
                    value={chartView}
                    onChange={(e) => {
                      changeChartView(e.target.value);
                    }}
                    classes={{
                      select: classes.selectStyles,
                    }}
                    input={
                      <OutlinedInput
                        classes={{
                          root: classes.fixInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        }}
                      />
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={"Activity Views"} dense>
                      Activity Views
                    </MenuItem>
                    <MenuItem value={"Activites Completed by Type"} dense>
                      Activites Completed by Type
                    </MenuItem>
                    <MenuItem value={"Total Points"} dense>
                      Total Points
                    </MenuItem>
                  </Select>
                </FormControl>
                {handleRenderChart(chartView)}
              </Card>
            </Grid>
            <Grid item md={12}>
              <div
                className="heading-4 text-left"
                style={{
                  textTransform: "none",
                  marginRight: "10px",
                  marginBottom: 0,
                  color: "#000",
                  marginTop: "3rem",
                }}
              >
                {"Activity Performance & Responses"}
              </div>
              <div className="mt-4">
                <AGGrid
                  rowData={
                    analytics
                      ? analytics.activites
                        ? analytics.activites
                        : []
                      : []
                  }
                  search={false}
                  autoHeight={true}
                  columnDefs={[
                    {
                      headerName: "Name",
                      field: "name",
                      sortable: true,
                      filter: true,
                    },
                    {
                      headerName: "Views",
                      field: "views",
                      sortable: true,
                      filter: true,
                    },
                    {
                      headerName: "Completions",
                      field: "timesCompleted",
                      sortable: true,
                      filter: true,
                    },
                    {
                      headerName: "Theme",
                      field: "theme",
                      sortable: true,
                      filter: true,
                      valueGetter: function (params) {
                        if (params.data.theme !== undefined) {
                          return themes.find(
                            (theme) => theme.id === params.data.theme
                          )
                            ? themes.find(
                                (theme) => theme.id === params.data.theme
                              ).name
                            : "";
                        } else {
                          return null;
                        }
                      },
                    },
                    {
                      width: 280,
                      suppressSizeToFit: true,
                      field: "actions",
                      headerName: "Actions",
                      cellRendererFramework: (params) => (
                        <div className="d-flex flex-row align-items-center">
                          {params.data.responses ? (
                            params.data.responses.length ? (
                              <btn
                                className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  handleResponsesOpen(
                                    params.data.responses,
                                    params.data.id,
                                    params.data.name
                                  );
                                }}
                              >
                                <span className="MuiButton-label">
                                  <span
                                    className="btn-wrapper--label text-white"
                                    style={{ fontSize: "12px" }}
                                  >
                                    View Responses
                                  </span>
                                </span>
                                <span className="MuiTouchRipple-root"></span>
                              </btn>
                            ) : null
                          ) : null}
                        </div>
                      ),
                      cellClass: "agGridCellCenter",
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={dateRangeDialog}
        onClose={handleCloseDateRange}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            // borderBottom: "none",
          }}
        >
          Select Date Range
        </DialogTitle>
        <DialogContent
          dividers={false}
          className="d-flex flex-row justify-content-center my-4"
        >
          <DayPickerRangeController
            numberOfMonths={2}
            onDatesChange={(dates) => {
              setDateRangeValue({
                startDate: dates.startDate,
                endDate: dates.endDate,
              });
            }}
            startDate={dateRangeValue.startDate}
            endDate={dateRangeValue.endDate}
            focusedInput={dateRangeFocus}
            onFocusChange={(focusedInput) => {
              setDateRangeFocus(!focusedInput ? "startDate" : focusedInput);
            }}
            hideKeyboardShortcutsPanel={true}
          />
        </DialogContent>
        <DialogActions className="p-3">
          <Button onClick={handleCloseDateRange}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={handleDateRangePick}
            autoFocus
          >
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      {/* Responses Modal */}
      <ResponsesModal
        responseModalTitle={responseModalTitle}
        responses={responsesInfo}
        responseModal={responseModal}
        handleResponsesClose={handleResponsesClose}
        handleSendFeedback={handleSendFeedback}
      />
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityAnalytics);
