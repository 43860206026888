import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ActivityServices,
  SchoolServices,
  updateSchoolAction,
  School,
} from "../../core";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  DialogContent,
  Snackbar,
  Divider,
  Card,
} from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AGGrid from "../Grid";
import Bugsnag from "@bugsnag/js";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/* Dialogs */
import PreviewActivity from "./components/previewActivity";
import AddActivityDialog from "./components/addActivityDialog";
import AddActivityDialog2 from "../AdminEditCurriculum/components/addActivityDialog";
import SchoolActivityDialog from "./components/schoolActivityDialog";

/* PDF */
import * as PDFJS from "pdfjs-dist/es5/build/pdf";
import { pdfjsworker } from "pdfjs-dist/es5/build/pdf.worker.entry";
import { ActivityCard } from "./components/activityCard";

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsworker;

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64
const convertPdfToImages = async (file) => {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;

    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    images.push(blob);
  }
  canvas.remove();
  return images;
};

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const handleThemeMonth = (schedule, themeId) => {
  switch (themeId) {
    case 0:
      return schedule === "August" ? "August" : "September";
    case 1:
      return schedule === "August" ? "September" : "October";
    case 2:
      return schedule === "August" ? "October" : "November";
    case 3:
      return schedule === "August" ? "November" : "March";
    case 4:
      return "December";
    case 5:
      return "January";
    case 6:
      return "February";
    case 7:
      return schedule === "August" ? "March" : "April";
    case 8:
      return schedule === "August" ? "April" : "May";
    case 9:
      return schedule === "August" ? "May" : "June";
    default:
      return "";
  }
};

const ActivieThemeSection = (props) => {
  const {
    schedule,
    theme,
    schoolActivities,
    setPreviewDialog,
    setPreviewDialogInfo,
    sceneType,
    sceneId,
    setDeleteDialogInfo,
    setDeleteDialog,
    handleAddActivityDialogOpen,
    simpleInterface,
  } = props;

  return (
    <Grid item md={12} xl={12}>
      <Card
        className="p-0"
        style={{
          borderRadius: "0.25rem",
          border: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <div className="inline-flex align-items-center">
                <div
                  className="heading-4 text-left text-black"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                  }}
                >
                  {handleThemeMonth(schedule, theme.id)}
                </div>
              </div>
              <div
                className="d-block text-black-50 text-left mt-1 font-size-sm"
                style={{ paddingRight: "2rem" }}
              >
                {theme.name}
              </div>
            </div>
            {simpleInterface ? null : (
              <Button
                variant="contained"
                size="medium"
                className="btn-info shadow-none ml-4"
                onClick={() => handleAddActivityDialogOpen(theme.id)}
              >
                <span className="btn-wrapper--icon mr-1">
                  <FontAwesomeIcon
                    style={{ fontSize: "12px" }}
                    icon={["fas", "edit"]}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: "12px" }}
                >
                  Edit Activities
                </span>
              </Button>
            )}
          </div>
        </div>
        <div className="pt-1 px-3 pb-4">
          <Grid container spacing={4}>
            {schoolActivities.map((activity) => (
              <Grid item md={6} lg={6} xl={6} sm={12} key={activity.id}>
                <ActivityCard
                  activity={activity}
                  setPreviewDialog={setPreviewDialog}
                  setPreviewDialogInfo={setPreviewDialogInfo}
                  sceneType={sceneType}
                  sceneId={sceneId}
                  setDeleteDialogInfo={setDeleteDialogInfo}
                  setDeleteDialog={setDeleteDialog}
                />
              </Grid>
            ))}
          </Grid>
          {/* <AGGrid
            rowData={schoolActivities}
            search={false}
            autoHeight={true}
            columnDefs={[
              {
                headerName: "Activity Name",
                field: "name",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Activity Type",
                field: "type",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.type !== undefined) {
                    return handleActivityType(params.data.type);
                  } else {
                    return null;
                  }
                },
              },
              {
                headerName: "Points",
                field: "points",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Status",
                field: "status",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.status) {
                    if (params.data.status === "draft") {
                      return "Draft";
                    } else {
                      return "Live";
                    }
                  }
                  return "Live";
                },
              },
              {
                width: 380,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) =>
                  params.data.allSchools ? (
                    <div className="d-flex flex-row align-items-center">
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                        onClick={() => {
                          setPreviewDialogInfo(params.data);
                          setPreviewDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Preview
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                      <span className="text-black-50">
                        Parent Parties Engage
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex flex-row align-items-center">
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                        onClick={() => {
                          setPreviewDialogInfo(params.data);
                          setPreviewDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Preview
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                      <NavLink
                        to={{
                          pathname: `/edit-activity/${sceneType}/${sceneId}`,
                          type: "edit",
                          activityData: params.data,
                        }}
                        style={{ lineHeight: 0 }}
                      >
                        <button
                          className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                          tabIndex="0"
                          type="button"
                        >
                          <span className="MuiButton-label">
                            <span
                              className="btn-wrapper--label text-white"
                              style={{ fontSize: "12px" }}
                            >
                              Edit
                            </span>
                          </span>
                          <span className="MuiTouchRipple-root"></span>
                        </button>
                      </NavLink>
                      {params.data ? (
                        params.data.translationData ? (
                          <NavLink
                            to={{
                              pathname: `/edit-translation/${sceneType}/${sceneId}`,
                              type: "activity",
                              data: params.data,
                            }}
                            style={{ lineHeight: 0 }}
                          >
                            <button
                              className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-info shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                              tabIndex="0"
                              type="button"
                            >
                              <span className="MuiButton-label">
                                <span
                                  className="btn-wrapper--label text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  Translations
                                </span>
                              </span>
                              <span className="MuiTouchRipple-root"></span>
                            </button>
                          </NavLink>
                        ) : null
                      ) : null}
                      <button
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                        onClick={() => {
                          setDeleteDialogInfo(params.data);
                          setDeleteDialog(true);
                        }}
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Delete
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  ),
                cellClass: "agGridCellCenter",
              },
            ]}
          /> */}
        </div>
      </Card>
    </Grid>
  );
};

function SchoolActivities(props) {
  const { scene } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [schoolActivities, setSchoolActivities] = useState([]);
  const [inSchoolActivities, setInSchoolActivites] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewDialogInfo, setPreviewDialogInfo] = useState(null);
  const handlePreviewClose = () => {
    setPreviewDialog(false);
  };

  const [addActivityDialog, setAddActivityDialog] = useState(false);
  const [addActivityDialogInfo, setAddActivityDialogInfo] = useState(null);
  const handleAddActivityDialogOpen = (themeId) => {
    setAddActivityDialogInfo(themeId);
    setAddActivityDialog(true);
  };
  const handleAddActivityDialogClose = () => {
    setAddActivityDialog(false);
  };

  const handleUpdateCurriculumThemeActivities = async (id, activityIds) => {
    const idIndex = curriculum.themes.findIndex((theme) => theme.id === id);

    const newSchoolThemes = props.scene.data.schoolThemes;
    newSchoolThemes[idIndex].activityIds = activityIds;

    const schoolToUpdate = new School({
      ...props.scene.data,
      schoolThemes: newSchoolThemes,
    });
    props.updateSchoolAction(schoolToUpdate);

    setAddActivityDialog(false);
  };

  /* Activity Option Modal */
  const [optionModal, setOptionModal] = useState(false);
  const handleOptionModalClose = () => {
    setOptionModal(false);
  };

  /* School Activity Modal */
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [schoolActivityDialog, setSchoolActivityDialog] = useState(false);
  const [schoolActivityItem, setSchoolActivityItem] = useState(null);
  const handleSchoolActivityDialogOpen = () => {
    setOptionModal(false);
    setSchoolActivityDialog(true);
  };
  const handleSchoolActivityDialogClose = () => {
    setSchoolActivityDialog(false);
    setSchoolActivityItem(null);
  };
  const addSchoolActivity = async (data) => {
    setLoadingActivity(true);

    // Handle PDF files
    let parsedFiles = [];
    if (data.evidenceFiles) {
      if (data.evidenceFiles.length) {
        await Promise.all(
          data.evidenceFiles.map(async (evidenceFile) => {
            if (evidenceFile.file) {
              if (
                evidenceFile.file instanceof File ||
                evidenceFile.file instanceof Blob ||
                evidenceFile.file._data
              ) {
                if (["application/pdf"].includes(evidenceFile.file.type)) {
                  const images = await convertPdfToImages(evidenceFile.file);

                  images.map((image, index) => {
                    parsedFiles.push({
                      ...evidenceFile,
                      name: evidenceFile.name.includes(".pdf")
                        ? `${evidenceFile.name.split(".pdf")[0]}_page${
                            index + 1
                          }.png`
                        : `${evidenceFile.name}_page${index + 1}`,
                      file: image,
                      fileType: "image/png",
                    });
                    return image;
                  });
                  return;
                }
              }
            }
            parsedFiles.push(evidenceFile);
          })
        );
      }
    }

    /* Set parsed files as evidenceFiles */
    data.evidenceFiles = parsedFiles;

    const inSchoolActivity = await ActivityServices.addInSchoolActivity(
      data,
      sceneId
    );

    setInSchoolActivites([...inSchoolActivities, inSchoolActivity]);
    setSchoolActivityDialog(false);
    setLoadingActivity(false);

    setSnackBarType("success");
    setToastMessage(`In-school activity added.`);
    setToastOpen(true);
  };
  const editSchoolActivity = async (data) => {
    setLoadingActivity(true);

    // Handle PDF files
    let parsedFiles = [];
    if (data.evidenceFiles) {
      if (data.evidenceFiles.length) {
        await Promise.all(
          data.evidenceFiles.map(async (evidenceFile) => {
            if (evidenceFile.file) {
              if (
                evidenceFile.file instanceof File ||
                evidenceFile.file instanceof Blob ||
                evidenceFile.file._data
              ) {
                if (["application/pdf"].includes(evidenceFile.file.type)) {
                  const images = await convertPdfToImages(evidenceFile.file);

                  images.map((image, index) => {
                    parsedFiles.push({
                      ...evidenceFile,
                      name: evidenceFile.name.includes(".pdf")
                        ? `${evidenceFile.name.split(".pdf")[0]}_page${
                            index + 1
                          }.png`
                        : `${evidenceFile.name}_page${index + 1}`,
                      file: image,
                      fileType: "image/png",
                    });
                    return image;
                  });
                  return;
                }
              }
            }
            parsedFiles.push(evidenceFile);
          })
        );
      }
    }

    /* Set parsed files as evidenceFiles */
    data.evidenceFiles = parsedFiles;

    const updatedInSchoolActivity = await ActivityServices.editInSchoolActivity(
      data,
      sceneId
    );

    const activityIndex = inSchoolActivities.findIndex((i) => i.id === data.id);
    const updatedActivities = update(inSchoolActivities, {
      [activityIndex]: {
        $set: updatedInSchoolActivity,
      },
    });

    setInSchoolActivites(updatedActivities);
    setSchoolActivityDialog(false);
    setLoadingActivity(false);
    setSchoolActivityItem(null);

    setSnackBarType("success");
    setToastMessage(`In-school activity updated.`);
    setToastOpen(true);
  };
  const handleDeleteInSchoolActivity = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ActivityServices.deleteInSchoolActivity(
            sceneId,
            deleteDialogInfo.id
          );
          setInSchoolActivites(
            inSchoolActivities.filter(
              (activity) => activity.id !== deleteDialogInfo.id
            )
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  const handleDeleteActivity = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ActivityServices.deleteActivity(deleteDialogInfo);
          setSchoolActivities(
            schoolActivities.filter(
              (activity) => activity.id !== deleteDialogInfo.id
            )
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
      Bugsnag.notify(err);
    }
  };

  /* Curriculum */
  const [curriculum, setCurriculum] = useState(null);

  useEffect(() => {
    async function getSchoolActivities(schoolId) {
      try {
        if (scene) {
          if (scene.data) {
            if (scene.data.curriculum) {
              const curriculum = await SchoolServices.readCurriculum(
                scene.data.curriculum
              );
              const schoolThemes = scene.data.schoolThemes;

              const filteredThemes = curriculum.themes.filter((i) =>
                schoolThemes
                  ? schoolThemes.find((j) => j.id === i.id && j.name === i.name)
                  : false
              );

              const newCurriculum = filteredThemes.length
                ? {
                    ...curriculum,
                    themes: curriculum.themes.map((i) => {
                      const schoolThemActivities =
                        schoolThemes.find(
                          (j) => j.id === i.id && j.name === i.name
                        ).activityIds || [];
                      return {
                        ...i,
                        activityIds: [
                          // ...(i.activityIds || []),
                          ...schoolThemActivities,
                        ],
                      };
                    }),
                  }
                : curriculum;

              setCurriculum(newCurriculum);

              const schoolActivities = await ActivityServices.readSchoolActivities(
                schoolId
              );
              setSchoolActivities(schoolActivities);

              const inSchoolActivitiesResult = await ActivityServices.readInSchoolActivities(
                schoolId
              );
              setInSchoolActivites(inSchoolActivitiesResult);
            }
          }
        }
        setInitialLoad(false);
      } catch (err) {
        console.log("error", err);
      }
    }

    if (scene) {
      if (scene.data) {
        getSchoolActivities(scene.id);
      }
    }
  }, [scene]);

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const handleActivityType = (type) => {
    switch (type) {
      case 0:
        return "Video";
      case 1:
      case 6:
        return "Multiple Choice";
      case 2:
      case 3:
      case 7:
        return "General";
      case 4:
        return "File";
      case 5:
        return "Link";
      default:
        return null;
    }
  };

  const schoolPlan = props.scene
    ? props.scene.data
      ? props.scene.data.plan
      : "None"
    : "None";

  const simpleInterface = props.scene
    ? props.scene.data
      ? props.scene.data.simpleInterface
      : false
    : false;

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                {simpleInterface ? "App Content" : "Create App Content"}
              </div>
              {simpleInterface ? null : (
                <Button
                  variant="contained"
                  size="medium"
                  className="btn-success shadow-none ml-4"
                  onClick={() => setOptionModal(true)}
                >
                  <span className="btn-wrapper--icon mr-1">
                    <FontAwesomeIcon
                      style={{ fontSize: "12px" }}
                      icon={["fas", "plus"]}
                    />
                  </span>
                  <span
                    className="btn-wrapper--label"
                    style={{ fontSize: "12px" }}
                  >
                    Add Activity
                  </span>
                </Button>
              )}
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem button selected className="pr-2">
                  <span className="font-size-sm">{"Your Content"}</span>
                  <div className="divider" />
                </ListItem>
                {simpleInterface ? null : (
                  <ListItem
                    button
                    disableRipple
                    className="px-2"
                    component={NavLink}
                    to={`/parent-evaluations/${sceneType}/${sceneId}`}
                  >
                    <span className="font-size-sm">Evaluations</span>
                    <div className="divider" />
                  </ListItem>
                )}
                {simpleInterface ? null : (
                  <ListItem
                    button
                    disableRipple
                    className="px-2"
                    component={NavLink}
                    to={`/weekly-scorecard/${sceneType}/${sceneId}`}
                  >
                    <span className="font-size-sm">Weekly Scorecard</span>
                    <div className="divider" />
                  </ListItem>
                )}
                {simpleInterface ? null : (
                  <ListItem
                    button
                    disableRipple
                    className="px-2"
                    component={NavLink}
                    to={`/resources-file-hosting/${sceneType}/${sceneId}`}
                  >
                    <span className="font-size-sm">School Files</span>
                    <div className="divider" />
                  </ListItem>
                )}
              </List>
            )}
          </Grid>
          {/* Themes */}
          <Grid item md={12} className="mt-4 pt-4">
            <Grid container spacing={3}>
              {curriculum
                ? curriculum.themes
                  ? curriculum.themes.map((theme) => {
                      return (
                        <ActivieThemeSection
                          schedule={
                            props.scene
                              ? props.scene.data
                                ? props.scene.data.schedule
                                  ? props.scene.data.schedule
                                  : ""
                                : ""
                              : ""
                          }
                          key={theme.id}
                          theme={theme}
                          schoolActivities={schoolActivities.filter(
                            (act) =>
                              (theme.activityIds || []).find(
                                (o) => o === act.id
                              ) ||
                              (props.scene
                                ? props.scene.data
                                  ? props.scene.data.schoolThemes || []
                                  : []
                                : []
                              ).find(
                                (o) =>
                                  o.id === theme.id &&
                                  (o.activityIds || []).find(
                                    (i) => i === act.id
                                  )
                              )
                          )}
                          handleActivityType={handleActivityType}
                          setPreviewDialog={setPreviewDialog}
                          setPreviewDialogInfo={setPreviewDialogInfo}
                          setDeleteDialog={setDeleteDialog}
                          setDeleteDialogInfo={setDeleteDialogInfo}
                          handleAddActivityDialogOpen={
                            handleAddActivityDialogOpen
                          }
                          sceneType={sceneType}
                          sceneId={sceneId}
                          simpleInterface={simpleInterface}
                        />
                      );
                    })
                  : null
                : null}
              <Grid item md={12} xl={12}>
                <Divider className="mb-5" />
                <div className="inline-flex align-items-center">
                  <div
                    className="heading-4 text-left"
                    style={{
                      textTransform: "none",
                      marginRight: "10px",
                      marginBottom: 0,
                    }}
                  >
                    In-school Activites
                  </div>
                </div>
                <div className="mt-2">
                  <small
                    className="d-block text-black-50 text-left"
                    style={{ marginBottom: "1rem", paddingRight: "2rem" }}
                  >
                    Recorded in-school activities that will be added to your
                    generated reports.
                  </small>
                </div>
                <AGGrid
                  rowData={inSchoolActivities}
                  search={false}
                  autoHeight={true}
                  columnDefs={[
                    {
                      headerName: "Date",
                      field: "date",
                      sortable: true,
                    },
                    {
                      headerName: "Topic",
                      field: "topic",
                      sortable: true,
                    },
                    {
                      headerName: "Attendees",
                      field: "attendees",
                      sortable: true,
                    },
                    {
                      width: 280,
                      suppressSizeToFit: true,
                      field: "actions",
                      headerName: "Actions",
                      cellRendererFramework: (params) => (
                        <div className="d-flex flex-row align-items-center">
                          <button
                            className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            tabIndex="0"
                            type="button"
                            onClick={() => {
                              setSchoolActivityItem(params.data);
                              handleSchoolActivityDialogOpen();
                            }}
                          >
                            <span className="MuiButton-label">
                              <span
                                className="btn-wrapper--label text-white"
                                style={{ fontSize: "12px" }}
                              >
                                Edit
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>
                          <button
                            className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                            tabIndex="0"
                            type="button"
                            onClick={() => {
                              setDeleteDialogInfo(params.data);
                              setDeleteDialog(true);
                            }}
                          >
                            <span className="MuiButton-label">
                              <span
                                className="btn-wrapper--label text-white"
                                style={{ fontSize: "12px" }}
                              >
                                Delete
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>
                        </div>
                      ),
                      cellClass: "agGridCellCenter",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={deleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"sm"}
          PaperProps={{
            className: "p-3",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "rgb(42, 42, 47)",
              borderBottom: "none",
            }}
          >
            {`Are you sure you want to delete activity${
              deleteDialogInfo
                ? deleteDialogInfo.name
                  ? `: ${deleteDialogInfo.name}`
                  : ""
                : ""
            }?`}
          </DialogTitle>
          <DialogActions style={{ borderTop: "none" }} className="mt-4">
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button
              className="btn-danger"
              onClick={() =>
                deleteDialogInfo
                  ? deleteDialogInfo.name
                    ? handleDeleteActivity()
                    : handleDeleteInSchoolActivity()
                  : null
              }
              autoFocus
            >
              {"Delete Activity"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={previewDialog}
          onClose={handlePreviewClose}
          fullWidth={true}
          maxWidth={"md"}
          PaperProps={{
            className: "p-3",
          }}
        >
          <DialogTitle
            style={{
              borderBottom: "none",
            }}
            onClose={handlePreviewClose}
            row={true}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: "rgb(42, 42, 47)",
              }}
            >
              {previewDialogInfo ? `Preview: ${previewDialogInfo.name}` : ""}
            </div>
          </DialogTitle>
          <DialogContent dividers={true} className="pb-4 pt-4">
            {previewDialogInfo ? (
              <PreviewActivity activity={previewDialogInfo} />
            ) : null}
          </DialogContent>
        </Dialog>
        <AddActivityDialog
          optionModal={optionModal}
          handleOptionModalClose={handleOptionModalClose}
          handleSchoolActivityDialogOpen={handleSchoolActivityDialogOpen}
          schoolPlan={schoolPlan}
          sceneType={sceneType}
          sceneId={sceneId}
        />
        <SchoolActivityDialog
          schoolActivityDialog={schoolActivityDialog}
          handleSchoolActivityDialogClose={handleSchoolActivityDialogClose}
          addSchoolActivity={addSchoolActivity}
          editSchoolActivity={editSchoolActivity}
          schoolActivityItem={schoolActivityItem}
          loadingActivity={loadingActivity}
        />
        {/* <CurriculumDialog
          curriculums={curriculums}
          curriculumDialog={curriculumDialog}
          handleCurriculumDialogClose={handleCurriculumDialogClose}
          handleCurriculumDialogChange={handleCurriculumDialogChange}
          saveCurriculumChange={saveCurriculumChange}
          curriculumDialogInfo={curriculumDialogInfo}
        /> */}
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toastOpen}
          classes={{
            root: snackBarType === "error" ? `toastr-danger` : `toastr-success`,
          }}
          onClose={() => setToastOpen(false)}
          message={toastMessage}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setToastOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
        <AddActivityDialog2
          activityPool={schoolActivities.filter((i) => i.schoolId)}
          addActivityDialog={addActivityDialog}
          addActivityDialogInfo={addActivityDialogInfo}
          handleAddActivityDialogClose={handleAddActivityDialogClose}
          themes={curriculum.themes}
          setPreviewDialog={setPreviewDialog}
          setPreviewDialogInfo={setPreviewDialogInfo}
          handleUpdateCurriculumThemeActivities={
            handleUpdateCurriculumThemeActivities
          }
        />
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
  updateSchoolAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolActivities);
