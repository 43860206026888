import { Card } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const handleActivityType = (type) => {
  switch (type) {
    case 0:
      return "Video";
    case 1:
    case 6:
      return "Multiple Choice";
    case 2:
    case 3:
    case 7:
      return "General";
    case 4:
      return "File";
    case 5:
      return "Link";
    default:
      return null;
  }
};

export const ActivityCard = ({
  activity,
  setPreviewDialogInfo,
  setPreviewDialog,
  sceneType,
  sceneId,
  setDeleteDialogInfo,
  setDeleteDialog,
}) => {
  return (
    <Card
      style={{
        borderColor: "rgba(0,0,0,0.1)",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          flex: 1,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            alt={activity.name}
            src={activity.thumbnail}
            style={{
              width: 80,
              height: 80,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: "#D2D3D2",
            }}
          />
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                color: "#000",
                fontSize: 15,
                // lineHeight: 25,
              }}
            >
              {activity.name}
            </div>
            <div
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#979797",
                  //   fontWeight: "500",
                  fontSize: 13,
                  //   lineHeight: 20,
                }}
              >
                {handleActivityType(activity.type)}
              </div>
              <div
                style={{
                  marginLeft: 7,
                  marginRight: 7,
                  color: "#979797",
                  fontSize: 13,
                  //   lineHeight: 20,
                  //   fontWeight: "200",
                }}
              >
                |
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#979797",
                    fontSize: 13,
                    // lineHeight: 20,
                    // fontWeight: "500",
                    marginRight: 6,
                  }}
                >
                  {`${activity.points} points`}
                </div>
                {/* <MaterialIcon size={17} color={'#e0a838'} name={'stars'} /> */}
              </div>
            </div>
            {activity.repeatable ? (
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                {/* <MaterialIcon name="sync" size={15} color="#3C947F" /> */}
                <div
                  style={{
                    color: "#3C947F",
                    fontSize: "14px",
                    // fontSize: 13,
                    // lineHeight: 20,
                    // marginLeft: 5,
                  }}
                >
                  {"Repeatable once a day"}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          borderTop: "1px solid #d3d3d3",
          padding: "8px",
        }}
      >
        {activity.allSchools ? (
          <div className="d-flex flex-row align-items-center justify-content-end">
            <span className="text-black-50" style={{ fontSize: "12px" }}>
              Parent Parties Engage
            </span>
            <button
              className="ml-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
              tabIndex="0"
              type="button"
              onClick={() => {
                setPreviewDialogInfo(activity);
                setPreviewDialog(true);
              }}
            >
              <span className="MuiButton-label">
                <span
                  className="btn-wrapper--label text-white"
                  style={{ fontSize: "12px" }}
                >
                  Preview
                </span>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center justify-content-end">
            <button
              className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
              tabIndex="0"
              type="button"
              onClick={() => {
                setPreviewDialogInfo(activity);
                setPreviewDialog(true);
              }}
            >
              <span className="MuiButton-label">
                <span
                  className="btn-wrapper--label text-white"
                  style={{ fontSize: "12px" }}
                >
                  Preview
                </span>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
            <NavLink
              to={{
                pathname: `/edit-activity/${sceneType}/${sceneId}`,
                type: "edit",
                activityData: activity,
              }}
              style={{ lineHeight: 0 }}
            >
              <button
                className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                tabIndex="0"
                type="button"
              >
                <span className="MuiButton-label">
                  <span
                    className="btn-wrapper--label text-white"
                    style={{ fontSize: "12px" }}
                  >
                    Edit
                  </span>
                </span>
                <span className="MuiTouchRipple-root"></span>
              </button>
            </NavLink>
            {activity ? (
              activity.translationData ? (
                <NavLink
                  to={{
                    pathname: `/edit-translation/${sceneType}/${sceneId}`,
                    type: "activity",
                    data: activity,
                  }}
                  style={{ lineHeight: 0 }}
                >
                  <button
                    className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-info shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                    tabIndex="0"
                    type="button"
                  >
                    <span className="MuiButton-label">
                      <span
                        className="btn-wrapper--label text-white"
                        style={{ fontSize: "12px" }}
                      >
                        Translations
                      </span>
                    </span>
                    <span className="MuiTouchRipple-root"></span>
                  </button>
                </NavLink>
              ) : null
            ) : null}
            <button
              className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
              tabIndex="0"
              type="button"
              onClick={() => {
                setDeleteDialogInfo(activity);
                setDeleteDialog(true);
              }}
            >
              <span className="MuiButton-label">
                <span
                  className="btn-wrapper--label text-white"
                  style={{ fontSize: "12px" }}
                >
                  Delete
                </span>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};
