export interface ActivityRecord {
  id?: string;
  theme: number;
  allSchools: boolean;
  schoolId?: string | undefined;
  type: number;
  points: number;
  repeatable?: boolean;
  lastUpdated?: number;
  // English (Default)
  name: string;
  information?: string;
  quiz?: any[];
  thumbnail?: string | File | Blob | any;
  video?: any;
  videoYoutube?: any;

  // School Activity
  schoolFiles?: any[];

  // Link
  links?: any[];

  // Draft / Live Status
  status?: any;

  // Translation targets
  translationTargets?: any;

  gradeLevels?: any[];

  // General
  verificationType?: any;
}

export class Activity {
  id?: string;
  theme: number;
  readonly allSchools: boolean;
  readonly schoolId?: string | undefined;
  readonly type: number;
  readonly points: number;
  readonly repeatable?: boolean;
  lastUpdated?: number;
  // English
  readonly name: string;
  readonly information?: string;
  readonly quiz?: any[];
  public thumbnail?: string | File | Blob | any;
  public video?: any;
  public videoYoutube?: any;

  // School Activity
  public schoolFiles?: any[];

  // School Activity
  public links?: any[];

  // Draft / Live Status
  public status?: any;

  public translationTargets?: any;

  readonly gradeLevels?: any[];

  readonly verificationType?: any;

  constructor(activity: ActivityRecord) {
    const {
      id,
      theme,
      allSchools,
      schoolId,
      type,
      points,
      repeatable,
      lastUpdated,
      name,
      information,
      thumbnail,
      video,
      videoYoutube,
      quiz,
      schoolFiles,
      links,
      status,
      translationTargets,
      gradeLevels,
      verificationType,
    } = activity;

    if (this.isInvalidString(name)) {
      throw new Error("Name cannot be empty");
    }

    this.id = id;
    this.theme = theme;
    this.allSchools = allSchools;
    this.schoolId = schoolId;
    this.type = type;
    this.points = points;
    this.repeatable = repeatable;
    this.lastUpdated = lastUpdated;
    // English
    this.name = name;
    this.information = information;
    this.quiz = quiz;
    this.thumbnail = thumbnail;
    this.video = video;
    this.videoYoutube = videoYoutube;

    // School Activity
    this.schoolFiles = schoolFiles;

    // Links
    this.links = links;

    // Draft
    this.status = status;

    // Translation
    this.translationTargets = translationTargets;

    // Translation
    this.gradeLevels = gradeLevels;

    // General
    this.verificationType = verificationType;
  }

  private isInvalidString(str: string): boolean {
    return !str || str.trim().length === 0;
  }
}
