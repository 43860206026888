import { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  Check,
} from "@material-ui/icons";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  Card,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  Typography,
  Switch,
  Select,
  OutlinedInput,
  MenuItem,
  Table,
  FormLabel,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import PreviewApp from "./previewApp";
import moment from "moment";
import ReactQuill from "react-quill";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const VideoDropzone = (props) => {
  const {
    videoUpload,
    setFormErrors,
    formErrors,
    errorField,
    setUnsavedChanges,
  } = props;

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "video/mp4,video/x-m4v,video/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      //   const MAX_SIZE = 31457280;
      //   const bigFile = (file) => file.size > MAX_SIZE;
      //   if (acceptedFiles.some(bigFile) === true) {
      //     alert("File Size Limit: 32 MB");
      //     return;
      //   }
      const videoFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      videoUpload(videoFile);
      setUnsavedChanges(true);
      setFormErrors({ ...formErrors, [errorField]: false });
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                Video will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                <CloudUploadTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm">
                Drag and drop video here{" "}
                <span className="font-size-xs text-dark">(MP4)</span>
              </div>
            </div>
          )}
          <small className="py-2 text-black-50">or</small>
          <div>
            <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
              <span className="px-2">Browse Files</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThumbnailDropzone = (props) => {
  const {
    thumbnailUpload,
    setFormErrors,
    formErrors,
    errorField,
    setUnsavedChanges,
  } = props;

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const MAX_SIZE = 31457280;
      const bigFile = (file) => file.size > MAX_SIZE;
      if (acceptedFiles.some(bigFile) === true) {
        alert("File Size Limit: 32 MB");
        return;
      }
      const thumbnailFile = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      thumbnailUpload(thumbnailFile);
      setUnsavedChanges(true);
      setFormErrors({ ...formErrors, [errorField]: false });
    },
  });

  return (
    <div className="dropzone">
      <div
        {...getRootProps({
          className: "dropzone-upload-wrapper",
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-inner-wrapper">
          {isDragAccept && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                <CheckIcon className="d-50" />
              </div>
              <div className="font-size-sm text-success">
                File will be uploaded!
              </div>
            </div>
          )}
          {isDragReject && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                <CloseTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm text-danger">
                File will be rejected!
              </div>
            </div>
          )}
          {!isDragActive && (
            <div>
              <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                <CloudUploadTwoToneIcon className="d-50" />
              </div>
              <div className="font-size-sm">
                Drag and drop image here{" "}
                <span className="font-size-xs text-dark">(JPEG, PNG)</span>
              </div>
            </div>
          )}
          <small className="py-2 text-black-50">or</small>
          <div>
            <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
              <span className="px-2">Browse Files</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SchoolFileButton = (props) => {
  const { toggleDialogFileName } = props;

  const { open, getInputProps } = useDropzone({
    accept:
      "image/jpeg, image/png, application/pdf, video/mp4, video/x-m4v, video/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      const resourceFile = {
        file: file,
        name: file.name,
        fileType: file.type,
        hosting: true,
        tempId: moment().valueOf(),
      };
      toggleDialogFileName(resourceFile);
    },
  });

  return (
    <div>
      <input {...getInputProps()} style={{ display: "none" }} />
      <Button
        variant="contained"
        size="small"
        className="btn-success shadow-none mt-2 py-2"
        onClick={open}
      >
        <span className="btn-wrapper--icon mr-1">
          <FontAwesomeIcon
            style={{ fontSize: "12px" }}
            icon={["fas", "plus"]}
          />
        </span>
        <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
          Add File
        </span>
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
  },
  optionTextFieldFocused: {},
  optionTextFieldUnderline: {
    "&:after": {
      borderBottom: `1px solid transparent !important`,
    },
    "&:before": {
      borderBottom: `1px solid transparent`,
    },
    "&:hover:not($disabled):not($focused):before": {
      borderBottom: `1px solid #d3d3d3 !important`,
    },
  },

  // Switch
  disabledSwitch: {
    backgroundColor: "#c5c4c4 !important",
    border: "none !important",
  },
  disabledSwitchOn: {
    backgroundColor: "#508d5e !important",
    border: "#508d5e !important",
  },
}));

const QuizCreator = (props) => {
  const {
    quiz,
    setQuiz,
    preview,
    multiple,
    setUnsavedChanges,
    inputQuiz,
  } = props;

  const [showAnswerKey, setShowAnswerKey] = useState(null);

  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const [feedbackDialogInfo, setFeedbackDialogInfo] = useState(null);
  const handleFeedbackClose = () => {
    setFeedbackDialog(false);
  };

  const classes = useStyles();

  const handleCorrectAnswer = (e, index) => {
    const correctOption = quiz[index].options.find(
      (option) => option.value === e.target.value
    );

    setQuiz(
      update(quiz, {
        [index]: {
          $set: {
            ...quiz[index],
            correctAnswer: correctOption.id,
          },
        },
      })
    );
  };

  return preview &&
    quiz &&
    quiz.filter(
      (quizQuestion) =>
        !quizQuestion.inputQuestion && !quizQuestion.correctAnswer
    ).length ? (
    <div>N/A</div>
  ) : (
    <>
      {quiz.map((quizQuestion, index) => (
        <Card className="card card-box py-4 mb-4">
          {showAnswerKey === index ? (
            <>
              <div className="d-flex flex-row align-items-center px-4">
                <AssignmentTurnedInOutlined
                  style={{ width: 25, height: 25, color: "gray" }}
                />
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "#000",
                  }}
                  className="ml-3"
                >
                  Choose correct answer:
                </div>
              </div>
              <Divider className="my-4" />
              <FormControl component="fieldset" className="px-4">
                <RadioGroup
                  value={quizQuestion.correctAnswer}
                  onChange={(e) => {
                    handleCorrectAnswer(e, index);
                  }}
                >
                  {quizQuestion.options.map((option, optionIndex) => (
                    <FormControlLabel
                      key={optionIndex}
                      fullWidth={true}
                      value={option.value}
                      control={<Radio color="primary" />}
                      style={
                        option.id === quizQuestion.correctAnswer
                          ? {
                              backgroundColor: "rgba(24, 169, 169, 0.1)",
                            }
                          : {}
                      }
                      label={
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div>{option.value}</div>
                          {option.id === quizQuestion.correctAnswer ? (
                            <div>
                              <Check
                                style={{ width: 20, height: 20 }}
                                className="mr-2"
                                color="primary"
                              />
                            </div>
                          ) : null}
                        </div>
                      }
                      checked={option.id === quizQuestion.correctAnswer}
                      classes={{
                        label: classes.formControlLabel,
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <div className="d-flex flex-row align-items-center mt-4">
                {quizQuestion.incorrectFeedback ? (
                  <div
                    className="px-4 py-4"
                    style={{ backgroundColor: "#f8f9fa", width: "100%" }}
                  >
                    <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#202124",
                        }}
                      >
                        Feedback for incorrect answers
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <IconButton
                          onClick={() => {
                            setFeedbackDialog(true);
                            setFeedbackDialogInfo({
                              ...quizQuestion,
                              index,
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "pen"]}
                            className="font-size-sm"
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setQuiz(
                              update(quiz, {
                                [index]: {
                                  $set: {
                                    ...quiz[index],
                                    incorrectFeedback: "",
                                  },
                                },
                              })
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            className="font-size-sm"
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#202124",
                        paddingBottom: "10px",
                      }}
                    >
                      {quizQuestion.incorrectFeedback}
                    </div>
                  </div>
                ) : (
                  <div className="px-4">
                    <Button
                      variant="outlined"
                      startIcon={<AssignmentOutlinedIcon />}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setFeedbackDialog(true);
                        setFeedbackDialogInfo({
                          ...quizQuestion,
                          index,
                        });
                      }}
                    >
                      Add answer feedback
                    </Button>
                  </div>
                )}
              </div>
              <Divider className="my-4" />
              <div className="d-flex flex-row align-items-center px-4 justify-content-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setShowAnswerKey(null);
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <>
              <TextField
                className="px-4"
                variant={preview ? "standard" : "filled"}
                fullWidth
                hiddenLabel={true}
                value={quizQuestion.question}
                // error={formErrors.name}
                placeholder="Question"
                type="text"
                onChange={(e) => {
                  setUnsavedChanges(true);
                  setQuiz(
                    update(quiz, {
                      [index]: {
                        $set: {
                          ...quiz[index],
                          question: e.target.value,
                        },
                      },
                    })
                  );
                }}
                disabled={preview}
                InputProps={{ disableUnderline: preview ? true : false }}
              />
              <Divider className="my-4" />
              {preview && !inputQuiz ? (
                <FormControl component="fieldset" className="px-4">
                  <RadioGroup value={quizQuestion.correctAnswer}>
                    {quizQuestion.options.map((option, optionIndex) => (
                      <FormControlLabel
                        key={optionIndex}
                        fullWidth={true}
                        value={option.value}
                        control={<Radio color="primary" />}
                        style={
                          option.id === quizQuestion.correctAnswer
                            ? {
                                backgroundColor: "rgba(24, 169, 169, 0.1)",
                              }
                            : {}
                        }
                        label={
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div>{option.value}</div>
                            {option.id === quizQuestion.correctAnswer ? (
                              <div>
                                <Check
                                  style={{ width: 20, height: 20 }}
                                  className="mr-2"
                                  color="primary"
                                />
                              </div>
                            ) : null}
                          </div>
                        }
                        checked={option.id === quizQuestion.correctAnswer}
                        disabled
                        classes={{
                          label: classes.formControlLabel,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : !inputQuiz ? (
                <FormControl component="fieldset" className="px-4">
                  <RadioGroup value={""}>
                    {quizQuestion.options.map((option, optionIndex) => (
                      <FormControlLabel
                        fullWidth={true}
                        value={option.value}
                        disabled
                        control={<Radio />}
                        key={optionIndex}
                        label={
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <TextField
                              variant="standard"
                              fullWidth
                              hiddenLabel={true}
                              value={option.value}
                              type="text"
                              onChange={(e) => {
                                setUnsavedChanges(true);
                                setQuiz(
                                  update(quiz, {
                                    [index]: {
                                      options: {
                                        [optionIndex]: {
                                          $set: {
                                            ...quiz[index].options[optionIndex],
                                            value: e.target.value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }}
                              InputProps={{
                                classes: {
                                  underline: classes.optionTextFieldUnderline,
                                },
                              }}
                            />
                            {preview ? null : (
                              <IconButton
                                size="small"
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={() => {
                                  setQuiz(
                                    update(quiz, {
                                      [index]: {
                                        options: {
                                          $splice: [[optionIndex, 1]],
                                        },
                                      },
                                    })
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </div>
                        }
                        checked={false}
                        classes={{
                          label: classes.formControlLabel,
                        }}
                      />
                    ))}
                    <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio />}
                      label="Add option"
                      onClick={() => {
                        setQuiz(
                          update(quiz, {
                            [index]: {
                              options: {
                                $push: [
                                  {
                                    id: `${moment().valueOf()}${
                                      quiz[index].options.length + 1
                                    }`,
                                    value: `Option ${
                                      quiz[index].options.length + 1
                                    }`,
                                  },
                                ],
                              },
                            },
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      checked={false}
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <div className="text-black-50 px-4">
                  Guardians will input an answer to this question.
                </div>
              )}
              {preview || inputQuiz ? null : (
                <>
                  <Divider className="my-4" />
                  <div className="d-flex flex-row align-items-center px-4 justify-content-between">
                    <Button
                      variant="outlined"
                      startIcon={<AssignmentTurnedIn />}
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setShowAnswerKey(index);
                      }}
                    >
                      Answer Key
                    </Button>
                    {multiple ? (
                      <div className="d-flex flex-row align-items-center">
                        <div>
                          <Tooltip title={"Duplicate"}>
                            <Button
                              onClick={() => {
                                setQuiz(
                                  update(quiz, {
                                    $push: [quiz[index]],
                                  })
                                );
                              }}
                              className="btn-icon btn-pill d-50 p-0 mx-2"
                            >
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={["fas", "copy"]}
                                  className="font-size-sm"
                                  style={{ color: "gray" }}
                                />
                              </span>
                            </Button>
                          </Tooltip>
                        </div>
                        <div>
                          <Tooltip title={"Delete"}>
                            <Button
                              onClick={() => {
                                setQuiz(
                                  update(quiz, {
                                    $splice: [[index, 1]],
                                  })
                                );
                              }}
                              className="btn-icon btn-pill d-50 p-0 mx-2"
                            >
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon
                                  icon={["fas", "trash"]}
                                  className="font-size-sm"
                                  style={{ color: "gray" }}
                                />
                              </span>
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      ))}
      <Dialog
        open={feedbackDialog}
        onClose={handleFeedbackClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
          className="p-4"
        >
          {`Add incorrect answer feedback`}
        </DialogTitle>
        <TextField
          className="px-4 my-4"
          variant={"filled"}
          fullWidth
          hiddenLabel={true}
          value={feedbackDialogInfo ? feedbackDialogInfo.incorrectFeedback : ""}
          placeholder="Enter feedback"
          type="text"
          onChange={(e) => {
            setFeedbackDialogInfo({
              ...feedbackDialogInfo,
              incorrectFeedback: e.target.value,
            });
          }}
        />
        <Divider className="mt-4" />
        <DialogActions style={{ borderTop: "none" }} className="mt-2">
          <Button onClick={handleFeedbackClose}>Cancel</Button>
          <Button
            className="btn-primary"
            onClick={() => {
              if (feedbackDialogInfo) {
                setUnsavedChanges(true);
                setQuiz(
                  update(quiz, {
                    [feedbackDialogInfo.index]: {
                      $set: {
                        ...quiz[feedbackDialogInfo.index],
                        incorrectFeedback:
                          feedbackDialogInfo.incorrectFeedback || "",
                      },
                    },
                  })
                );
                handleFeedbackClose();
              }
            }}
            autoFocus
          >
            {"Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ActivityDetails = (props) => {
  const {
    activityType,
    formErrors,
    setFormErrors,
    activityName,
    setActivityName,
    information,
    setInformation,
    points,
    setPoints,
    repeatable,
    setRepeatable,
    video,
    setVideo,
    videoYoutube,
    setVideoYoutube,
    thumbnail,
    setThumbnail,
    quiz,
    setQuiz,
    preview,
    setUnsavedChanges,
    // School Activity
    schoolFiles,
    setSchoolFiles,
    // Link
    links,
    setLinks,
    // General
    verificationType,
    handleVerificationType,
  } = props;
  const editing = props.location
    ? props.location.type
      ? props.location.type === "edit"
      : false
    : false;

  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };

    setSchoolFiles(
      update(schoolFiles, {
        $push: [updatedFile],
      })
    );

    setUnsavedChanges(true);
    setFormErrors({
      ...formErrors,
      schoolFiles: false,
    });
  };

  // Link
  const [dialogLink, setDialogLink] = useState(false);
  const [linkTextDialog, setLinkTextDialog] = useState("");
  const [linkURLDialog, setLinkURLDialog] = useState("");
  const toggleDialogLink = () => {
    setDialogLink(!dialogLink);
    setLinkTextDialog("");
    setLinkURLDialog("");
  };
  const handleLink = async () => {
    const link = {
      text: linkTextDialog,
      url: linkURLDialog,
    };

    setLinks(
      update(links, {
        $push: [link],
      })
    );

    setUnsavedChanges(true);
    setFormErrors({
      ...formErrors,
      links: false,
    });
  };

  const classes = useStyles();

  switch (activityType) {
    case 0:
      return (
        <div className="px-4">
          <div className="mt-4">
            <p
              className="text-black"
              style={{ fontSize: "14px", fontWeight: 300 }}
            >
              {preview
                ? "Please review the activity details and the preview on right."
                : "Please fill out the details for this Video activity. "}
            </p>
          </div>
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={formErrors.name}
                    variant="outlined"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={activityName}
                      error={formErrors.name}
                      type="text"
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        setActivityName(e.target.value);

                        setFormErrors({ ...formErrors, name: false });
                      }}
                      disabled={preview}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.name
                          ? "Activity name is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      setInformation(e);

                      setFormErrors({ ...formErrors, description: false });
                    }}
                    readOnly={preview}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  />
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.information
                        ? "Description is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                  >
                    {"Video upload"}
                  </InputLabel>
                  {video ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <video
                        key={video.preview ? video.preview : video}
                        controls
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      >
                        <source
                          src={video.preview ? video.preview : video}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      {preview ? null : (
                        <Button
                          style={{ width: "200px" }}
                          variant="contained"
                          className="btn-pill mt-3 btn-danger"
                          onClick={() => setVideo(null)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ) : preview ? (
                    <div>N/A</div>
                  ) : (
                    <VideoDropzone
                      videoUpload={setVideo}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      errorField="video"
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  )}
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.video
                        ? "Video or Youtube link is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                  >
                    {"Youtube video ID"}
                  </InputLabel>
                  <div className="py-2">
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={videoYoutube}
                      type="text"
                      onChange={(e) => {
                        setUnsavedChanges(true);
                        const queryParams = e.target.value.split("?")[1];
                        const getQueryStringParams = (query) => {
                          return query
                            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                                .split("&")
                                .reduce((params, param) => {
                                  let [key, value] = param.split("=");
                                  params[key] = value
                                    ? decodeURIComponent(
                                        value.replace(/\+/g, " ")
                                      )
                                    : "";
                                  return params;
                                }, {})
                            : {};
                        };
                        const params = getQueryStringParams(queryParams);

                        if (params.v) {
                          setVideoYoutube(`${params.v}`);
                        } else {
                          setVideoYoutube(e.target.value);
                        }
                      }}
                      disabled={preview}
                    />
                    <FormHelperText className="mt-2">
                      Paste YouTube video link and it will automatically
                      retrieve the video ID.
                    </FormHelperText>
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.video
                          ? "Video or Youtube link is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required
                  >
                    Points
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={formErrors.points}
                    variant="outlined"
                  >
                    <Select
                      value={points}
                      onChange={(e) => {
                        setPoints(e.target.value);
                        setFormErrors({ ...formErrors, points: false });
                        setUnsavedChanges(true);
                      }}
                      classes={{
                        select: classes.selectStyles,
                      }}
                      input={
                        <OutlinedInput
                          classes={{
                            root: classes.fixInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                          }}
                        />
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      disabled={preview || editing}
                    >
                      <MenuItem value={1} dense>
                        1 point
                      </MenuItem>
                      <MenuItem value={2} dense>
                        2 points
                      </MenuItem>
                      <MenuItem value={3} dense>
                        3 points
                      </MenuItem>
                      <MenuItem value={4} dense>
                        4 points
                      </MenuItem>
                      <MenuItem value={5} dense>
                        5 points
                      </MenuItem>
                    </Select>
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.points
                          ? "Points field is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Video thumbnail"}
                  </InputLabel>

                  {thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={thumbnail.preview ? thumbnail.preview : thumbnail}
                        className="rounded"
                        alt="..."
                      />
                      {preview ? null : (
                        <Button
                          style={{ width: "200px" }}
                          variant="contained"
                          className="btn-pill mt-3 btn-danger"
                          onClick={() => setThumbnail(null)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ) : (
                    <ThumbnailDropzone
                      thumbnailUpload={setThumbnail}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      errorField="thumbnail"
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  )}

                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.thumbnail
                        ? "Video thumbnail is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <QuizCreator
                    quiz={quiz}
                    setQuiz={setQuiz}
                    preview={preview}
                    multiple={false}
                    setUnsavedChanges={setUnsavedChanges}
                  />
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.quiz
                        ? "Please check that your question has a valid title, options, and you've choosen a correct answer."
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {preview ? (
              <Grid item lg={5}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex">
                    <p
                      className={"text-black-50 text-center"}
                      style={{ fontSize: "14px", marginLeft: "40px" }}
                    >
                      Scrollable Preview of Parent Parties Engage
                    </p>
                  </div>
                  <div
                    className="marvel-device iphone-x"
                    style={{
                      transform:
                        "scale(0.7) translateY(-170px) translateX(40px)",
                    }}
                  >
                    <div className="notch">
                      <div className="camera"></div>
                      <div className="speaker"></div>
                    </div>
                    <div className="top-bar"></div>
                    <div className="sleep"></div>
                    <div className="bottom-bar"></div>
                    <div className="volume"></div>
                    <div className="overflow">
                      <div className="shadow shadow--tr"></div>
                      <div className="shadow shadow--tl"></div>
                      <div className="shadow shadow--br"></div>
                      <div className="shadow shadow--bl"></div>
                    </div>
                    <div className="inner-shadow"></div>
                    <div className="screen">
                      <PreviewApp
                        activity={{
                          type: activityType,
                          points,
                          repeatable,
                          // En
                          name: activityName,
                          information,
                          thumbnail,
                          video,
                          videoYoutube,
                        }}
                        language={"en"}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      );
    case 1:
    case 6:
      return (
        <div className="px-4">
          <div className="mt-4">
            <p
              className="text-black"
              style={{ fontSize: "14px", fontWeight: 300 }}
            >
              {preview
                ? "Please review the activity details and the preview on right."
                : "Please fill out the details for this Multiple Choice activity."}
            </p>
          </div>
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={formErrors.name}
                    variant="outlined"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      error={formErrors.name}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        setActivityName(e.target.value);

                        setFormErrors({ ...formErrors, name: false });
                      }}
                      disabled={preview}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.name
                          ? "Activity name is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      setInformation(e);

                      setFormErrors({ ...formErrors, description: false });
                    }}
                    readOnly={preview}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  />
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.information
                        ? "Description is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required
                  >
                    Points
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={formErrors.points}
                    variant="outlined"
                  >
                    <Select
                      value={points}
                      onChange={(e) => {
                        setPoints(e.target.value);
                        setFormErrors({ ...formErrors, points: false });
                        setUnsavedChanges(true);
                      }}
                      classes={{
                        select: classes.selectStyles,
                      }}
                      input={
                        <OutlinedInput
                          classes={{
                            root: classes.fixInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                          }}
                        />
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      disabled={preview || editing}
                    >
                      <MenuItem value={1} dense>
                        1 point
                      </MenuItem>
                      <MenuItem value={2} dense>
                        2 points
                      </MenuItem>
                      <MenuItem value={3} dense>
                        3 points
                      </MenuItem>
                      <MenuItem value={4} dense>
                        4 points
                      </MenuItem>
                      <MenuItem value={5} dense>
                        5 points
                      </MenuItem>
                    </Select>
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.points
                          ? "Points field is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity thumbnail"}
                  </InputLabel>
                  {thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={thumbnail.preview ? thumbnail.preview : thumbnail}
                        className="rounded"
                        alt="..."
                      />
                      {preview ? null : (
                        <Button
                          style={{ width: "200px" }}
                          variant="contained"
                          className="btn-pill mt-3 btn-danger"
                          onClick={() => setThumbnail(null)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ) : (
                    <ThumbnailDropzone
                      thumbnailUpload={setThumbnail}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      errorField="thumbnail"
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  )}
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.thumbnail
                        ? "Thumbnail is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Question"}
                  </InputLabel>
                  <div className="mb-4">
                    <QuizCreator
                      quiz={quiz}
                      setQuiz={setQuiz}
                      preview={preview}
                      multiple={true}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.quiz
                          ? "Please check that your quiz has has a valid questions, options, and you've choosen a correct answer for each one."
                          : ""}
                      </FormHelperText>
                    )}
                    {preview ? null : (
                      <Button
                        variant="contained"
                        size="small"
                        className="btn-success shadow-none mt-2 py-2"
                        onClick={() => {
                          setQuiz(
                            update(quiz, {
                              $push: [
                                {
                                  question: `Question ${quiz.length + 1}`,
                                  options: [
                                    {
                                      id: moment().valueOf(),
                                      value: "Option 1",
                                    },
                                  ],
                                },
                              ],
                            })
                          );
                        }}
                      >
                        <span className="btn-wrapper--icon mr-1">
                          <FontAwesomeIcon
                            style={{ fontSize: "12px" }}
                            icon={["fas", "plus"]}
                          />
                        </span>
                        <span
                          className="btn-wrapper--label"
                          style={{ fontSize: "12px" }}
                        >
                          Add Question
                        </span>
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {preview ? (
              <Grid item lg={5}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex">
                    <p
                      className={"text-black-50 text-center"}
                      style={{ fontSize: "14px", marginLeft: "40px" }}
                    >
                      Scrollable Preview of Parent Parties Engage
                    </p>
                  </div>
                  <div
                    className="marvel-device iphone-x"
                    style={{
                      transform:
                        "scale(0.7) translateY(-170px) translateX(40px)",
                    }}
                  >
                    <div className="notch">
                      <div className="camera"></div>
                      <div className="speaker"></div>
                    </div>
                    <div className="top-bar"></div>
                    <div className="sleep"></div>
                    <div className="bottom-bar"></div>
                    <div className="volume"></div>
                    <div className="overflow">
                      <div className="shadow shadow--tr"></div>
                      <div className="shadow shadow--tl"></div>
                      <div className="shadow shadow--br"></div>
                      <div className="shadow shadow--bl"></div>
                    </div>
                    <div className="inner-shadow"></div>
                    <div className="screen">
                      <PreviewApp
                        activity={{
                          type: activityType,
                          points,
                          repeatable,
                          // En
                          name: activityName,
                          information,
                          thumbnail,
                          video,
                        }}
                        language={"en"}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      );
    case 2:
    case 3:
    case 7:
      return (
        <div className="px-4">
          <div className="mt-4">
            <p
              className="text-black"
              style={{ fontSize: "14px", fontWeight: 300 }}
            >
              {preview
                ? "Please review the activity details and the preview on right."
                : "Please fill out the details for this General activity."}
            </p>
          </div>
          <Grid container spacing={0} className="mt-4 pt-4">
            <Grid md={7}>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity name"}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    error={formErrors.name}
                    variant="outlined"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={activityName}
                      error={formErrors.name}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        setActivityName(e.target.value);

                        setFormErrors({ ...formErrors, name: false });
                      }}
                      disabled={preview}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.name
                          ? "Activity name is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Description"}
                  </InputLabel>
                  <ReactQuill
                    theme="snow"
                    value={information}
                    onChange={(e) => {
                      setUnsavedChanges(true);

                      setInformation(e);

                      setFormErrors({ ...formErrors, description: false });
                    }}
                    readOnly={preview}
                    placeholder="Example placeholder..."
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"], // toggled buttons
                        ["link"], // link button
                      ],
                    }}
                  />
                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.information
                        ? "Description is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required
                  >
                    Points
                  </InputLabel>
                  <div className="d-flex flex-row align-items-center">
                    <FormControl
                      fullWidth
                      error={formErrors.points}
                      variant="outlined"
                    >
                      <Select
                        value={points}
                        onChange={(e) => {
                          setPoints(e.target.value);
                          setFormErrors({ ...formErrors, points: false });
                          setUnsavedChanges(true);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        disabled={preview || repeatable}
                      >
                        <MenuItem value={1} dense>
                          1 point
                        </MenuItem>
                        <MenuItem value={2} dense>
                          2 points
                        </MenuItem>
                        <MenuItem value={3} dense>
                          3 points
                        </MenuItem>
                        <MenuItem value={4} dense>
                          4 points
                        </MenuItem>
                        <MenuItem value={5} dense>
                          5 points
                        </MenuItem>
                      </Select>
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.points
                            ? "Points field is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            setUnsavedChanges(true);
                            setRepeatable(e.target.checked);
                            setPoints(1);
                            setFormErrors({ ...formErrors, points: false });
                          }}
                          checked={repeatable}
                          className="switch-small toggle-switch-success ml-4"
                          disabled={preview || editing}
                          classes={{
                            track:
                              preview || editing
                                ? repeatable
                                  ? classes.disabledSwitchOn
                                  : classes.disabledSwitch
                                : "",
                          }}
                        />
                      }
                      label={
                        <span
                          className="ml-2"
                          style={{ fontSize: "13px", color: "rgba(0,0,0,.6)" }}
                        >
                          Repeatable
                        </span>
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Activity thumbnail"}
                  </InputLabel>

                  {thumbnail ? (
                    <div className="py-2 d-flex flex-column align-items-start">
                      <img
                        style={{
                          width: "300px",
                          height: "300px",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                        src={thumbnail.preview ? thumbnail.preview : thumbnail}
                        className="rounded"
                        alt="..."
                      />
                      {preview ? null : (
                        <Button
                          style={{ width: "200px" }}
                          variant="contained"
                          className="btn-pill mt-3 btn-danger"
                          onClick={() => setThumbnail(null)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  ) : (
                    <ThumbnailDropzone
                      thumbnailUpload={setThumbnail}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      errorField="thumbnail"
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  )}

                  {preview ? null : (
                    <FormHelperText error={true}>
                      {props.formErrors.thumbnail
                        ? "Thumbnail is required"
                        : ""}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item md={12}>
                  <InputLabel
                    style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                    className="mb-3"
                    required={true}
                  >
                    {"Verification Type"}
                  </InputLabel>
                  {preview ? (
                    <div>
                      {verificationType === "single"
                        ? "Open Question"
                        : verificationType === "multiple"
                        ? "Multiple Choice"
                        : "Upload Photo"}
                    </div>
                  ) : (
                    <RadioGroup
                      row
                      value={verificationType}
                      onChange={(e) => {
                        handleVerificationType(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label="Open Question"
                      />
                      <FormControlLabel
                        value="multiple"
                        control={<Radio />}
                        label="Multiple Choice"
                      />
                      <FormControlLabel
                        value="upload"
                        control={<Radio />}
                        label="Upload Photo"
                      />
                    </RadioGroup>
                  )}
                  {preview ? null : verificationType === "single" ? (
                    <div className="my-4">
                      <QuizCreator
                        quiz={quiz}
                        setQuiz={setQuiz}
                        preview={preview}
                        multiple={false}
                        setUnsavedChanges={setUnsavedChanges}
                        inputQuiz={true}
                      />
                    </div>
                  ) : verificationType === "multiple" ? (
                    <div className="my-4">
                      <QuizCreator
                        quiz={quiz}
                        setQuiz={setQuiz}
                        preview={preview}
                        multiple={false}
                        setUnsavedChanges={setUnsavedChanges}
                      />
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.quiz
                            ? "Please check that your quiz has has a valid questions, options, and you've choosen a correct answer for each one."
                            : ""}
                        </FormHelperText>
                      )}
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            {/* {preview ? (
              <Grid item lg={5}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex">
                    <p
                      className={"text-black-50 text-center"}
                      style={{ fontSize: "14px", marginLeft: "40px" }}
                    >
                      Scrollable Preview of Parent Parties Engage
                    </p>
                  </div>
                  <div
                    className="marvel-device iphone-x"
                    style={{
                      transform:
                        "scale(0.7) translateY(-170px) translateX(40px)",
                    }}
                  >
                    <div className="notch">
                      <div className="camera"></div>
                      <div className="speaker"></div>
                    </div>
                    <div className="top-bar"></div>
                    <div className="sleep"></div>
                    <div className="bottom-bar"></div>
                    <div className="volume"></div>
                    <div className="overflow">
                      <div className="shadow shadow--tr"></div>
                      <div className="shadow shadow--tl"></div>
                      <div className="shadow shadow--br"></div>
                      <div className="shadow shadow--bl"></div>
                    </div>
                    <div className="inner-shadow"></div>
                    <div className="screen">
                      <PreviewApp
                        activity={{
                          type: activityType,
                          points,
                          repeatable,
                          // En
                          name: activityName,
                          information,
                          thumbnail,
                          video,
                        }}
                        language={"en"}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            ) : null} */}
          </Grid>
        </div>
      );
    case 4:
      return (
        <>
          <div className="px-4">
            <div className="mt-4">
              <p
                className="text-black"
                style={{ fontSize: "14px", fontWeight: 300 }}
              >
                {preview
                  ? "Please review the activity details and the preview on right."
                  : "Please fill out the details for this File activity."}
              </p>
            </div>
            <Grid container spacing={0} className="mt-4 pt-4">
              <Grid md={7}>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity name"}
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formErrors.name}
                      variant="outlined"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={activityName}
                        error={formErrors.name}
                        type="text"
                        onChange={(e) => {
                          setUnsavedChanges(true);

                          setActivityName(e.target.value);

                          setFormErrors({ ...formErrors, name: false });
                        }}
                        disabled={preview}
                      />
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.name
                            ? "Activity name is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Description"}
                    </InputLabel>
                    <ReactQuill
                      theme="snow"
                      value={information}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        setInformation(e);

                        setFormErrors({ ...formErrors, description: false });
                      }}
                      readOnly={preview}
                      placeholder="Example placeholder..."
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"], // toggled buttons
                          ["link"], // link button
                        ],
                      }}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.information
                          ? "Description is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"File uploads (3 max)"}
                    </InputLabel>

                    <div className="py-2">
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-striped table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">File Name</th>
                              <th scope="col" style={{ width: "125px" }}>
                                Type
                              </th>
                              <th scope="col" style={{ width: "125px" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {schoolFiles.map((file) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.name}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.fileType
                                      ? file.fileType.includes("pdf")
                                        ? "PDF"
                                        : file.fileType.includes("mp4")
                                        ? "Video"
                                        : "File"
                                      : "File"}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {preview ? null : (
                                      <div className="d-flex flex-row align-items-center">
                                        <Button
                                          className="px-2 btn-transition-none border-0 btn-outline-danger"
                                          variant="outlined"
                                          onClick={() => {
                                            const fileIndex = schoolFiles.findIndex(
                                              (schoolFile) =>
                                                file.id
                                                  ? file.id === schoolFile.id
                                                  : file.tempId ===
                                                    schoolFile.tempId
                                            );

                                            setUnsavedChanges(true);
                                            setSchoolFiles(
                                              update(schoolFiles, {
                                                $splice: [[fileIndex, 1]],
                                              })
                                            );
                                          }}
                                        >
                                          <span className="btn-wrapper--icon">
                                            <FontAwesomeIcon
                                              icon={["fas", "trash"]}
                                              className="font-size-sm"
                                            />
                                          </span>
                                        </Button>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      {schoolFiles.length < 3 ? (
                        preview ? null : (
                          <SchoolFileButton
                            toggleDialogFileName={toggleDialogFileName}
                          />
                        )
                      ) : null}
                      <div className="mt-2">
                        <small className="text-black-50">
                          Accepts JPEG, PNG, PDF, and MP4 files.
                        </small>
                      </div>
                    </div>
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.schoolFiles
                          ? "Atleast one file is required."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required
                    >
                      Points
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formErrors.points}
                      variant="outlined"
                    >
                      <Select
                        value={points}
                        onChange={(e) => {
                          setPoints(e.target.value);
                          setFormErrors({ ...formErrors, points: false });
                          setUnsavedChanges(true);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        disabled={preview || editing}
                      >
                        <MenuItem value={1} dense>
                          1 point
                        </MenuItem>
                        <MenuItem value={2} dense>
                          2 points
                        </MenuItem>
                        <MenuItem value={3} dense>
                          3 points
                        </MenuItem>
                        <MenuItem value={4} dense>
                          4 points
                        </MenuItem>
                        <MenuItem value={5} dense>
                          5 points
                        </MenuItem>
                      </Select>
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.points
                            ? "Points field is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity thumbnail"}
                    </InputLabel>

                    {thumbnail ? (
                      <div className="py-2 d-flex flex-column align-items-start">
                        <img
                          style={{
                            width: "300px",
                            height: "300px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          src={
                            thumbnail.preview ? thumbnail.preview : thumbnail
                          }
                          className="rounded"
                          alt="..."
                        />
                        {preview ? null : (
                          <Button
                            style={{ width: "200px" }}
                            variant="contained"
                            className="btn-pill mt-3 btn-danger"
                            onClick={() => setThumbnail(null)}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    ) : (
                      <ThumbnailDropzone
                        thumbnailUpload={setThumbnail}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        errorField="thumbnail"
                        setUnsavedChanges={setUnsavedChanges}
                      />
                    )}
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.thumbnail
                          ? "Thumbnail is required."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Question"}
                    </InputLabel>
                    <QuizCreator
                      quiz={quiz}
                      setQuiz={setQuiz}
                      preview={preview}
                      multiple={false}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.quiz
                          ? "Please check that your question has a valid title, options, and you've choosen a correct answer."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {preview ? (
                <Grid item lg={5}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex">
                      <p
                        className={"text-black-50 text-center"}
                        style={{ fontSize: "14px", marginLeft: "40px" }}
                      >
                        Scrollable Preview of Parent Parties Engage
                      </p>
                    </div>
                    <div
                      className="marvel-device iphone-x"
                      style={{
                        transform:
                          "scale(0.7) translateY(-170px) translateX(40px)",
                      }}
                    >
                      <div className="notch">
                        <div className="camera"></div>
                        <div className="speaker"></div>
                      </div>
                      <div className="top-bar"></div>
                      <div className="sleep"></div>
                      <div className="bottom-bar"></div>
                      <div className="volume"></div>
                      <div className="overflow">
                        <div className="shadow shadow--tr"></div>
                        <div className="shadow shadow--tl"></div>
                        <div className="shadow shadow--br"></div>
                        <div className="shadow shadow--bl"></div>
                      </div>
                      <div className="inner-shadow"></div>
                      <div className="screen">
                        <PreviewApp
                          activity={{
                            type: activityType,
                            points,
                            repeatable,
                            schoolFiles,
                            // En
                            name: activityName,
                            information,
                            thumbnail,
                            video,
                          }}
                          language={"en"}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={dialogFileName}
            onClose={() => toggleDialogFileName()}
            classes={{ paper: "modal-content rounded-lg pt-4" }}
          >
            <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
              {`Add file name`}
            </div>
            <div className="divider my-2" />
            <div className="p-3">
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <FormControl fullWidth variant="outlined">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fileNameDialog}
                        label="Menu Name"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setFileNameDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: School Calendar</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="pt-4">
                <Button
                  onClick={() => toggleDialogFileName()}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  onClick={async () => {
                    toggleDialogFileName();
                    handleFileName(dialogInfoFileName);
                  }}
                  className={"btn-pill mx-1 btn-success"}
                >
                  <span className="btn-wrapper--label">Save</span>
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      );
    case 5:
      return (
        <>
          <div className="px-4">
            <div className="mt-4">
              <p
                className="text-black"
                style={{ fontSize: "14px", fontWeight: 300 }}
              >
                {preview
                  ? "Please review the activity details and the preview on right."
                  : "Please fill out the details for this Link activity."}
              </p>
            </div>
            <Grid container spacing={0} className="mt-4 pt-4">
              <Grid md={7}>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity name"}
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formErrors.name}
                      variant="outlined"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={activityName}
                        error={formErrors.name}
                        type="text"
                        onChange={(e) => {
                          setUnsavedChanges(true);

                          setActivityName(e.target.value);

                          setFormErrors({ ...formErrors, name: false });
                        }}
                        disabled={preview}
                      />
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.name
                            ? "Activity name is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Description"}
                    </InputLabel>
                    <ReactQuill
                      theme="snow"
                      value={information}
                      onChange={(e) => {
                        setUnsavedChanges(true);

                        setInformation(e);

                        setFormErrors({ ...formErrors, description: false });
                      }}
                      readOnly={preview}
                      placeholder="Example placeholder..."
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"], // toggled buttons
                        ],
                      }}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.information
                          ? "Description is required"
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Links"}
                    </InputLabel>

                    <div className="py-2">
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-striped table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Link text</th>
                              <th scope="col">URL</th>
                              <th scope="col" style={{ width: "125px" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {links.map((link) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {link.text}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={link.url}
                                      style={{
                                        color: "#0073bb",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {link.url}
                                    </a>
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {preview ? null : (
                                      <div className="d-flex flex-row align-items-center">
                                        <Button
                                          className="px-2 btn-transition-none border-0 btn-outline-danger"
                                          variant="outlined"
                                          onClick={() => {
                                            const linkIndex = links.findIndex(
                                              (linkItem) =>
                                                linkItem.id
                                                  ? linkItem.id === link.id
                                                  : linkItem.tempId ===
                                                    link.tempId
                                            );

                                            setUnsavedChanges(true);
                                            setLinks(
                                              update(links, {
                                                $splice: [[linkIndex, 1]],
                                              })
                                            );
                                          }}
                                        >
                                          <span className="btn-wrapper--icon">
                                            <FontAwesomeIcon
                                              icon={["fas", "trash"]}
                                              className="font-size-sm"
                                            />
                                          </span>
                                        </Button>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      {links.length < 3 ? (
                        preview ? null : (
                          <Button
                            variant="contained"
                            size="small"
                            className="btn-success shadow-none mt-2 py-2"
                            onClick={toggleDialogLink}
                          >
                            <span className="btn-wrapper--icon mr-1">
                              <FontAwesomeIcon
                                style={{ fontSize: "12px" }}
                                icon={["fas", "plus"]}
                              />
                            </span>
                            <span
                              className="btn-wrapper--label"
                              style={{ fontSize: "12px" }}
                            >
                              Add Link
                            </span>
                          </Button>
                        )
                      ) : null}
                    </div>

                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.links
                          ? "Atleast one link is required."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required
                    >
                      Points
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={formErrors.points}
                      variant="outlined"
                    >
                      <Select
                        value={points}
                        onChange={(e) => {
                          setPoints(e.target.value);
                          setFormErrors({ ...formErrors, points: false });
                          setUnsavedChanges(true);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        disabled={preview || editing}
                      >
                        <MenuItem value={1} dense>
                          1 point
                        </MenuItem>
                        <MenuItem value={2} dense>
                          2 points
                        </MenuItem>
                        <MenuItem value={3} dense>
                          3 points
                        </MenuItem>
                        <MenuItem value={4} dense>
                          4 points
                        </MenuItem>
                        <MenuItem value={5} dense>
                          5 points
                        </MenuItem>
                      </Select>
                      {preview ? null : (
                        <FormHelperText error={true}>
                          {props.formErrors.points
                            ? "Points field is required"
                            : ""}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Activity thumbnail"}
                    </InputLabel>

                    {thumbnail ? (
                      <div className="py-2 d-flex flex-column align-items-start">
                        <img
                          style={{
                            width: "300px",
                            height: "300px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          src={
                            thumbnail.preview ? thumbnail.preview : thumbnail
                          }
                          className="rounded"
                          alt="..."
                        />
                        {preview ? null : (
                          <Button
                            style={{ width: "200px" }}
                            variant="contained"
                            className="btn-pill mt-3 btn-danger"
                            onClick={() => setThumbnail(null)}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    ) : (
                      <ThumbnailDropzone
                        thumbnailUpload={setThumbnail}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        errorField="thumbnail"
                        setUnsavedChanges={setUnsavedChanges}
                      />
                    )}

                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.thumbnail
                          ? "Thumbnail is required."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Question"}
                    </InputLabel>
                    <QuizCreator
                      quiz={quiz}
                      setQuiz={setQuiz}
                      preview={preview}
                      multiple={false}
                      setUnsavedChanges={setUnsavedChanges}
                      inputQuiz={true}
                    />
                    {preview ? null : (
                      <FormHelperText error={true}>
                        {props.formErrors.quiz
                          ? "Please check that your question has a valid title, options, and you've choosen a correct answer."
                          : ""}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {preview ? (
                <Grid item lg={5}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex">
                      <p
                        className={"text-black-50 text-center"}
                        style={{ fontSize: "14px", marginLeft: "40px" }}
                      >
                        Scrollable Preview of Parent Parties Engage
                      </p>
                    </div>
                    <div
                      className="marvel-device iphone-x"
                      style={{
                        transform:
                          "scale(0.7) translateY(-170px) translateX(40px)",
                      }}
                    >
                      <div className="notch">
                        <div className="camera"></div>
                        <div className="speaker"></div>
                      </div>
                      <div className="top-bar"></div>
                      <div className="sleep"></div>
                      <div className="bottom-bar"></div>
                      <div className="volume"></div>
                      <div className="overflow">
                        <div className="shadow shadow--tr"></div>
                        <div className="shadow shadow--tl"></div>
                        <div className="shadow shadow--br"></div>
                        <div className="shadow shadow--bl"></div>
                      </div>
                      <div className="inner-shadow"></div>
                      <div className="screen">
                        <PreviewApp
                          activity={{
                            type: activityType,
                            points,
                            repeatable,
                            schoolFiles,
                            // En
                            name: activityName,
                            information,
                            thumbnail,
                            video,
                            links,
                          }}
                          language={"en"}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={dialogLink}
            onClose={() => toggleDialogLink()}
            classes={{ paper: "modal-content rounded-lg pt-4" }}
          >
            <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
              {`Add link`}
            </div>
            <div className="divider my-2" />
            <div className="p-4">
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mb-4 pb-2"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={linkTextDialog}
                        label="Link text"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setLinkTextDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: Sign-up Form</FormHelperText>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={linkURLDialog}
                        label="Link URL"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          onChange: (e) => {
                            setLinkURLDialog(e.target.value);
                          },
                        }}
                      />
                    </div>
                    <FormHelperText>Ex: https://www.google.com</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="pt-4 mt-2">
                <Button
                  onClick={() => toggleDialogLink()}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  onClick={async () => {
                    toggleDialogLink();
                    handleLink();
                  }}
                  className={"btn-pill mx-1 btn-success"}
                >
                  <span className="btn-wrapper--label">Add Link</span>
                </Button>
              </div>
            </div>
          </Dialog>
        </>
      );
    default:
      return null;
  }
};
export default ActivityDetails;
