import { useState, useEffect } from "react";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import AGGrid from "../../Grid";

const priority = (el) => {
  const priorityList = [
    "Preschool",
    "Kindergarten",
    "1st grade",
    "2nd grade",
    "3rd grade",
    "4th grade",
    "5th grade",
    "6th grade",
    "7th grade",
    "8th grade",
    "9th grade",
    "10th grade",
    "11th grade",
    "12th grade",
  ];
  return priorityList.indexOf(el);
};

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}
const handleActivityType = (type) => {
  switch (type) {
    case 0:
      return "Video";
    case 1:
    case 6:
      return "Multiple Choice";
    case 2:
    case 3:
    case 7:
      return "General";
    case 4:
      return "File";
    case 5:
      return "Link";
    default:
      return null;
  }
};

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddActivityDialog = (props) => {
  const {
    activityPool,
    addActivityDialog,
    addActivityDialogInfo,
    handleAddActivityDialogClose,
    themes,
    setPreviewDialogInfo,
    setPreviewDialog,
    handleUpdateCurriculumThemeActivities,
  } = props;

  const themeActivities = themes
    ? themes
        .map((theme) =>
          theme.id !== addActivityDialogInfo ? theme.activityIds : []
        )
        .flat()
    : [];

  useEffect(() => {
    return () => setSelectedActivities([]);
  }, [addActivityDialog]);

  const [selectedActivities, setSelectedActivities] = useState([]);
  const handleActivitySelect = (e) => {
    const itemId = e.data.id;
    const itemIndex = selectedActivities.findIndex((i) => i === itemId);
    if (e.node.selected) {
      setSelectedActivities([...selectedActivities, e.data.id]);
    } else {
      setSelectedActivities(
        update(selectedActivities, {
          $splice: [[itemIndex, 1]],
        })
      );
    }
  };

  const themeId = addActivityDialogInfo;
  const theme = themes ? themes.find((theme) => theme.id === themeId) : null;

  return (
    <Dialog
      open={addActivityDialog}
      onClose={handleAddActivityDialogClose}
      fullWidth={true}
      maxWidth={"lg"}
      PaperProps={{
        className: "p-3",
      }}
    >
      <DialogTitle
        style={{
          borderBottom: "none",
        }}
        onClose={handleAddActivityDialogClose}
        row={true}
      >
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "rgb(42, 42, 47)",
            }}
          >
            {theme ? `Edit Activities: ${theme.subtext}` : ""}
          </div>
          {theme ? (
            theme.name ? (
              <div className="text-black-50">{theme.name}</div>
            ) : null
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent dividers={true} className="pb-4 pt-4">
        <AGGrid
          updateSelected={theme ? theme.activityIds : []}
          rowData={activityPool.filter(
            (act) => !themeActivities.includes(act.id)
          )}
          onRowSelected={handleActivitySelect}
          rowSelection={"multiple"}
          search={false}
          autoHeight={true}
          columnDefs={[
            {
              flex: 2,
              checkboxSelection: true,
              headerName: "Name",
              field: "name",
              sortable: true,
              filter: true,
            },
            {
              flex: 1,
              headerName: "Type",
              field: "type",
              sortable: true,
              filter: true,
              valueGetter: function (params) {
                if (params.data.type !== undefined) {
                  return handleActivityType(params.data.type);
                } else {
                  return null;
                }
              },
            },
            {
              flex: 1,
              headerName: "Points",
              field: "points",
              sortable: true,
              filter: true,
            },
            {
              flex: 1,
              sort: "desc",
              headerName: "Last Updated",
              field: "lastUpdated",
              sortable: true,
              filter: "agDateColumnFilter",
              valueFormatter: function (params) {
                if (params.data.lastUpdated) {
                  return moment(params.data.lastUpdated).format("L");
                } else {
                  return null;
                }
              },
              comparator: dateComparator,
            },
            {
              flex: 1,
              headerName: "Grade Levels",
              field: "gradeLevels",
              cellRendererFramework: (params) => {
                let gradeLevels = params.data.gradeLevels
                  ? params.data.gradeLevels.sort(
                      (a, b) => priority(a) - priority(b)
                    )
                  : null;
                return gradeLevels ? (
                  <Tooltip
                    arrow
                    title={
                      <div className="px-4">
                        {gradeLevels.map((gradeLevel) => (
                          <div>{gradeLevel}</div>
                        ))}
                      </div>
                    }
                  >
                    <div className="d-flex flex-row align-items-center">
                      View
                      <FontAwesomeIcon
                        icon={["fas", "info-circle"]}
                        className="ml-2 text-dark"
                      />
                    </div>
                  </Tooltip>
                ) : null;
              },
              cellClass: "agGridCellCenter",
            },
            {
              flex: 1,
              field: "actions",
              headerName: "Actions",
              cellRendererFramework: (params) => (
                <div className="d-flex flex-row align-items-center">
                  <button
                    className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                    tabIndex="0"
                    type="button"
                    onClick={() => {
                      setPreviewDialogInfo(params.data);
                      setPreviewDialog(true);
                    }}
                  >
                    <span className="MuiButton-label">
                      <span
                        className="btn-wrapper--label text-white"
                        style={{ fontSize: "12px" }}
                      >
                        Preview
                      </span>
                    </span>
                    <span className="MuiTouchRipple-root"></span>
                  </button>
                </div>
              ),
              cellClass: "agGridCellCenter",
            },
          ]}
        />
      </DialogContent>
      <DialogActions style={{ borderTop: "none" }} className="mt-2">
        <Button
          className="btn-primary"
          autoFocus
          onClick={() => {
            handleUpdateCurriculumThemeActivities(themeId, selectedActivities);
          }}
        >
          {`Save Activities (${selectedActivities.length} selected)`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddActivityDialog;
