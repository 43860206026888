import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  List,
  ListItem,
  Card,
  CardContent,
  Avatar,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  LinearProgress,
  Checkbox,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import AGGrid from "../Grid";
import Chart from "react-apexcharts";
import update from "immutability-helper";
import { DayPickerRangeController } from "react-dates";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
// Responsive //
import { useTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircleLoader } from "react-spinners";
import ResponsesModal from "./components/ResponsesModal";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
  root: {
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0)",
    paddingLeft: 0,
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
    paddingLeft: 0,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(MuiAccordionDetails);

function AccordionComp(props) {
  const [expanded, setExpanded] = useState(false);
  const { title, body } = props;

  return (
    <Accordion square expanded={expanded}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
}
const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "125px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

const themes = [
  {
    id: 0,
    name: "Off to a Good Start",
    subtext: "Starts in August or September",
  },
  {
    id: 1,
    name: "Helping with Homework",
    subtext: "Starts in September or October",
  },
  {
    id: 2,
    name: "Conference Conversations",
    subtext: "Starts in October or November",
  },
  {
    id: 3,
    name: "Doctor's Orders",
    subtext: "Starts in November or March",
  },
  {
    id: 4,
    name: "Make Time for Reading Together",
    subtext: "Starts in December",
  },
  {
    id: 5,
    name: "Spreading Kindness",
    subtext: "Starts in January",
  },
  {
    id: 6,
    name: "Arts in Education",
    subtext: "Starts in February",
  },
  {
    id: 7,
    name: "Math Matters",
    subtext: "Starts in March or April",
  },
  {
    id: 8,
    name: "Positive Parenting",
    subtext: "Starts in April or May",
  },
  {
    id: 9,
    name: "Celebrating Parents",
    subtext: "Starts in May or June",
  },
];

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "30 0",
  },
  headerSection: {
    padding: "0 20 20 20",
    borderBottom: "1pt solid #000",
    marginBottom: "10",
  },
  section: {
    padding: 20,
  },
  row: {
    flexDirection: "row",
  },
  questionHeader: {
    padding: "0 0 20 0",
  },
  questionHeaderText: {
    fontSize: "15pt",
  },
  questionText: {
    fontSize: "12pt",
  },
  questionTextContainer: {
    padding: "0 0 15 0",
  },
  flexItem: {
    flex: 1,
  },
  scaleHeader: {
    fontSize: "12pt",
    textAlign: "center",
  },
  questionRow: {
    flexDirection: "row",
    padding: "0 0 10 0",
  },
  questionScaleBottomRow: {
    flexDirection: "row",
    padding: "0 0 20 0",
  },
});

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

function SchoolParentResponses(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);

  /* Evaluation */
  const [evaluationAnswers, setEvaluationAnswers] = useState([]);
  const [totalResponses, setTotalResponses] = useState(0);
  const [comments, setComments] = useState([]);
  const [openEndedQuestions, setOpenEndedQuestions] = useState([]);

  /* Scorcard */
  const [scorecardAnswers, setScorecardAnswers] = useState([]);
  const [totalResponsesWeekly, setTotalResponsesWeekly] = useState(0);
  const [guardians, setGuardians] = useState([]);
  const [guardiansWeekly, setGuardiansWeekly] = useState([]);

  /* App Analytics */
  const [timeFrame, setTimeFrame] = useState("August");
  const [analytics, setAnalytics] = useState(null);
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRangeFocus, setDateRangeFocus] = useState("startDate");

  const [responseModal, setResponseModal] = useState(false);
  const [responseModalTitle, setResponseModalTitle] = useState("");
  const [responsesInfo, setResponsesInfo] = useState([]);
  const [responsesActivityId, setResponsesActivityId] = useState("");
  const handleResponsesOpen = async (responses, activityId, activityName) => {
    setResponseModalTitle(activityName);
    setResponsesInfo(responses);
    setResponsesActivityId(activityId);
    setResponseModal(true);
  };
  const handleResponsesClose = async () => {
    setResponseModalTitle("");
    setResponsesInfo([]);
    setResponseModal(false);
  };

  /* User Uploads */
  const [uploads, setUploads] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteDialogInfo, setDeleteDialogInfo] = useState(null);
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteUpload = async () => {
    try {
      if (deleteDialogInfo) {
        if (deleteDialogInfo.id) {
          await ParentPartiesAPIs.deleteUpload(deleteDialogInfo);
          setUploads(
            uploads.filter((upload) => upload.id !== deleteDialogInfo.id)
          );
          setDeleteDialog(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function getResponses() {
      const responses = await ParentPartiesAPIs.readSchoolEvaluationResponses(
        sceneId
      );
      const guardiansWithEvaluations = await ParentPartiesAPIs.fetchGuardainsEvaluations(
        sceneId
      );
      setGuardians(guardiansWithEvaluations);

      setTotalResponses(responses ? responses.length : 0);
      const questions = responses
        ? [
            ...new Set(
              responses
                .map((res) =>
                  res.responses
                    ? res.responses.map((res1) => res1.question)
                    : []
                )
                .flat()
            ),
          ]
        : [];
      const arrayOfResponses = responses
        ? responses.map((res) => res.responses).flat()
        : [];

      // Gen open-ended responses
      const openEndedQuestions = responses
        ? responses
            .map((res) => ({
              question: res.topic
                ? "A topic I would like to see on the Parent Parties Engage app is:"
                : res.feedbackQuestion
                ? res.feedbackQuestion
                : "",
              val: res.feedback || res.topic,
            }))
            .filter((i) => i.val)
        : [];
      let arrayedOpenEndedQuestions = [];
      openEndedQuestions.map((obj) => {
        const index = arrayedOpenEndedQuestions.findIndex(
          (z) => z.question === obj.question
        );
        if (index === -1) {
          arrayedOpenEndedQuestions.push({
            question: obj.question,
            val: [obj.val],
          });
        } else {
          arrayedOpenEndedQuestions[index].val.push(obj.val);
        }
        return null;
      });

      setOpenEndedQuestions(arrayedOpenEndedQuestions);

      // Comments
      const comments = responses
        ? [...new Set(responses.map((res) => res.comments))].filter((i) => i)
        : [];
      setComments(comments);

      const arrayOfAnswers = questions
        ? questions
            .map((question) => {
              const questionResponses = arrayOfResponses.filter((res) =>
                res ? res.question === question : false
              );

              const oneRating = questionResponses.filter((a) => a.answer === 1)
                .length;
              const twoRating = questionResponses.filter((a) => a.answer === 2)
                .length;
              const threeRating = questionResponses.filter(
                (a) => a.answer === 3
              ).length;
              const fourRating = questionResponses.filter((a) => a.answer === 4)
                .length;
              const fiveRating = questionResponses.filter((a) => a.answer === 5)
                .length;

              const totalRating = questionResponses.reduce((a, b) => {
                return a + (b.answer || 0);
              }, 0);

              const average = (totalRating / questionResponses.length).toFixed(
                1
              );
              const percent = average ? (average / 5) * 100 : 0;

              return {
                question,
                totalResponses: questionResponses.length,
                average,
                percent,
                oneRating,
                twoRating,
                threeRating,
                fourRating,
                fiveRating,
              };
            })
            .sort((a, b) => b.totalResponses - a.totalResponses)
        : [];
      setEvaluationAnswers(arrayOfAnswers);
      setInitialLoad(false);
    }
    async function getWeeklyResponses() {
      const responses = await ParentPartiesAPIs.readSchoolResponses(sceneId);
      const guardiansWithScorecards = await ParentPartiesAPIs.fetchGuardainsScorecards(
        sceneId
      );
      setGuardiansWeekly(guardiansWithScorecards);

      setTotalResponsesWeekly(responses ? responses.length : 0);
      const questions = responses
        ? [
            ...new Set(
              responses
                .map((res) => res.responses.map((res1) => res1.question))
                .flat()
            ),
          ]
        : [];
      const arrayOfResponses = responses
        ? responses.map((res) => res.responses).flat()
        : [];
      const arrayOfAnswers = questions
        ? questions
            .map((question) => {
              const trueAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === true
              ).length;
              const falseAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === false
              ).length;
              const unansweredAmount = arrayOfResponses.filter(
                (res) => res.question === question && res.answer === "none"
              ).length;
              const totalAmount = trueAmount + falseAmount;
              const truePercent = trueAmount
                ? (trueAmount / totalAmount) * 100
                : 0;
              const falsePercent = falseAmount
                ? (falseAmount / totalAmount) * 100
                : 0;
              const unansweredPercent = unansweredAmount
                ? (unansweredAmount / totalAmount) * 100
                : 0;
              const percentage = totalAmount
                ? totalAmount / arrayOfResponses.length
                : 0;

              return {
                question,
                trueAmount,
                falseAmount,
                unansweredAmount,
                totalAmount,
                percentage,
                truePercent,
                falsePercent,
                unansweredPercent,
              };
            })
            .sort((a, b) => b.totalAmount - a.totalAmount)
        : [];
      setScorecardAnswers(arrayOfAnswers);
    }
    async function fetchData(dateRange, sceneId) {
      try {
        const goal = moment().set({ date: 15 }).valueOf();
        const closestMonth = dateRange.reduce((prev, curr) =>
          Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
        );

        setTimeFrame(closestMonth);

        const startDate = moment(closestMonth).startOf("month");
        const endDate = moment(closestMonth).endOf("month");

        const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
          sceneId,
          startDate,
          endDate
        );
        setAnalytics(result);
      } catch (err) {
        console.log(err);
      }
    }

    if (props.app.dateRange && props.scene) {
      /* Closest date */
      fetchData(props.app.dateRange, props.scene.id);
    }

    async function getUploads() {
      const uploads = await ParentPartiesAPIs.readSchoolUploads(sceneId);
      const arrayOfUploads = uploads ? Object.values(uploads).flat() : [];
      setUploads(arrayOfUploads);
    }

    getUploads();

    getResponses();
    getWeeklyResponses();
  }, [props.app.dateRange, props.scene, sceneId]);

  const [chartView, setChartView] = useState("Activity Views");
  const changeChartView = (chartType) => {
    setChartView(chartType);
  };
  const changeTimeFrame = async (timeFrame) => {
    const sceneId = props.scene ? props.scene.id : null;
    if (!sceneId) {
      return;
    }
    if (timeFrame === "Custom" || timeFrame === "School Year") {
      return;
    }
    // Reset Date Range
    else {
      setDateRangeValue({
        startDate: null,
        endDate: null,
      });
    }
    setTimeFrame(timeFrame);

    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  const handleCloseDateRange = () => {
    setDateRangeDialog(false);
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
  };
  const handleDateRangePick = async () => {
    setTimeFrame("Custom");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = dateRangeValue.startDate
      ? moment(dateRangeValue.startDate).startOf("day")
      : dateRangeValue.endDate;
    const endDate = dateRangeValue.endDate
      ? moment(dateRangeValue.endDate).endOf("day")
      : moment(dateRangeValue.startDate).endOf("day");

    if (startDate === null && endDate === null) {
      return handleCloseDateRange();
    }

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
    setDateRangeDialog(false);
  };
  const handleSchoolYearPick = async () => {
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
    setTimeFrame("School Year");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = props.app.dateRange
      ? props.app.dateRange[0]
        ? moment(props.app.dateRange[0]).startOf("month")
        : null
      : null;
    const endDate = props.app.dateRange
      ? props.app.dateRange.length
        ? moment(props.app.dateRange[props.app.dateRange.length - 1]).endOf(
            "month"
          )
        : null
      : null;

    const result = await ParentPartiesAPIs.fetchSchoolActivityAnalytics(
      sceneId,
      startDate,
      endDate
    );

    setAnalytics(result);
  };

  const handleSendFeedback = async (response, feedback) => {
    const activityIndex = analytics.activites.findIndex(
      (i) => i.id === responsesActivityId
    );
    const activityItem = analytics.activites[activityIndex];

    /* Backend */
    await ParentPartiesAPIs.createUserNotifcation({
      activityName: activityItem.name,
      activityId: responsesActivityId,
      schoolId: props.scene.id,
      responseId: response.id,
      userId: response.userId,
      feedback,
      feedbackEmail: props.user ? props.user.email.address : "",
      responses: response.responses,
    });

    if (activityIndex > -1) {
      /* Find Response */
      const responseIndex = activityItem.responses.findIndex(
        (res) => res.id === response.id
      );
      if (responseIndex > -1) {
        const newAnalytics = update(analytics, {
          activites: {
            [activityIndex]: {
              responses: {
                [responseIndex]: {
                  $set: {
                    ...activityItem.responses[responseIndex],
                    feedback,
                    feedbackEmail: props.user ? props.user.email.address : "",
                  },
                },
              },
            },
          },
        });

        setAnalytics(newAnalytics);
      }
    }
  };

  let chartOptions = {
    stroke: {
      show: true,
      curve: "smooth",
      width: 2,
    },
    chart: {
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
    // labels: dateRange,
    xaxis: {
      type: "datetime",
      labels: {
        trim: false,
        rotate: 0,
        minHeight: 40,
        hideOverlappingLabels: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      strokeDashArray: "5",
      borderColor: "rgba(125, 138, 156, 0.3)",
    },
    legend: {
      show: true,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      x: {
        format: "MMMM yyyy",
      },
    },
  };

  const handleRenderChart = (chartView) => {
    switch (chartView) {
      case "Activity Views":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Activity Views",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityViews
                      ? analytics.charts.activityViews.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      case "Activites Completed by Type":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
              colors: [
                "#018FFB",
                "#01E396",
                "#FEB019",
                "#FF4460",
                "#785DD0",
                "#000",
              ],
            }}
            series={[
              {
                name: "Video",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity0
                    : []
                  : [],
              },
              {
                name: "Mutliple Choice",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity1
                    : []
                  : [],
              },
              {
                name: "General",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity2
                    : []
                  : [],
              },
              {
                name: "File",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity4
                    : []
                  : [],
              },
              {
                name: "Link",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.activityByType.activity5
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "Total Points":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Total Points",
                data: analytics
                  ? analytics.charts
                    ? analytics.charts.totalPoints
                      ? analytics.charts.totalPoints.data
                      : []
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      default:
        return null;
    }
  };

  const generateExport = async () => {
    const doc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.headerSection}>
            <Text style={{ fontWeight: 600 }}>
              {props.scene
                ? props.scene.data
                  ? props.scene.data.name
                  : ""
                : ""}
            </Text>
            <Text style={{ fontSize: "12pt" }}>
              Parent Evaluation Responses
            </Text>
          </View>

          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>Questions</Text>
            </View>
            {evaluationAnswers.map((item, index) => (
              <View>
                <View style={styles.questionTextContainer}>
                  <Text style={styles.questionText}>
                    {`${index + 1}) ${item.question}`}
                  </Text>
                </View>
                <View>
                  <View style={styles.questionRow}>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Strongly Disagree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Disagree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Neutral</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Agree</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>Strongly Agree</Text>
                    </View>
                  </View>
                  <View style={styles.questionScaleBottomRow}>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.oneRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.twoRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.threeRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.fourRating}</Text>
                    </View>
                    <View style={styles.flexItem}>
                      <Text style={styles.scaleHeader}>{item.fiveRating}</Text>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>
                Open-ended Responses
              </Text>
            </View>
            {openEndedQuestions.map((obj, index) => (
              <View style={{ marginTop: index === 0 ? 0 : 15 }}>
                <View style={{ paddingBottom: "8pt" }}>
                  <Text style={styles.questionText}>{`${obj.question}`}</Text>
                </View>
                <View style={styles.questionTextContainer}>
                  {obj.val
                    ? obj.val.map((val) => (
                        <View>
                          <Text style={styles.questionText}>{val}</Text>
                        </View>
                      ))
                    : null}
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={styles.questionHeader}>
              <Text style={styles.questionHeaderText}>Additional Comments</Text>
            </View>
            {comments.map((comment) => (
              <View>
                <View style={styles.questionTextContainer}>
                  <Text style={styles.questionText}>{`- ${comment}`}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `PPE-Evaluation-Responses.pdf`);
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                View Statistics
              </div>
            </div>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={12} xl={12}>
              {mobile ? null : (
                <List
                  component="div"
                  className="nav-line-alt nav-line d-flex align-items-center mt-3"
                >
                  <ListItem
                    button
                    component={NavLink}
                    to={`/monthly-analytics/${sceneType}/${sceneId}`}
                    className="pr-2"
                  >
                    <span className="font-size-sm">{"Monthly Analytics"}</span>
                    <div className="divider" />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to={`/analytics/${sceneType}/${sceneId}`}
                    className="px-2"
                  >
                    <span className="font-size-sm">{"Reports"}</span>
                    <div className="divider" />
                  </ListItem>
                  <ListItem button selected className="px-2">
                    <span className="font-size-sm">{"Parent Responses"}</span>
                    <div className="divider" />
                  </ListItem>
                </List>
              )}
            </Grid>
            <Grid item md={12}>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <div
                    className="heading-4 text-left mb-0 font-size-md"
                    style={{
                      color: "#000",
                      textTransform: "none",
                    }}
                  >
                    {`Evaluations`}
                  </div>
                </div>
              </div>
              <Grid
                container
                spacing={0}
                style={{
                  borderTop: "1px solid #d3d3d3",
                  paddingBottom: "30px",
                }}
              >
                <Grid item md={12}>
                  <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
                    <div>
                      <div
                        className="heading-4 text-left mb-0 font-size-sm"
                        style={{
                          color: "#000",
                          textTransform: "none",
                        }}
                      >
                        {`Total Evaluation Responses: ${
                          totalResponses ? totalResponses : 0
                        }`}
                      </div>
                      <div>
                        <small className="text-black-50">
                          Average response rating for each parent evaluation
                          question.
                        </small>
                      </div>
                    </div>
                    <Button
                      className="btn-primary shadow-none ml-4"
                      size="medium"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={generateExport}
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon
                          style={{ fontSize: "12px" }}
                          icon={["fas", "file-export"]}
                        />
                      </span>
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: "12px" }}
                      >
                        Export Responses
                      </span>
                    </Button>
                  </div>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    {evaluationAnswers.map((item, index) => (
                      <ListItem className="pt-3 pb-3 d-block" key={index}>
                        <div className="align-box-row py-1">
                          <div>
                            <div className="text-black">{item.question}</div>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-item-center mt-2">
                          <div
                            style={{
                              width: "100%",
                              marginRight: 16,
                            }}
                          >
                            <LinearProgress
                              variant="determinate"
                              className={`${
                                item.percent <= 40
                                  ? "progress-bar-danger"
                                  : item.percent > 40 && item.percent < 70
                                  ? "progress-bar-warning"
                                  : "progress-bar-success"
                              } w-100`}
                              value={item.percent}
                              style={{ height: ".5rem" }}
                            />
                            <div className="d-flex flex-row justify-content-between">
                              <div className="text-black-50 font-size-sm">
                                Strongly Disagree
                              </div>
                              <div className="text-black-50 font-size-sm">
                                Strongly Agree
                              </div>
                            </div>
                          </div>
                          <div style={{ minWidth: 180 }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{ color: "#000" }}
                                className="font-size-sm"
                              >
                                {`${item.average} Average Rating`}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{ color: "#000" }}
                                className="font-size-sm"
                              >
                                {`${item.totalResponses} responses`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    ))}
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
                    <div>
                      <div
                        className="heading-4 text-left mb-0 font-size-sm"
                        style={{
                          color: "#000",
                          textTransform: "none",
                        }}
                      >
                        Open-ended Responses
                      </div>
                    </div>
                  </div>
                  <Card>
                    <CardContent>
                      {openEndedQuestions.map((obj, index) => (
                        <div
                          className={
                            index === 0 ? "text-black" : "mt-4 text-black"
                          }
                        >
                          <div
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                            className="mb-1"
                          >
                            {`${obj.question}`}
                          </div>
                          {obj.val
                            ? obj.val.map((val) => (
                                <div
                                  className="text-black"
                                  style={{ fontSize: "14px" }}
                                >
                                  {val}
                                </div>
                              ))
                            : null}
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
                    <div>
                      <div
                        className="heading-4 text-left mb-0 font-size-sm"
                        style={{
                          color: "#000",
                          textTransform: "none",
                        }}
                      >
                        {`Additional Comments`}
                      </div>
                    </div>
                  </div>
                  <Card>
                    <CardContent>
                      {comments.map((comment, index) => (
                        <div
                          className={
                            index === 0 ? "text-black" : "mt-1 text-black"
                          }
                          style={{ fontSize: "14px" }}
                        >
                          {comment}
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <AccordionComp
                    title={
                      <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
                        <div>
                          <div
                            className="heading-4 text-left mb-0 font-size-sm"
                            style={{
                              color: "#000",
                              textTransform: "none",
                            }}
                          >
                            {`User Responses`}
                          </div>
                        </div>
                      </div>
                    }
                    body={
                      <div className="w-100">
                        {guardians.map((guardian, index) => (
                          <Card
                            style={
                              index === 0
                                ? {
                                    marginTop: 10,
                                    border: "1px solid rgb(233 232 232)",
                                  }
                                : {
                                    marginTop: 20,
                                    border: "1px solid rgb(233 232 232)",
                                  }
                            }
                          >
                            <CardContent>
                              <div className="d-flex flex-row align-items-center justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  {guardian ? (
                                    guardian.avatar ? (
                                      <Avatar
                                        src={guardian.avatar}
                                        style={{ width: 30, height: 30 }}
                                        className="mr-2"
                                      />
                                    ) : null
                                  ) : null}
                                  <div
                                    className="text-black"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {guardian
                                      ? guardian.username
                                        ? guardian.username
                                        : "Guardian"
                                      : "Guardian"}
                                  </div>
                                </div>
                                <Typography
                                  style={{
                                    color: "rgb(143, 143, 145)",
                                    fontSize: "14px",
                                    width: 200,
                                    textAlign: "right",
                                  }}
                                  className="ml-2"
                                >
                                  {moment(Number(guardian.start)).format(
                                    "MMMM YYYY"
                                  )}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: 10,
                                }}
                              >
                                {guardian.response.responses
                                  ? guardian.response.responses.map(
                                      (question) => {
                                        const percent =
                                          (question.answer / 5) * 100;
                                        return (
                                          <ListItem
                                            className="pt-3 pb-3 d-block"
                                            key={`${guardian.uid}-${question.question}-${guardian.start}`}
                                          >
                                            <div className="align-box-row py-1">
                                              <div>
                                                <div
                                                  className="text-black"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  {question.question}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex flex-row align-item-center mt-2">
                                              <div
                                                style={{
                                                  width: "100%",
                                                  marginRight: 16,
                                                }}
                                              >
                                                <LinearProgress
                                                  variant="determinate"
                                                  className={`${
                                                    percent <= 40
                                                      ? "progress-bar-danger"
                                                      : percent > 40 &&
                                                        percent < 70
                                                      ? "progress-bar-warning"
                                                      : "progress-bar-success"
                                                  } w-100`}
                                                  value={percent}
                                                  style={{ height: ".5rem" }}
                                                />
                                                <div className="d-flex flex-row justify-content-between">
                                                  <div className="text-black-50 font-size-sm">
                                                    Strongly Disagree
                                                  </div>
                                                  <div className="text-black-50 font-size-sm">
                                                    Strongly Agree
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </ListItem>
                                        );
                                      }
                                    )
                                  : null}
                                {guardian.response.topic ? (
                                  <div className="mt-4 px-3">
                                    <div
                                      style={{ fontSize: 14, color: "#000" }}
                                      className="mr-2"
                                    >
                                      A topic I would like to see on the Parent
                                      Parties Engage app is:
                                    </div>
                                    <div className="mt-2">
                                      <TextField
                                        value={guardian.response.topic}
                                        variant="outlined"
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        hiddenLabel
                                        size="small"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {guardian.response.feedback ? (
                                  <div className="mt-4 px-3">
                                    <div
                                      style={{ fontSize: 14, color: "#000" }}
                                      className="mr-2"
                                    >
                                      {guardian.response.feedbackQuestion}
                                    </div>
                                    <div className="mt-2">
                                      <TextField
                                        value={guardian.response.feedback}
                                        variant="outlined"
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        hiddenLabel
                                        size="small"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {guardian.response.comments ? (
                                  <div className="mt-4 px-3">
                                    <div
                                      style={{ fontSize: 14, color: "#000" }}
                                      className="mr-2"
                                    >
                                      Additional Comments:
                                    </div>
                                    <div className="mt-2">
                                      <TextField
                                        value={guardian.response.comments}
                                        variant="outlined"
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        hiddenLabel
                                        size="small"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div>
                  <div
                    className="heading-4 text-left mb-0 font-size-md"
                    style={{
                      color: "#000",
                      textTransform: "none",
                    }}
                  >
                    {`Weekly Scorecard`}
                  </div>
                </div>
              </div>
              <Grid
                container
                spacing={0}
                style={{
                  borderTop: "1px solid #d3d3d3",
                  paddingBottom: "30px",
                }}
              >
                <Grid item md={12}>
                  <div className="mt-4 pt-4 pb-4">
                    <div>
                      <div
                        className="heading-4 text-left mb-0"
                        style={{
                          color: "#000",
                          textTransform: "none",
                        }}
                      >
                        {`Total Scorecard Responses: ${
                          totalResponsesWeekly ? totalResponsesWeekly : 0
                        }`}
                      </div>
                    </div>
                  </div>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <>
                      <List component="div" className="list-group-flush">
                        {scorecardAnswers.map((scorecardAnswer, index) => {
                          return (
                            <ListItem className="pt-3 pb-3 d-block" key={index}>
                              <div className="align-box-row py-1">
                                <div>
                                  <div className="text-black">
                                    {scorecardAnswer.question}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-item-center">
                                <div
                                  style={{
                                    width: "100%",
                                    marginRight: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className="progress-bar-success w-100"
                                    value={scorecardAnswer.truePercent}
                                    style={{ height: ".5rem" }}
                                  />
                                </div>
                                <div
                                  style={{
                                    minWidth: 200,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="font-size-sm text-black">
                                    Yes
                                  </div>
                                  <div
                                    style={{ color: "#000" }}
                                    className="font-size-sm"
                                  >{`${
                                    scorecardAnswer.trueAmount
                                  } (${Math.round(
                                    scorecardAnswer.truePercent
                                  )}%)`}</div>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-item-center">
                                <div
                                  style={{
                                    width: "100%",
                                    marginRight: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className="progress-bar-danger w-100"
                                    value={scorecardAnswer.falsePercent}
                                    style={{ height: ".5rem" }}
                                  />
                                </div>
                                <div
                                  style={{
                                    minWidth: 200,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="font-size-sm text-black">
                                    No
                                  </div>
                                  <div
                                    style={{ color: "#000" }}
                                    className="font-size-sm"
                                  >{`${
                                    scorecardAnswer.falseAmount
                                  } (${Math.round(
                                    scorecardAnswer.falsePercent
                                  )}%)`}</div>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-item-center">
                                <div
                                  style={{
                                    width: "100%",
                                    marginRight: 16,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    className="progress-bar-warning w-100"
                                    value={scorecardAnswer.unansweredPercent}
                                    style={{ height: ".5rem" }}
                                  />
                                </div>
                                <div
                                  style={{
                                    minWidth: 200,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="font-size-sm text-black">
                                    Unanswered
                                  </div>
                                  <div
                                    style={{ color: "#000" }}
                                    className="font-size-sm"
                                  >{`${
                                    scorecardAnswer.unansweredAmount
                                  } (${Math.round(
                                    scorecardAnswer.unansweredPercent
                                  )}%)`}</div>
                                </div>
                              </div>
                            </ListItem>
                          );
                        })}
                      </List>
                    </>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <AccordionComp
                    title={
                      <div className="d-flex flex-row mb-4 align-items-center justify-content-between mt-4 pt-4">
                        <div>
                          <div
                            className="heading-4 text-left mb-0"
                            style={{
                              color: "#000",
                              textTransform: "none",
                            }}
                          >
                            {`User Responses`}
                          </div>
                        </div>
                      </div>
                    }
                    body={
                      <div className="w-100">
                        {guardiansWeekly.map((guardian, index) => (
                          <Card
                            style={
                              index === 0
                                ? {
                                    marginTop: 10,
                                    border: "1px solid rgb(233 232 232)",
                                  }
                                : {
                                    marginTop: 20,
                                    border: "1px solid rgb(233 232 232)",
                                  }
                            }
                          >
                            <CardContent>
                              <div className="d-flex flex-row align-items-center justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  {guardian ? (
                                    guardian.avatar ? (
                                      <Avatar
                                        src={guardian.avatar}
                                        style={{ width: 30, height: 30 }}
                                        className="mr-2"
                                      />
                                    ) : null
                                  ) : null}
                                  <div
                                    className="text-black"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {guardian
                                      ? guardian.username
                                        ? guardian.username
                                        : "Guardian"
                                      : "Guardian"}
                                  </div>
                                </div>
                                <Typography
                                  style={{
                                    color: "rgb(143, 143, 145)",
                                    fontSize: "14px",
                                    width: 200,
                                    textAlign: "right",
                                  }}
                                  className="ml-2"
                                >
                                  {`${moment(guardian.start)
                                    .startOf("isoWeek")
                                    .format("MMM Do")} - ${moment(guardian.end)
                                    .endOf("isoWeek")
                                    .format("MMM Do")}`}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: 10,
                                }}
                              >
                                {guardian.response.map((question) => (
                                  <FormControlLabel
                                    style={{ cursor: "default" }}
                                    control={
                                      <Checkbox
                                        checked={question.answer}
                                        size={"small"}
                                      />
                                    }
                                    label={
                                      <div
                                        style={{ fontSize: 14, color: "#000" }}
                                      >
                                        {question.question}
                                      </div>
                                    }
                                  />
                                ))}
                              </div>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-4 mb-2">
                <div
                  className="heading-4 text-left font-size-md"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                    color: "#000",
                  }}
                >
                  {"Activity Analytics"}
                </div>
                {props.app.dateRange ? (
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ boxShadow: "none" }}
                  >
                    <Select
                      autoWidth={false}
                      value={timeFrame}
                      renderValue={(value) => {
                        return value === "Custom"
                          ? `${
                              dateRangeValue.startDate
                                ? moment(dateRangeValue.startDate).format("ll")
                                : ""
                            }${
                              dateRangeValue.endDate
                                ? `${
                                    dateRangeValue.startDate
                                      ? ` - ${moment(
                                          dateRangeValue.endDate
                                        ).format("ll")}`
                                      : moment(dateRangeValue.endDate).format(
                                          "ll"
                                        )
                                  }`
                                : ""
                            }`
                          : value === "School Year"
                          ? "School Year"
                          : moment(value).format("MMMM YYYY");
                      }}
                      onChange={(e) => {
                        changeTimeFrame(e.target.value);
                      }}
                      classes={{
                        select: classes.selectStyles,
                      }}
                      input={
                        <OutlinedInput
                          classes={{
                            root: classes.fixInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                          }}
                        />
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {(props.app.dateRange ? props.app.dateRange : []).map(
                        (date) => (
                          <MenuItem value={date} dense key={date}>
                            {moment(date).format("MMMM YYYY")}
                          </MenuItem>
                        )
                      )}
                      <MenuItem
                        value={"School Year"}
                        dense
                        onClick={() => {
                          handleSchoolYearPick();
                        }}
                      >
                        School Year
                      </MenuItem>
                      <MenuItem
                        value={"Custom"}
                        dense
                        onClick={() => {
                          setDateRangeDialog(true);
                        }}
                      >
                        Custom
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </div>
              <Grid
                container
                spacing={4}
                style={{
                  paddingBottom: "30px",
                }}
              >
                <Grid item md={12}>
                  <Card
                    className="p-3"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ boxShadow: "none" }}
                    >
                      <Select
                        value={chartView}
                        onChange={(e) => {
                          changeChartView(e.target.value);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={"Activity Views"} dense>
                          Activity Views
                        </MenuItem>
                        <MenuItem value={"Activites Completed by Type"} dense>
                          Activites Completed by Type
                        </MenuItem>
                        <MenuItem value={"Total Points"} dense>
                          Total Points
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {handleRenderChart(chartView)}
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <div
                    className="heading-4 text-left font-size-sm"
                    style={{
                      textTransform: "none",
                      marginRight: "10px",
                      marginBottom: 0,
                      color: "#000",
                      marginTop: "3rem",
                    }}
                  >
                    {"Activity Performance & Responses"}
                  </div>
                  <div className="mt-4">
                    <AGGrid
                      rowData={
                        analytics
                          ? analytics.activites
                            ? analytics.activites
                            : []
                          : []
                      }
                      search={false}
                      autoHeight={true}
                      columnDefs={[
                        {
                          headerName: "Name",
                          field: "name",
                          sortable: true,
                          filter: true,
                        },
                        {
                          headerName: "Views",
                          field: "views",
                          sortable: true,
                          filter: true,
                        },
                        {
                          headerName: "Completions",
                          field: "timesCompleted",
                          sortable: true,
                          filter: true,
                        },
                        {
                          headerName: "Theme",
                          field: "theme",
                          sortable: true,
                          filter: true,
                          valueGetter: function (params) {
                            if (params.data.theme !== undefined) {
                              return themes.find(
                                (theme) => theme.id === params.data.theme
                              )
                                ? themes.find(
                                    (theme) => theme.id === params.data.theme
                                  ).name
                                : "";
                            } else {
                              return null;
                            }
                          },
                        },
                        {
                          width: 280,
                          suppressSizeToFit: true,
                          field: "actions",
                          headerName: "Actions",
                          cellRendererFramework: (params) => (
                            <div className="d-flex flex-row align-items-center">
                              {params.data.responses ? (
                                params.data.responses.length ? (
                                  <btn
                                    className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-primary shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                      handleResponsesOpen(
                                        params.data.responses,
                                        params.data.id,
                                        params.data.name
                                      );
                                    }}
                                  >
                                    <span className="MuiButton-label">
                                      <span
                                        className="btn-wrapper--label text-white"
                                        style={{ fontSize: "12px" }}
                                      >
                                        View Responses
                                      </span>
                                    </span>
                                    <span className="MuiTouchRipple-root"></span>
                                  </btn>
                                ) : null
                              ) : null}
                            </div>
                          ),
                          cellClass: "agGridCellCenter",
                        },
                      ]}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                <div>
                  <div
                    className="heading-4 text-left mb-0 font-size-md"
                    style={{
                      color: "#000",
                      textTransform: "none",
                    }}
                  >
                    {`Photo Uploads`}
                  </div>
                </div>
              </div>
              <Grid container spacing={0}>
                <Grid item md={12}>
                  <AGGrid
                    smallTitle={true}
                    titleGrid={
                      <div>
                        <div className="mt-1">
                          <small className="text-black-50">
                            File uploads from guardians completing the Engage at
                            Home activity.
                          </small>
                        </div>
                      </div>
                    }
                    search={false}
                    statusBar={false}
                    rowData={uploads}
                    columnDefs={[
                      {
                        headerName: "Username",
                        field: "userName",
                        sortable: true,
                        filter: true,
                      },
                      {
                        headerName: "Upload Date",
                        field: "time",
                        sortable: true,
                        filter: "agDateColumnFilter",
                        valueFormatter: function (params) {
                          if (params.data.time) {
                            return moment(params.data.time).format("L");
                          } else {
                            return null;
                          }
                        },
                        comparator: dateComparator,
                      },
                      {
                        width: 300,
                        suppressSizeToFit: true,
                        field: "actions",
                        headerName: "Actions",
                        cellRendererFramework: (params) => (
                          <div className="d-flex flex-row align-items-center">
                            <a
                              href={params.data.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                              tabIndex="0"
                              type="button"
                            >
                              <span className="MuiButton-label">
                                <span
                                  className="btn-wrapper--label text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  Preview
                                </span>
                              </span>
                              <span className="MuiTouchRipple-root"></span>
                            </a>
                            <button
                              className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained btn-danger shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                              tabIndex="0"
                              type="button"
                              onClick={() => {
                                setDeleteDialogInfo(params.data);
                                setDeleteDialog(true);
                              }}
                            >
                              <span className="MuiButton-label">
                                <span
                                  className="btn-wrapper--label text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  Delete
                                </span>
                              </span>
                              <span className="MuiTouchRipple-root"></span>
                            </button>
                          </div>
                        ),
                        cellClass: "agGridCellCenter",
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={dateRangeDialog}
          onClose={handleCloseDateRange}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "rgb(42, 42, 47)",
              // borderBottom: "none",
            }}
          >
            Select Date Range
          </DialogTitle>
          <DialogContent
            dividers={false}
            className="d-flex flex-row justify-content-center my-4"
          >
            <DayPickerRangeController
              numberOfMonths={2}
              onDatesChange={(dates) => {
                setDateRangeValue({
                  startDate: dates.startDate,
                  endDate: dates.endDate,
                });
              }}
              startDate={dateRangeValue.startDate}
              endDate={dateRangeValue.endDate}
              focusedInput={dateRangeFocus}
              onFocusChange={(focusedInput) => {
                setDateRangeFocus(!focusedInput ? "startDate" : focusedInput);
              }}
              hideKeyboardShortcutsPanel={true}
            />
          </DialogContent>
          <DialogActions className="p-3">
            <Button onClick={handleCloseDateRange}>Cancel</Button>
            <Button
              className="btn-primary"
              onClick={handleDateRangePick}
              autoFocus
            >
              Finish
            </Button>
          </DialogActions>
        </Dialog>
        {/* Responses Modal */}
        <ResponsesModal
          responseModalTitle={responseModalTitle}
          responses={responsesInfo}
          responseModal={responseModal}
          handleResponsesClose={handleResponsesClose}
          handleSendFeedback={handleSendFeedback}
        />
        <Dialog
          open={deleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"sm"}
          PaperProps={{
            className: "p-3",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "rgb(42, 42, 47)",
              borderBottom: "none",
            }}
          >
            {`Are you sure you want to delete this upload?`}
          </DialogTitle>
          <DialogActions style={{ borderTop: "none" }} className="mt-4">
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button
              className="btn-danger"
              onClick={() => handleDeleteUpload()}
              autoFocus
            >
              {"Delete Upload"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolParentResponses);
