import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";

import { NavLink, useLocation } from "react-router-dom";
import {
  setSidebarToggleMobile,
  userSelector,
  premiumSelector,
  sceneSelector,
  readSceneAction,
} from "../../core";

import SidebarUserbox from "../SidebarUserbox";

import HomeTwoTone from "@material-ui/icons/HomeTwoTone";
import SchoolIcon from "@material-ui/icons/SchoolTwoTone";
import EqualizerTwoToneIcon from "@material-ui/icons/EqualizerTwoTone";
import TeamTwoToneIcon from "@material-ui/icons/SupervisorAccountTwoTone";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import LocationCityOutlinedIcon from "@material-ui/icons/LocationCityOutlined";
import { ReactComponent as GuardiansIcon } from "../../assets/images/guardians.svg";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import EmojiEventsTwoToneIcon from "@material-ui/icons/EmojiEventsTwoTone";
import PhoneIcon from "@material-ui/icons/PhoneIphoneTwoTone";
import { SchoolTwoTone } from "@material-ui/icons";
import SentimentSatisfiedTwoToneIcon from "@material-ui/icons/SentimentSatisfiedTwoTone";

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const location = useLocation();
  const { pathname } = location;

  const params = pathname.split("/");
  const pathRoute = params[1];
  const sceneType = props.scene
    ? props.scene.type === "school"
      ? "s"
      : "d"
    : null;
  const sceneId = props.scene
    ? props.scene.data
      ? props.scene.data.id
      : null
    : null;

  const sceneData = props.scene
    ? props.scene.data
      ? props.scene.data
      : null
    : null;
  const schoolPlan = sceneData ? (sceneData.plan ? sceneData.plan : "") : "";

  const simpleInterface = sceneData ? sceneData.simpleInterface : false;

  return (
    <>
      <PerfectScrollbar style={{ paddingBottom: "4rem" }}>
        {sidebarUserbox && sceneId && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <ul>
            {sceneId ? (
              sceneId ? (
                schoolPlan === "In-school Curriculum" ? (
                  <>
                    <li>
                      <NavLink
                        activeClassName="active"
                        onClick={() => {
                          toggleSidebarMobile();
                        }}
                        className="nav-link-simple"
                        to={`/resources-in-person/${sceneType}/${sceneId}`}
                        isActive={() =>
                          [
                            "resources-in-person",
                            "resources-in-person-general",
                          ].includes(pathRoute)
                        }
                      >
                        <span className="sidebar-icon">
                          <PermMediaOutlinedIcon />
                        </span>
                        Resources
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        isActive={() =>
                          ["team", "edit-team"].includes(pathRoute)
                        }
                        onClick={toggleSidebarMobile}
                        className="nav-link-simple"
                        to={`/team/${sceneType}/${sceneId}`}
                      >
                        <span className="sidebar-icon">
                          <TeamTwoToneIcon />
                        </span>
                        Team
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    {sceneType === "d" ? (
                      <li>
                        <NavLink
                          activeClassName="active"
                          isActive={() => pathRoute === "dashboard"}
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/dashboard/${sceneType}/${sceneId}`}
                        >
                          <span className="sidebar-icon">
                            <HomeTwoTone />
                          </span>
                          {"Dashboard"}
                        </NavLink>
                      </li>
                    ) : null}
                    {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/monthly-analytics/${sceneType}/${sceneId}`}
                          isActive={() =>
                            [
                              "analytics",
                              "activity-analytics",
                              "guardians-analytics",
                              "evaluation-analytics",
                              "weekly-scorecard-analytics",
                              "monthly-analytics",
                              "generate-report",
                              "parent-responses",
                            ].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <EqualizerTwoToneIcon />
                          </span>
                          View Statistics
                        </NavLink>
                      </li>
                    )}
                    {/* {sceneType === "d" ? (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/district-guardians/${sceneType}/${sceneId}`}
                          isActive={() =>
                            ["district-guardians"].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <GuardiansIcon />
                          </span>
                          Guardians
                        </NavLink>
                      </li>
                    ) : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/guardians/${sceneType}/${sceneId}`}
                          isActive={() =>
                            ["guardians", "guardians-leaderboard"].includes(
                              pathRoute
                            )
                          }
                        >
                          <span className="sidebar-icon">
                            <GuardiansIcon />
                          </span>
                          Guardians
                        </NavLink>
                      </li>
                    )} */}
                    {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/activities/${sceneType}/${sceneId}`}
                          isActive={() =>
                            [
                              "activities",
                              "add-activity",
                              "edit-activity",
                              "weekly-scorecard",
                              "parent-evaluations",
                              "edit-translation",
                              "resources-file-hosting",
                            ].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <AssignmentOutlinedIcon />
                          </span>
                          {simpleInterface
                            ? "App Content"
                            : "Create App Content"}
                        </NavLink>
                      </li>
                    )}
                    {/* {sceneType === "d" || schoolPlan === "Basic" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={toggleSidebarMobile}
                          className="nav-link-simple"
                          to={`/video-shorts/${sceneType}/${sceneId}`}
                          isActive={() => ["video-shorts"].includes(pathRoute)}
                        >
                          <span className="sidebar-icon">
                            <PlayCircleFilledOutlinedIcon />
                          </span>
                          Video Shorts
                        </NavLink>
                      </li>
                    )} */}
                    {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/guardians-leaderboard/${sceneType}/${sceneId}`}
                          isActive={() =>
                            ["guardians-leaderboard"].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <EmojiEventsTwoToneIcon />
                          </span>
                          Leaderboard
                        </NavLink>
                      </li>
                    )}
                    {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/resources-in-person/${sceneType}/${sceneId}`}
                          isActive={() =>
                            [
                              "resources-in-person",
                              "resources-in-person-general",
                            ].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <SentimentSatisfiedTwoToneIcon />
                          </span>
                          Host a Parent Party
                        </NavLink>
                      </li>
                    )}
                    {sceneType === "d" || simpleInterface ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/push-notification/${sceneType}/${sceneId}`}
                          isActive={() =>
                            ["push-notification"].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <PhoneIcon />
                          </span>
                          Send Push Notification
                        </NavLink>
                      </li>
                    )}
                    {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/resources/${sceneType}/${sceneId}`}
                          isActive={() =>
                            ["resources", "team", "more-info"].includes(
                              pathRoute
                            )
                          }
                        >
                          <span className="sidebar-icon">
                            <SchoolIcon />
                          </span>
                          Get Started
                        </NavLink>
                      </li>
                    )}
                    {/* {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/resources-in-person/${sceneType}/${sceneId}`}
                          isActive={() =>
                            [
                              "resources-in-person",
                              "resources-in-person-general",
                            ].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <SchoolIcon />
                          </span>
                          Parent Parties
                        </NavLink>
                      </li>
                    )} */}
                    {/* {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/push-notification/${sceneType}/${sceneId}`}
                          isActive={() =>
                            [
                              "push-notification",
                              "more-info",
                              "prize-plan",
                              "resources-file-hosting",
                            ].includes(pathRoute)
                          }
                        >
                          <span className="sidebar-icon">
                            <PhoneIcon />
                          </span>
                          Mobile App
                        </NavLink>
                      </li>
                    )} */}
                    {/* {sceneType === "d" ? null : (
                      <li>
                        <NavLink
                          activeClassName="active"
                          onClick={() => {
                            toggleSidebarMobile();
                          }}
                          className="nav-link-simple"
                          to={`/resources/${sceneType}/${sceneId}`}
                          isActive={() => ["resources"].includes(pathRoute)}
                        >
                          <span className="sidebar-icon">
                            <PermMediaOutlinedIcon />
                          </span>
                          Onboarding
                        </NavLink>
                      </li>
                    )} */}
                    {/* {props.scene ? (
                      props.scene.data ? (
                        props.scene.data.defaultDistrict ? null : (
                          <li>
                            <NavLink
                              activeClassName="active"
                              isActive={() =>
                                ["team", "edit-team"].includes(pathRoute)
                              }
                              onClick={toggleSidebarMobile}
                              className="nav-link-simple"
                              to={`/team/${sceneType}/${sceneId}`}
                            >
                              <span className="sidebar-icon">
                                <TeamTwoToneIcon />
                              </span>
                              {props.scene.type === "district"
                                ? "Team"
                                : "Team"}
                            </NavLink>
                          </li>
                        )
                      ) : null
                    ) : null} */}
                  </>
                )
              ) : null
            ) : null}
            {props.user ? (
              props.user.adminSupport ? (
                <>
                  <div className="sidebar-header">
                    <span>Second Mile</span>
                  </div>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-schools"
                      isActive={() =>
                        ["admin-schools", "add-school", "edit-school"].includes(
                          pathRoute
                        )
                      }
                    >
                      <span className="sidebar-icon">
                        <SchoolOutlinedIcon />
                      </span>
                      Schools
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-districts"
                      isActive={() =>
                        [
                          "admin-districts",
                          "add-district",
                          "edit-district",
                        ].includes(pathRoute)
                      }
                    >
                      <span className="sidebar-icon">
                        <LocationCityOutlinedIcon />
                      </span>
                      Districts
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-dashboard"
                      isActive={() => ["admin-dashboard"].includes(pathRoute)}
                    >
                      <span className="sidebar-icon">
                        <HomeTwoTone />
                      </span>
                      Admin Dashboard
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to={`/admin-guardians-analytics`}
                      isActive={() =>
                        [
                          "admin-analytics",
                          "admin-activity-analytics",
                          "admin-guardians-analytics",
                          "admin-evaluation-analytics",
                          "admin-weekly-scorecard-analytics",
                        ].includes(pathRoute)
                      }
                    >
                      <span className="sidebar-icon">
                        <EqualizerTwoToneIcon />
                      </span>
                      Admin Reporting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-guardians"
                      isActive={() => ["admin-guardians"].includes(pathRoute)}
                    >
                      <span className="sidebar-icon">
                        <GuardiansIcon />
                      </span>
                      Admin Guardians
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-activities"
                      isActive={() =>
                        [
                          "admin-activities",
                          "admin-add-activity",
                          "admin-edit-activity",
                          "admin-weekly-scorecard",
                          "admin-parent-evaluations",
                          "admin-edit-translation",
                          "admin-resources-uploads",
                          "admin-archived-activities",
                          "admin-edit-curriculum",
                        ].includes(pathRoute)
                      }
                    >
                      <span className="sidebar-icon">
                        <AssignmentOutlinedIcon />
                      </span>
                      Admin Activities
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-video-shorts"
                      isActive={() =>
                        [
                          "admin-video-shorts",
                          "admin-add-video-short",
                          "admin-edit-video-short",
                          "admin-archived-activities",
                        ].includes(pathRoute)
                      }
                    >
                      <span className="sidebar-icon">
                        <PlayCircleFilledOutlinedIcon />
                      </span>
                      Admin Video Shorts
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-resources"
                      isActive={() =>
                        ["admin-resources", "admin-resources-general"].includes(
                          pathRoute
                        )
                      }
                    >
                      <span className="sidebar-icon">
                        <SchoolTwoTone />
                      </span>
                      Admin Parent Parties
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-resources-marketing"
                      isActive={() =>
                        [
                          "admin-resources-marketing",
                          "admin-resources-file-hosting",
                          "admin-resources-onboarding-video",
                        ].includes(pathRoute)
                      }
                    >
                      <span className="sidebar-icon">
                        <PermMediaOutlinedIcon />
                      </span>
                      Admin Onboarding
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={() => {
                        toggleSidebarMobile();
                        props.readSceneAction();
                      }}
                      className="nav-link-simple"
                      to="/admin-email"
                      isActive={() => ["admin-email"].includes(pathRoute)}
                    >
                      <span className="sidebar-icon">
                        <PermMediaOutlinedIcon />
                      </span>
                      Admin Email
                    </NavLink>
                  </li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  user: userSelector(state),
  premium: premiumSelector(state),
  scene: sceneSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  readSceneAction: () => dispatch(readSceneAction()),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
