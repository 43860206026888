import React, { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import update from "immutability-helper";
import {
  appSelector,
  GoogleServices,
  School,
  updateSchoolAction,
  sceneSelector,
  premiumSelector,
  SchoolServices,
} from "../../core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Prompt } from "react-router";
import MaskedInput from "react-text-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  InputAdornment,
  Card,
  Button,
  TextField,
  FormControl,
  InputLabel,
  List,
  FormHelperText,
  Typography,
  ListItem,
  Snackbar,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  Table,
  Switch,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import Alert from "@material-ui/lab/Alert";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import CheckIcon from "@material-ui/icons/Check";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { CircleLoader } from "react-spinners";

/// Handle Google Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

// Responsive //
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { track } from "../../utils/Analytics";

const SchoolFileButton = (props) => {
  const { toggleDialogFileName } = props;

  const { open, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )[0];
      const resourceFile = {
        file: file,
        name: file.name,
        fileType: file.type,
        hosting: true,
        tempId: new Date().valueOf(),
      };
      toggleDialogFileName(resourceFile);
    },
  });

  return (
    <div>
      <input {...getInputProps()} style={{ display: "none" }} />
      <Button
        variant="contained"
        size="small"
        className="btn-success shadow-none mt-2 py-2"
        onClick={open}
      >
        <span className="btn-wrapper--icon mr-1">
          <FontAwesomeIcon
            style={{ fontSize: "12px" }}
            icon={["fas", "plus"]}
          />
        </span>
        <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
          Add File
        </span>
      </Button>
    </div>
  );
};

function TextMaskZipcode(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      // showMask
      // guide={true}
    />
  );
}

function getSteps() {
  return ["School Details", "Additional Info", "Review"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <Step1 {...props} />;
    case 1:
      return <Step2 {...props} />;
    case 2:
      return <Step3 {...props} />;
    default:
      return <Step1 {...props} />;
  }
}

const Step1 = (props) => {
  const { setUnsavedChanges } = props;
  const steps = getSteps();
  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          Edit School Information
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={0}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <p
          className="text-black my-4 px-4 pt-2"
          style={{ fontSize: "14px", fontWeight: 300 }}
        >
          Please type in a school name and we will pull in the address details.
        </p>
        <Grid container spacing={4} className="mt-4 pt-2 px-4">
          <Grid item md={12}>
            <FormControl
              fullWidth
              error={props.formErrors.name}
              variant="outlined"
            >
              <Autocomplete
                id="google-map-demo"
                getOptionLabel={(option) =>
                  typeof option === "string"
                    ? option
                    : option.structured_formatting.main_text
                }
                filterOptions={(x) => x}
                options={props.placeOptions}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={props.name}
                freeSolo={true}
                onChange={(event, newValue) => {
                  if (newValue) {
                    props.setPlaceOptions(
                      newValue
                        ? [newValue, ...props.placeOptions]
                        : props.placeOptions
                    );
                    props.checkPlaceId(newValue);
                    props.setName(newValue.structured_formatting.main_text);
                    setUnsavedChanges(true);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  props.setNameInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={props.formErrors.name}
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="School name"
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderOption={(option) => {
                  if (option) {
                    if (option.structured_formatting) {
                      const matches = option.structured_formatting
                        .main_text_matched_substrings
                        ? option.structured_formatting
                            .main_text_matched_substrings
                        : null;
                      const parts = matches
                        ? parse(
                            option.structured_formatting.main_text,
                            matches.map((match) => [
                              match.offset,
                              match.offset + match.length,
                            ])
                          )
                        : [
                            {
                              text: option.structured_formatting.main_text,
                              highlight: true,
                            },
                          ];

                      return (
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon className={props.classes.icon} />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }
                  }
                  return null;
                }}
              />
              <FormHelperText
                component={"div"}
                error={
                  (props.name &&
                    !props.placeIdValid &&
                    !props.placeValidationLoading) ||
                  props.formErrors.name
                }
              >
                {props.name &&
                !props.placeValidationLoading &&
                props.loadedSchool &&
                !props.formErrors.name ? (
                  props.placeIdValid ? (
                    <span className="text-success">
                      Loaded school info from Google.
                    </span>
                  ) : (
                    <p>
                      This school has been added already!{" "}
                      <span>Please contact support for assistance.</span>
                    </p>
                  )
                ) : props.placeValidationLoading ? (
                  <span className="d-flex align-items-center">
                    <CircleLoader size={10} color="#18a9a9" />
                    <span style={{ marginLeft: "10px" }}>
                      Loading school information.
                    </span>
                  </span>
                ) : props.formErrors.name ? (
                  <p>School name is required.</p>
                ) : (
                  <p></p>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl
              fullWidth
              error={props.formErrors.address}
              variant="outlined"
            >
              <TextField
                variant="outlined"
                fullWidth
                value={props.address}
                error={props.formErrors.address}
                label="School address"
                type="text"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: props.placeValidationLoading ? (
                    <InputAdornment position="start">
                      <CircleLoader size={15} color="#18a9a9" />
                    </InputAdornment>
                  ) : null,
                  onChange: (e) => {
                    setUnsavedChanges(true);
                    props.setAddress(e.target.value);
                    props.setFormErrors({
                      ...props.formErrors,
                      address: false,
                    });
                  },
                }}
              />
              <FormHelperText>
                {props.formErrors.address ? "Ex: 123 Happy Street NW" : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl
              fullWidth
              error={props.formErrors.city}
              variant="outlined"
            >
              <TextField
                variant="outlined"
                fullWidth
                value={props.city}
                error={props.formErrors.city}
                label="City"
                type="text"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: props.placeValidationLoading ? (
                    <InputAdornment position="start">
                      <CircleLoader size={15} color="#18a9a9" />
                    </InputAdornment>
                  ) : null,
                  onChange: (e) => {
                    setUnsavedChanges(true);
                    props.setCity(e.target.value);
                    props.setFormErrors({ ...props.formErrors, city: false });
                  },
                }}
              />
              <FormHelperText>
                {props.formErrors.city ? "City is required" : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl
              fullWidth
              error={props.formErrors.state}
              variant="outlined"
              disabled={
                props.loadedSchool
                  ? props.loadedSchool.location.country !== "United States"
                    ? true
                    : false
                  : props.schoolData
                  ? props.schoolData.location.state === "N/A"
                    ? true
                    : false
                  : false
              }
            >
              <TextField
                variant="outlined"
                fullWidth
                disabled={
                  props.loadedSchool
                    ? props.loadedSchool.location.country !== "United States"
                      ? true
                      : false
                    : props.schoolData
                    ? props.schoolData.location.state === "N/A"
                      ? true
                      : false
                    : false
                }
                value={props.state}
                error={props.formErrors.state}
                label="State"
                type="text"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: props.placeValidationLoading ? (
                    <InputAdornment position="start">
                      <CircleLoader size={15} color="#18a9a9" />
                    </InputAdornment>
                  ) : null,
                  onChange: (e) => {
                    setUnsavedChanges(true);
                    props.setState(e.target.value);
                    props.setFormErrors({
                      ...props.formErrors,
                      state: false,
                    });
                  },
                }}
              />
              <FormHelperText>
                {props.formErrors.state
                  ? "Ex. NY or N/A for international"
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl
              fullWidth
              error={props.formErrors.zipcode}
              variant="outlined"
            >
              <TextField
                variant="outlined"
                fullWidth
                value={props.zipcode}
                error={props.formErrors.zipcode}
                label="Zipcode"
                type="text"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: TextMaskZipcode,
                  startAdornment: props.placeValidationLoading ? (
                    <InputAdornment position="start">
                      <CircleLoader size={15} color="#18a9a9" />
                    </InputAdornment>
                  ) : null,
                  onChange: (e) => {
                    setUnsavedChanges(true);
                    props.setZipcode(e.target.value);
                    props.setFormErrors({
                      ...props.formErrors,
                      zipcode: false,
                    });
                  },
                }}
              />
              <FormHelperText>
                {props.formErrors.zipcode
                  ? "Zipcode must be exactly 5 digits. Ex. 35805"
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
const Step2 = (props) => {
  const steps = getSteps();
  const { setUnsavedChanges } = props;
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple: false,
    onDrop: (acceptedFiles) => {
      const MAX_SIZE = 31457280;
      const bigFile = (file) => file.size > MAX_SIZE;
      if (acceptedFiles.some(bigFile) === true) {
        alert("File Size Limit: 32 MB");
        return;
      }
      props.setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setUnsavedChanges(true);
      props.setFileAdded(true);
      props.setFormErrors({ ...props.formErrors, file: false });
    },
  });

  const filesList = props.files.length ? (
    props.files.map((file) => {
      return (
        <div className="py-2 d-flex flex-column align-items-start">
          <Card className="card card-box">
            <div className="card-img-wrapper rounded">
              <img
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={file.preview}
                className="card-img-top rounded"
                alt="..."
              />
            </div>
          </Card>
          <Button
            style={{ width: "200px" }}
            variant="contained"
            className="btn-pill mt-3 btn-danger"
            onClick={props.removeDealImage}
          >
            Remove
          </Button>
        </div>
      );
    })
  ) : props.loadedSchool ? (
    props.loadedSchool.properties ? (
      props.loadedSchool.properties.schoolLogo ? (
        <div className="py-2 d-flex flex-column align-items-start">
          <Card className="card card-box">
            <div className="card-img-wrapper rounded">
              <img
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={props.loadedSchool.properties.schoolLogo}
                className="card-img-top rounded"
                alt="..."
              />
            </div>
          </Card>
          <Button
            style={{ width: "200px" }}
            variant="contained"
            className="btn-pill mt-3 btn-danger"
            onClick={() => {
              props.setFileAdded(true);
            }}
          >
            Remove
          </Button>
        </div>
      ) : null
    ) : null
  ) : props.schoolData ? (
    props.schoolData.properties ? (
      props.schoolData.properties.schoolLogo ? (
        <div className="py-2 d-flex flex-column align-items-start">
          <Card className="card card-box">
            <div className="card-img-wrapper rounded">
              <img
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={props.schoolData.properties.schoolLogo}
                className="card-img-top rounded"
                alt="..."
              />
            </div>
          </Card>
          <Button
            style={{ width: "200px" }}
            variant="contained"
            className="btn-pill mt-3 btn-danger"
            onClick={() => {
              props.setFileAdded(true);
            }}
          >
            Remove
          </Button>
        </div>
      ) : null
    ) : null
  ) : null;

  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          Edit School Information
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={1}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <p
          className="text-black my-4 px-4"
          style={{ fontSize: "14px", fontWeight: 300 }}
        >
          Select an activity schedule and grade levels for the school.
          Optionally add a school logo to display in mobile app
        </p>
        <Grid container spacing={6} className="mt-4 px-4">
          <Grid item md={8}>
            <InputLabel shrink={true} className="mb-2" required>
              Activity schedule
            </InputLabel>
            <FormControl
              fullWidth
              variant="outlined"
              error={props.formErrors.schedule}
            >
              <Autocomplete
                options={["August", "September"]}
                value={props.schedule}
                onChange={(event, newValue) => {
                  setUnsavedChanges(true);
                  props.setSchedule(newValue);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                disableClearable={true}
              />
            </FormControl>
          </Grid>
          <Grid item md={8}>
            <InputLabel shrink={true} className="mb-2" required>
              Grade levels
            </InputLabel>
            <FormControl
              fullWidth
              variant="outlined"
              error={props.formErrors.gradeLevels}
            >
              <Autocomplete
                multiple
                id="tags-standard"
                options={[
                  "Select all",
                  "Preschool",
                  "Kindergarten",
                  "1st grade",
                  "2nd grade",
                  "3rd grade",
                  "4th grade",
                  "5th grade",
                  "6th grade",
                  "7th grade",
                  "8th grade",
                  "9th grade",
                  "10th grade",
                  "11th grade",
                  "12th grade",
                ]}
                value={props.gradeLevels}
                onChange={(event, newValue) => {
                  setUnsavedChanges(true);

                  if (newValue.includes("Select all")) {
                    /* Choose select all latest */
                    if (newValue[newValue.length - 1] === "Select all") {
                      props.setGradeLevels([
                        "Preschool",
                        "Kindergarten",
                        "1st grade",
                        "2nd grade",
                        "3rd grade",
                        "4th grade",
                        "5th grade",
                        "6th grade",
                        "7th grade",
                        "8th grade",
                        "9th grade",
                        "10th grade",
                        "11th grade",
                        "12th grade",
                      ]);
                      return;
                    } else {
                      /* Remove select all  */
                      props.setGradeLevels(
                        newValue.filter((i) => i !== "Select all")
                      );
                      return;
                    }
                  }

                  props.setGradeLevels(newValue);
                }}
                getOptionLabel={(option) => option}
                renderValue={(selected) => selected.join(", ")}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              <FormHelperText>
                {props.formErrors.gradeLevels
                  ? "Atleast one grade level is required"
                  : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={8}>
            <InputLabel shrink={true}>School logo (optional)</InputLabel>
            {!props.files.length && props.fileAdded ? (
              <div className="dropzone">
                <div
                  {...getRootProps({
                    className: "dropzone-upload-wrapper",
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="dropzone-inner-wrapper">
                    {isDragAccept && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                          <CheckIcon className="d-50" />
                        </div>
                        <div className="font-size-sm text-success">
                          All files will be uploaded!
                        </div>
                      </div>
                    )}
                    {isDragReject && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                          <CloseTwoToneIcon className="d-50" />
                        </div>
                        <div className="font-size-sm text-danger">
                          Some files will be rejected!
                        </div>
                      </div>
                    )}
                    {!isDragActive && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                          <CloudUploadTwoToneIcon className="d-50" />
                        </div>
                        <div className="font-size-sm">
                          Drag and drop logo here{" "}
                          <span className="font-size-xs text-dark">
                            (JPG, PNG)
                          </span>
                        </div>
                      </div>
                    )}

                    <small className="py-2 text-black-50">or</small>
                    <div>
                      <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                        <span className="px-2">Browse Files</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div>
              {props.files.length > 0 && props.fileAdded ? (
                <div className="mt-4">
                  <Alert
                    severity="success"
                    className="text-center mb-3"
                    style={{ width: "450px" }}
                  >
                    You have added a school logo.
                  </Alert>
                  <List component="div" className="font-size-sm">
                    {filesList}
                  </List>
                </div>
              ) : props.fileAdded ? null : props.schoolData ? (
                props.schoolData.properties ? (
                  props.schoolData.properties.schoolLogo ? (
                    <div className="mt-4">
                      {props.mobile ? null : (
                        <Alert
                          severity="info"
                          className="text-center mb-3"
                          style={{ width: "450px" }}
                        >
                          You have uploaded a school logo.
                        </Alert>
                      )}
                      <List component="div" className="font-size-sm">
                        {filesList}
                      </List>
                    </div>
                  ) : null
                ) : null
              ) : props.loadedSchool ? (
                props.loadedSchool.properties ? (
                  props.loadedSchool.properties.schoolLogo ? (
                    <div className="mt-4">
                      <Alert
                        severity="info"
                        className="text-center mb-3"
                        style={{ width: "450px" }}
                      >
                        You have uploaded a school logo.
                      </Alert>
                      <List component="div" className="font-size-sm">
                        {filesList}
                      </List>
                    </div>
                  ) : null
                ) : null
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const Step3 = (props) => {
  const steps = getSteps();
  return (
    <>
      <div className={"py-4"}>
        <h5 className="font-size-xl mb-2 font-weight-bold px-4">
          Edit School Information
        </h5>
        <div className="my-4">
          <Stepper
            className="stepper-horizontal-1"
            style={{ background: "rgba(69, 170, 170, 0.07)" }}
            activeStep={2}
            connector={
              <span className="mx-3">
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  color={"rgba(0, 0, 0, 0.51)"}
                />
              </span>
            }
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <p
          className="text-black my-4 px-4 pt-2"
          style={{ fontSize: "14px", fontWeight: 300 }}
        >
          Please review the school information below then press update school to
          finish.
        </p>
        <Grid container spacing={4} className="mt-4 pt-2 px-4">
          <Grid item md={12}>
            <InputLabel shrink={true}>School name</InputLabel>
            <div className="text-black">{props.name}</div>
          </Grid>
          <Grid item md={12}>
            <InputLabel shrink={true}>School address</InputLabel>
            <div className="text-black">{props.address}</div>
          </Grid>
          <Grid item md={4}>
            <InputLabel shrink={true}>City</InputLabel>
            <div className="text-black">{props.city}</div>
          </Grid>
          <Grid item md={4}>
            <InputLabel shrink={true}>State</InputLabel>
            <div className="text-black">{props.state}</div>
          </Grid>
          <Grid item md={4}>
            <InputLabel shrink={true}>Zipcode</InputLabel>
            <div className="text-black">{props.zipcode}</div>
          </Grid>
          <Grid item md={12}>
            <InputLabel shrink={true}>Schedule</InputLabel>
            <div className="text-black">{props.schedule}</div>
          </Grid>
          <Grid item md={12}>
            <InputLabel shrink={true}>Grade levels</InputLabel>
            <div className="text-black">
              {props.gradeLevels.length > 5
                ? "Multiple grade levels"
                : props.gradeLevels.join(", ")}
            </div>
          </Grid>
          <Grid item md={12}>
            <InputLabel shrink={true}>School logo (optional)</InputLabel>
            {props.files.length ? (
              <img
                src={props.files[0].preview}
                className="card-img-top rounded mt-1"
                style={{
                  border: "1px solid rgba(0,0,0,.1)",
                  width: "120px",
                  height: "120px",
                  objectFit: "cover",
                }}
                alt="..."
              />
            ) : props.schoolData ? (
              props.schoolData.properties ? (
                props.schoolData.properties.schoolLogo ? (
                  <img
                    src={props.schoolData.properties.schoolLogo}
                    className="card-img-top rounded mt-1"
                    style={{
                      border: "1px solid rgba(0,0,0,.1)",
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                ) : (
                  <div className="text-black">No logo</div>
                )
              ) : (
                <div className="text-black">No logo</div>
              )
            ) : (
              <div className="text-black">No logo</div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function EditSchool(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Create and Update handle //
  const [schoolLoading, setSchoolLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSchoolCleaning = () => {
    try {
      /* Create */
      let school;
      if (loadedSchool) {
        school = new School({
          ...loadedSchool,
          ...props.location.schoolData,
          name: nameInputValue !== name ? nameInputValue : name,
          location: {
            ...loadedSchool.location,
            address,
            state,
            city,
            zipcode,
          },
          schedule,
          gradeLevels,
          properties: {
            ...props.location.schoolData.properties,
            placeId: loadedSchool.properties.placeId,
            telephone: loadedSchool.properties.telephone,
            mapsUrl: loadedSchool.properties.mapsUrl,
            schoolLogo: fileAdded
              ? files[0]
              : props.location.schoolData.properties
              ? props.location.schoolData.properties.schoolLogo
                ? props.location.schoolData.properties.schoolLogo
                : null
              : null,
          },
        });
      } else {
        school = new School({
          ...props.location.schoolData,
          name: nameInputValue,
          location: {
            ...props.location.schoolData.location,
            address,
            state,
            city,
            zipcode,
          },
          schedule,
          gradeLevels,
          properties: {
            ...props.location.schoolData.properties,
            schoolLogo: fileAdded
              ? files[0]
              : props.location.schoolData.properties
              ? props.location.schoolData.properties.schoolLogo
                ? props.location.schoolData.properties.schoolLogo
                : null
              : null,
          },
        });
      }

      onEditSchool(school);
    } catch (err) {
      console.log(err);
    }
  };

  const onEditSchool = (school) => {
    if (schoolLoading) {
      return;
    }
    try {
      track("Edit_School");
      setError("");
      props.dispatchUpdateSchool(school);
    } catch (err) {
      setError(err.toString());
    }
  };

  useEffect(() => {
    if (schoolLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setError(`Could not edit school. Please contact support.`);
          setSchoolLoading(false);
        } else {
          setError("");
          setSchoolLoading(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, schoolLoading]);

  useEffect(() => {
    if (props.location.schoolData) {
      const school = props.location.schoolData;
      setAddress(school.location.address.trim());
      setName(school.name);
      setNameInputValue(school.name);
      setCity(school.location.city);
      if (school.location.country) {
        if (school.location.country !== "United States") {
          setState("N/A");
        } else {
          setState(school.location.state);
        }
      } else {
        setState(school.location.state);
      }
      setZipcode(school.location.zipcode);
      if (school.properties) {
        if (school.properties.schoolLogo) {
          setFileAdded(false);
        }
      }
      setSchedule(school.schedule);
      setGradeLevels(school.gradeLevels);

      // Plan page
      setPlan(school.plan || "None");
      setPlanStudents(school.planStudents || 0);

      // Curriculums
      setSchoolCurriculum(school.curriculum || "main");

      if (school.simpleInterface) {
        setSimpleInterface(school.simpleInterface);
      }

      async function handleCurr() {
        const res1 = await SchoolServices.readCurriculums();
        setCurriculums(res1);
        setCurrLoader(false);
      }
      handleCurr();

      // Billing
      setBillingNotes(school.billingNotes || "");
      setBillingFiles(school.billingFiles || []);
    }
  }, [props.location.schoolData]);

  const [formErrors, setFormErrors] = useState({
    name: false,
    address: false,
    city: false,
    state: false,
    zipcode: false,
    file: false,
    phone: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeStep]);

  const handleNext = () => {
    /// Check Form Per Step ///
    let errors = {
      name: false,
      address: false,
      city: false,
      state: false,
      zipcode: false,
      phone: false,
      schedule: false,
      gradeLevels: false,
    };
    if (activeStep === 0) {
      if (!name && !nameInputValue) {
        errors.name = true;
      }
      if (loadedSchool && !placeIdValid) {
        errors.name = true;
      }
      if (!address) {
        errors.address = true;
      }
      if (!city) {
        errors.city = true;
      }
      if (
        loadedSchool
          ? loadedSchool.location.country === "United States"
            ? state !== state.toUpperCase() || state.length !== 2
            : state.length < 1
          : state.length < 1
      ) {
        errors.state = true;
      }
      if (
        loadedSchool
          ? loadedSchool.location.country === "United States"
            ? zipcode.toString().trim().length !== 5
            : zipcode.toString().trim().length < 1
          : zipcode.toString().trim().length < 1
      ) {
        errors.zipcode = true;
      }
      if (errors.name || errors.address) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      if (
        errors.name ||
        errors.address ||
        errors.city ||
        errors.state ||
        errors.zipcode
      ) {
        return setFormErrors(errors);
      }
    }
    if (activeStep === 1) {
      if (!schedule) {
        errors.schedule = true;
      }
      if (!gradeLevels.length) {
        errors.gradeLevels = true;
      }
      if (errors.schedule || errors.gradeLevels) {
        return setFormErrors(errors);
      }
    }
    // End Check

    // Last Step run functions //
    if (activeStep === steps.length - 1) {
      // Run Loader
      setSchoolLoading("create");
      setUnsavedChanges(false);
      handleSchoolCleaning();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  ///// Handle Google
  const classes = useStyles();
  const [nameInputValue, setNameInputValue] = useState("");
  const [name, setName] = useState("");
  const [placeValue, setPlaceValue] = React.useState(null);
  const [placeOptions, setPlaceOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAP7WssZiXyS0IL929NxrEd2bvpuXfRRAg&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (nameInputValue === "") {
      setPlaceOptions(placeValue ? [placeValue] : []);
      return undefined;
    }

    fetch(
      {
        input: nameInputValue,
        componentRestrictions: {
          // country: "us",
        },
        types: ["establishment"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (placeValue) {
            newOptions = [placeValue];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setPlaceOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [placeValue, nameInputValue, fetch, name]);

  const getAddress = (address_components) => {
    let typeMap = {
      streetNumber: ["street_number"],
      route: ["route"],
      subpremise: ["subpremise"],
      city: ["locality"],
      state: ["administrative_area_level_1"],
      zipcode: ["postal_code"],
      country: ["country"],
    };

    let address = {
      streetNumber: "",
      route: "",
      subpremise: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    };
    address_components.forEach((component) => {
      for (let type in typeMap) {
        if (typeMap[type].indexOf(component.types[0]) !== -1) {
          if (type === "state") {
            address[type] = component.short_name;
          } else {
            address[type] = component.long_name;
          }
        }
      }
    });
    return {
      street: `${address.streetNumber} ${address.route} ${address.subpremise}`,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
    };
  };

  const checkPlaceId = async (newValue) => {
    if (!newValue) {
      return;
    }
    if (!newValue.place_id || placeValidationLoading) {
      return;
    }

    setPlaceValidationLoading(true);
    const details = await GoogleServices.placeDetails(newValue.place_id);
    const {
      place_id,
      name,
      url,
      geometry: {
        location: { lat, lng },
      },
      formatted_phone_number,
      alreadyAdded,
    } = details;

    let { street, city, state, zipcode, country } = getAddress(
      details.address_components
    );
    if (!street || /^\s*$/.test(street)) {
      street = details.formatted_address.split(",")[0];
    }

    const school = {
      name,
      location: {
        address: street.trim(),
        city,
        state,
        zipcode,
        coordinates: { latitude: lat, longitude: lng },
        country,
      },
      properties: {
        placeId: place_id,
        telephone: formatted_phone_number,
        mapsUrl: url,
      },
    };
    setPlaceIdValid(!alreadyAdded);
    setValidReason(alreadyAdded ? "alreadyAdded" : null);
    setLoadedSchool(school);
    setPlaceValue(school.location.address);
    setAddress(school.location.address.trim());
    setName(school.name);
    setNameInputValue(school.name);
    setCity(school.location.city);
    if (school.location.country !== "United States") {
      setState("N/A");
    } else {
      setState(school.location.state);
    }
    setZipcode(school.location.zipcode);
    setFormErrors({
      ...formErrors,
      name: false,
      city: false,
      address: false,
      state: false,
      zipcode: false,
      phone: false,
    });
    setPlaceValidationLoading(false);
    if (school.properties.schoolLogo) {
      setFileAdded(false);
    }
    setUnsavedChanges(true);
  };
  // End Handle Google //

  /// Step1
  const [placeIdValid, setPlaceIdValid] = useState(false);
  const [validReason, setValidReason] = useState(null);
  const [placeValidationLoading, setPlaceValidationLoading] = useState(false);
  const [loadedSchool, setLoadedSchool] = useState(null);
  const [address, setAddress] = React.useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  /// Step2
  const [schedule, setSchedule] = useState("August");
  const [gradeLevels, setGradeLevels] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileAdded, setFileAdded] = useState(true);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  // Plan page
  const [tabView, setTabView] = useState(0);
  const [plan, setPlan] = useState("None");
  const [planStudents, setPlanStudents] = useState(0);
  const [schoolPlanLoading, setSchoolPlanLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  // Billing
  const [schoolBillingLoading, setSchoolBillingLoading] = useState(false);
  const [billingNotes, setBillingNotes] = useState("");
  const [billingFiles, setBillingFiles] = useState([]);
  const [dialogFileName, setDialogFileName] = useState(false);
  const [dialogInfoFileName, setDialogInfoFileName] = useState(null);
  const [fileNameDialog, setFileNameDialog] = useState("");
  const toggleDialogFileName = (file) => {
    if (file) {
      setDialogInfoFileName(file);
      setFileNameDialog(file.name);
    }
    setDialogFileName(!dialogFileName);
  };
  const handleFileName = async (fileInDialog) => {
    const updatedFile = {
      ...fileInDialog,
      name: fileNameDialog,
    };

    setBillingFiles(
      update(billingFiles, {
        $push: [updatedFile],
      })
    );

    setUnsavedChanges(true);
    setFormErrors({
      ...formErrors,
      schoolFiles: false,
    });
  };

  // Curriculums
  const [currLoader, setCurrLoader] = useState(true);
  const [simpleInterface, setSimpleInterface] = useState(false);
  const [schoolCurrLoading, setSchoolCurrLoading] = useState(false);
  const [schoolCurriculum, setSchoolCurriculum] = useState("");
  const [curriculums, setCurriculums] = useState([]);
  const handleUpdateCurriculum = () => {
    if (schoolCurriculum) {
      if (schoolCurriculum.id === "main") {
        setSchoolCurrLoading(true);
        setToastMessage(`Updating school curriculum...`);
        setSnackBarType("info");
        setToastOpen(true);
        let school = new School({
          ...props.location.schoolData,
          curriculum: null,
        });

        props.dispatchUpdateSchool(school);
        return;
      }
    }

    setSchoolPlanLoading(true);
    setToastMessage(`Updating school curriculum...`);
    setSnackBarType("info");
    setToastOpen(true);
    let school = new School({
      ...props.location.schoolData,
      curriculum: schoolCurriculum,
      simpleInterface,
    });

    props.dispatchUpdateSchool(school);
  };

  const handleActiveChange = (target) => {
    setSimpleInterface(target);
    setUnsavedChanges(true);
  };

  const handleUpdatePlan = () => {
    if (plan && planStudents !== "") {
      setSchoolPlanLoading(true);
      setToastMessage(`Updating school plan...`);
      setSnackBarType("info");
      setToastOpen(true);
      let school = new School({
        ...props.location.schoolData,
        plan,
        planStudents,
      });

      props.dispatchUpdateSchool(school);
    }
  };

  const handleUpdateBilling = () => {
    setSchoolBillingLoading(true);
    setToastMessage(`Updating school billing...`);
    setSnackBarType("info");
    setToastOpen(true);
    let school = new School({
      ...props.location.schoolData,
      billingNotes,
      billingFiles,
    });

    props.dispatchUpdateSchool(school);
  };

  useEffect(() => {
    if (schoolPlanLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setToastMessage(
            `Could not update school plan. Contact us at support@csmatrix.online.`
          );
          setSnackBarType("error");
          setToastOpen(true);
          setSchoolPlanLoading(false);
        } else {
          setToastMessage(`Updated school plan successfully.`);
          setSnackBarType("success");
          setToastOpen(true);
          setSchoolPlanLoading(false);
          setUnsavedChanges(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, schoolPlanLoading]);

  useEffect(() => {
    if (schoolBillingLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setToastMessage(
            `Could not update school billing. Contact us at support@csmatrix.online.`
          );
          setSnackBarType("error");
          setToastOpen(true);
          setSchoolBillingLoading(false);
        } else {
          setToastMessage(`Updated school billing successfully.`);
          setSnackBarType("success");
          setToastOpen(true);
          setSchoolBillingLoading(false);
          setUnsavedChanges(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, schoolBillingLoading]);

  useEffect(() => {
    if (schoolCurrLoading) {
      if (!props.app.isLoading) {
        if (props.app.error) {
          Bugsnag.notify(props.app.error);
          setToastMessage(
            `Could not update school curriculum. Contact us at support@csmatrix.online.`
          );
          setSnackBarType("error");
          setToastOpen(true);
          setSchoolPlanLoading(false);
        } else {
          setToastMessage(`Updated school curriculum successfully.`);
          setSnackBarType("success");
          setToastOpen(true);
          setSchoolPlanLoading(false);
          setUnsavedChanges(false);
        }
      }
    }
  }, [props.app.error, props.app.isLoading, schoolCurrLoading]);

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ height: "52px" }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              {"Schools"}
            </div>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          <List
            component="div"
            className="nav-line-alt nav-line d-flex align-items-center"
          >
            <ListItem
              button
              selected={tabView === 0}
              className="pr-2"
              onClick={() => setTabView(0)}
            >
              <span className="font-size-sm">{"School Information"}</span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              selected={tabView === 1}
              className="px-2"
              onClick={() => setTabView(1)}
            >
              <span className="font-size-sm">Curriculum / Settings</span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              selected={tabView === 2}
              className="px-2"
              onClick={() => setTabView(2)}
            >
              <span className="font-size-sm">Edit Plan</span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              selected={tabView === 3}
              className="px-2"
              onClick={() => setTabView(3)}
            >
              <span className="font-size-sm">Billing</span>
              <div className="divider" />
            </ListItem>
          </List>
        </Grid>
        {tabView === 1 ? (
          <Grid item md={12}>
            <Card
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <div className={"py-4 px-4"}>
                <h5 className="font-size-xl mb-2 font-weight-bold">
                  Edit Curriculum
                </h5>
                <p
                  className="text-black mb-4"
                  style={{ fontSize: "14px", fontWeight: 300 }}
                >
                  Determines which Parent Parties Engage activities are
                  avaiable.
                </p>
                <Grid container spacing={4}>
                  <Grid item md={8} xl={8}>
                    {currLoader ? (
                      <div>
                        <CircleLoader size={25} color="#18a9a9" />
                      </div>
                    ) : (
                      <>
                        <InputLabel shrink={true} className="mb-2" required>
                          Curriculum
                        </InputLabel>
                        <FormControl fullWidth={true} variant="outlined">
                          <Select
                            value={schoolCurriculum}
                            onChange={(e) => {
                              setSchoolCurriculum(e.target.value);
                            }}
                          >
                            {curriculums.map((curriculum) => (
                              <MenuItem value={curriculum.id}>
                                {curriculum.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <div className="pt-2">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 13,
                              paddingBottom: 13,
                            }}
                            className="mt-4"
                          >
                            <div
                              className="font-weight-bold"
                              style={{ color: "rgb(43, 39, 60)" }}
                            >
                              Use Simple Interface
                            </div>
                            <Switch
                              onChange={(e) => {
                                handleActiveChange(e.target.checked);
                              }}
                              checked={simpleInterface}
                              className="switch-small toggle-switch-success ml-3"
                            />
                          </div>
                          <div className="mt-1 mb-4">
                            <div className="text-black-50 font-size-sm">
                              Remove the ability to create custom activities and
                              advanced reporting.
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item md={8} xl={8}>
                    <Button
                      className="btn-pill btn-primary font-weight-bold px-4"
                      onClick={handleUpdateCurriculum}
                    >
                      {"Update"}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        ) : tabView === 2 ? (
          <Grid item md={12}>
            <Card
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <div className={"py-4 px-4"}>
                <h5 className="font-size-xl mb-2 font-weight-bold">
                  Edit Plan
                </h5>
                <p
                  className="text-black mb-4"
                  style={{ fontSize: "14px", fontWeight: 300 }}
                >
                  The plan setting will allow school users access to paid
                  features in the web application. Invoicing will be handled in
                  Stripe separately.
                </p>
                <Grid container spacing={4}>
                  <Grid item md={8} xl={8}>
                    <InputLabel shrink={true} className="mb-2" required>
                      Plan
                    </InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        options={[
                          "Demo - Premium",
                          "Basic",
                          "Premium",
                          "In-school Curriculum",
                          "None",
                        ]}
                        value={plan}
                        onChange={(event, newValue) => {
                          setUnsavedChanges(true);
                          setPlan(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        disableClearable={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={8} xl={8}>
                    <InputLabel shrink={true} className="mb-2" required>
                      Number of students
                    </InputLabel>
                    <FormControl
                      fullWidth
                      // error={props.formErrors.zipcode}
                      variant="outlined"
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={planStudents}
                        // error={props.formErrors.zipcode}
                        type="number"
                        onChange={(e) => {
                          setUnsavedChanges(true);
                          setPlanStudents(e.target.value);
                          // props.setFormErrors({
                          //   ...props.formErrors,
                          //   planStudents: false,
                          // });
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={8} xl={8}>
                    <Button
                      className="btn-pill btn-primary font-weight-bold px-4"
                      onClick={handleUpdatePlan}
                    >
                      {"Update Plan"}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        ) : tabView === 3 ? (
          <Grid item md={12}>
            <Card
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <div className={"py-4 px-4"}>
                <h5 className="font-size-xl mb-2 font-weight-bold">Billing</h5>
                <p
                  className="text-black mb-4"
                  style={{ fontSize: "14px", fontWeight: 300 }}
                >
                  Uploaded PDF/PNG invoices and update notes.
                </p>
                <Grid container spacing={4}>
                  <Grid item md={8} xl={8}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                    >
                      {"File uploads"}
                    </InputLabel>
                    <div className="py-2">
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-striped table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">File Name</th>
                              <th scope="col" style={{ width: "125px" }}>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {billingFiles.map((file) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {file.name}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <div className="d-flex flex-row align-items-center">
                                      <Button
                                        className="px-2 btn-transition-none border-0 btn-outline-primary mr-2"
                                        variant="outlined"
                                        component={"a"}
                                        target="_blank"
                                        href={file.preview}
                                      >
                                        <span className="btn-wrapper--icon">
                                          <FontAwesomeIcon
                                            icon={["fas", "eye"]}
                                            className="font-size-sm"
                                          />
                                        </span>
                                      </Button>
                                      <Button
                                        className="px-2 btn-transition-none border-0 btn-outline-danger"
                                        variant="outlined"
                                        onClick={() => {
                                          const fileIndex = billingFiles.findIndex(
                                            (schoolFile) =>
                                              file.id
                                                ? file.id === schoolFile.id
                                                : file.tempId ===
                                                  schoolFile.tempId
                                          );

                                          setUnsavedChanges(true);
                                          setBillingFiles(
                                            update(billingFiles, {
                                              $splice: [[fileIndex, 1]],
                                            })
                                          );
                                        }}
                                      >
                                        <span className="btn-wrapper--icon">
                                          <FontAwesomeIcon
                                            icon={["fas", "trash"]}
                                            className="font-size-sm"
                                          />
                                        </span>
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <SchoolFileButton
                        toggleDialogFileName={toggleDialogFileName}
                      />
                      <div className="mt-2">
                        <small className="text-black-50">
                          Accepts JPEG, PNG, and PDF.
                        </small>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={8} xl={8}>
                    <InputLabel shrink={true} className="mb-2">
                      Notes
                    </InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={billingNotes}
                        onChange={(e) => {
                          setUnsavedChanges(true);
                          setBillingNotes(e.target.value);
                        }}
                        multiline={true}
                        inputProps={{
                          style: { minHeight: "100px" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={8} xl={8}>
                    <Button
                      className="btn-pill btn-primary font-weight-bold px-4"
                      onClick={handleUpdateBilling}
                    >
                      {"Update Billing"}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        ) : (
          <Grid item md={12}>
            <Card
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              <div>
                {activeStep === steps.length ? (
                  <div className="text-center p-5">
                    {schoolLoading ? (
                      <>
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-50">
                            <CircleLoader
                              className="d-flex align-self-center"
                              color={"#18a9a9"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">{`Updating school.`}</h4>
                      </>
                    ) : (
                      <>
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                          <div
                            className={clsx(
                              "d-inline-flex",
                              "justify-content-center",
                              "p-0",
                              "rounded-circle",
                              "btn-icon",
                              "avatar-icon-wrapper",
                              "m-0",
                              "d-130",
                              error
                                ? "bg-neutral-danger"
                                : "bg-neutral-success",
                              error ? "text-danger" : "text-success"
                            )}
                          >
                            <FontAwesomeIcon
                              icon={["far", "lightbulb"]}
                              className="d-flex align-self-center display-3"
                            />
                          </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                          {error
                            ? error
                            : `${
                                name || nameInputValue
                              } has been ${"updated"}!`}
                        </h4>
                        <div className="pt-4">
                          {error ? (
                            <Button
                              onClick={handleReset}
                              className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                              size="large"
                            >
                              <span className="btn-wrapper--label">
                                Back to Form
                              </span>
                            </Button>
                          ) : (
                            <NavLink to={`/admin-schools`}>
                              <Button
                                className="btn-primary font-weight-bold rounded hover-scale-lg mx-1"
                                size="large"
                              >
                                <span className="btn-wrapper--label">
                                  Back to Schools
                                </span>
                              </Button>
                            </NavLink>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>
                      {getStepContent(activeStep, {
                        mobile,
                        theme,
                        setUnsavedChanges,
                        formErrors,
                        setFormErrors,
                        /// Step 1
                        name,
                        setName,
                        address,
                        placeValue,
                        placeOptions,
                        setPlaceValue,
                        setPlaceOptions,
                        checkPlaceId,
                        placeIdValid,
                        placeValidationLoading,
                        classes,
                        city,
                        setCity,
                        state,
                        setState,
                        zipcode,
                        setZipcode,
                        validReason,
                        setValidReason,
                        setAddress,
                        setNameInputValue,
                        /// Step 2
                        schedule,
                        setSchedule,
                        gradeLevels,
                        setGradeLevels,
                        files,
                        setFiles,
                        fileAdded,
                        setFileAdded,
                        schoolData: props.location.schoolData,
                        loadedSchool,
                      })}
                    </div>
                    <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                      <Button
                        variant="text"
                        className={
                          activeStep === 0
                            ? "btn-pill m-2"
                            : "btn-pill m-2 btn-outline-primary"
                        }
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        className="btn-pill btn-primary font-weight-bold"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={toastOpen}
        classes={{
          root:
            snackBarType === "error"
              ? `toastr-danger`
              : snackBarType === "info"
              ? `toastr-primary`
              : `toastr-success`,
        }}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        autoHideDuration={snackBarType === "success" ? 5000 : null}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToastOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={dialogFileName}
        onClose={() => toggleDialogFileName()}
        classes={{ paper: "modal-content rounded-lg pt-4" }}
      >
        <div className="px-3 pb-1 text-left font-size-md font-weight-bold">
          {`Add file name`}
        </div>
        <div className="divider my-2" />
        <div className="p-3">
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl fullWidth variant="outlined">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={fileNameDialog}
                    label="File Name"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      onChange: (e) => {
                        setFileNameDialog(e.target.value);
                      },
                    }}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-4">
            <Button
              onClick={() => toggleDialogFileName()}
              className="btn-neutral-dark btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              onClick={async () => {
                toggleDialogFileName();
                handleFileName(dialogInfoFileName);
              }}
              className={"btn-pill mx-1 btn-success"}
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  premium: premiumSelector(state),
});
const mapDispatchToProps = {
  dispatchUpdateSchool: updateSchoolAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSchool);
