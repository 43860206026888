import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { appSelector, userSelector, ActivityServices } from "../../core";
import {
  Grid,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  Card,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router-dom";
import PreviewActivity from "./components/previewActivity";
import { CircleLoader } from "react-spinners";
import AGGrid from "../Grid";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import update from "immutability-helper";

import AddActivityDialog from "./components/addActivityDialog";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ActivieThemeSection = (props) => {
  const {
    theme,
    activityPool,
    handleActivityType,
    handleUpdateCurriculumThemeName,
    handleAddActivityDialogOpen,
    setPreviewDialog,
    setPreviewDialogInfo,
  } = props;

  return (
    <Grid item md={12} xl={12}>
      <Card
        className="p-0"
        style={{
          borderRadius: "0.25rem",
          border: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <div className="inline-flex align-items-center">
                <div
                  className="heading-4 text-left text-black"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                  }}
                >
                  {theme.subtext}
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-info shadow-none ml-4"
              onClick={() => handleAddActivityDialogOpen(theme.id)}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "edit"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Edit Activities
              </span>
            </Button>
          </div>
          <div className="mt-3">
            <InputLabel shrink={true} required>
              Theme
            </InputLabel>
            <FormControl variant="outlined">
              <TextField
                variant="outlined"
                fullWidth
                value={theme.name}
                onChange={(e) => {
                  handleUpdateCurriculumThemeName(theme.id, e.target.value);
                }}
                size="small"
                margin="dense"
                inputProps={{
                  style: { width: "250px" },
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className="pt-1 px-3 pb-4">
          <AGGrid
            rowData={activityPool}
            search={false}
            autoHeight={true}
            columnDefs={[
              {
                headerName: "Activity Name",
                field: "name",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Activity Type",
                field: "type",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.type !== undefined) {
                    return handleActivityType(params.data.type);
                  } else {
                    return null;
                  }
                },
              },
              {
                headerName: "Points",
                field: "points",
                sortable: true,
                filter: true,
              },
              {
                headerName: "Status",
                field: "status",
                sortable: true,
                filter: true,
                valueGetter: function (params) {
                  if (params.data.status) {
                    if (params.data.status === "draft") {
                      return "Draft";
                    } else {
                      return "Live";
                    }
                  }
                  return "Live";
                },
              },
              {
                width: 380,
                suppressSizeToFit: true,
                field: "actions",
                headerName: "Actions",
                cellRendererFramework: (params) => (
                  <div className="d-flex flex-row align-items-center">
                    <button
                      className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                      tabIndex="0"
                      type="button"
                      onClick={() => {
                        setPreviewDialogInfo(params.data);
                        setPreviewDialog(true);
                      }}
                    >
                      <span className="MuiButton-label">
                        <span
                          className="btn-wrapper--label text-white"
                          style={{ fontSize: "12px" }}
                        >
                          Preview
                        </span>
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                ),
                cellClass: "agGridCellCenter",
              },
            ]}
          />
        </div>
      </Card>
    </Grid>
  );
};

function AdminEditCurriculum(props) {
  const themesDefault = [
    {
      id: 0,
      subtext: "August or September",
      name: "",
      activityIds: [],
    },
    {
      id: 1,
      subtext: "September or October",
      name: "",
      activityIds: [],
    },
    {
      id: 2,
      subtext: "October or November",
      name: "",
      activityIds: [],
    },
    {
      id: 3,
      subtext: "November or March",
      name: "",
      activityIds: [],
    },
    {
      id: 4,
      subtext: "December",
      name: "",
      activityIds: [],
    },
    {
      id: 5,
      subtext: "January",
      name: "",
      activityIds: [],
    },
    {
      id: 6,
      subtext: "February",
      name: "",
      activityIds: [],
    },
    {
      id: 7,
      subtext: "March or April",
      name: "",
      activityIds: [],
    },
    {
      id: 8,
      subtext: "April or May",
      name: "",
      activityIds: [],
    },
    {
      id: 9,
      subtext: "May or June",
      name: "",
      activityIds: [],
    },
  ];

  const handleActivityType = (type) => {
    switch (type) {
      case 0:
        return "Video";
      case 1:
      case 6:
        return "Multiple Choice";
      case 2:
      case 3:
      case 7:
        return "General";
      case 4:
        return "File";
      case 5:
        return "Link";
      default:
        return null;
    }
  };

  const [initialLoad, setInitialLoad] = useState(true);
  const [activityPool, setActivityPool] = useState([]);
  const [curriculumName, setCurriculumName] = useState("");
  const [themes, setThemes] = useState(themesDefault);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewDialogInfo, setPreviewDialogInfo] = useState(null);
  const handlePreviewClose = () => {
    setPreviewDialog(false);
  };

  const [addActivityDialog, setAddActivityDialog] = useState(false);
  const [addActivityDialogInfo, setAddActivityDialogInfo] = useState(null);
  const handleAddActivityDialogOpen = (themeId) => {
    setAddActivityDialogInfo(themeId);
    setAddActivityDialog(true);
  };
  const handleAddActivityDialogClose = () => {
    setAddActivityDialog(false);
  };

  let { id } = useParams();
  useEffect(() => {
    if (id) {
      if (id !== "new") {
        /// Read curriculum
        // async function getCurriculum(id) {
        //   const response = await ActivityServices.getCurriculum(id);
        //   if (response) {
        //     setCurriculumName(response.name);
        //     setActivities(response.activities);
        //   }
        // }
        // getCurriculum(id);
      }
    }

    async function getAdminActivities() {
      const activityPoolData = await ActivityServices.readAllAdminActivities();
      setActivityPool(activityPoolData);
    }

    getAdminActivities();
    setInitialLoad(false);
  }, [id]);

  const handleUpdateCurriculumThemeName = async (id, name) => {
    const idIndex = themes.findIndex((theme) => theme.id === id);
    const newThemes = update(themes, {
      [idIndex]: {
        name: { $set: name },
      },
    });
    setThemes(newThemes);
  };
  const handleUpdateCurriculumThemeActivities = async (id, activityIds) => {
    const idIndex = themes.findIndex((theme) => theme.id === id);
    const newThemes = update(themes, {
      [idIndex]: {
        activityIds: { $set: activityIds },
      },
    });
    setThemes(newThemes);
    setAddActivityDialog(false);
  };

  const handleCreateCurriculum = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const curriculum = {
      name: curriculumName,
      themes,
    };

    await ActivityServices.createCurriculum(curriculum);

    setSuccess(true);
    setLoading(false);
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ height: "52px" }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              {"Create Curriculum"}
            </div>
          </div>
        </Grid>
        {success ? (
          <Grid item md={12}>
            <div className="my-4 text-black">
              Created curriculum successfully!
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-info shadow-none"
              component={NavLink}
              to="/admin-activities"
            >
              <span className="btn-wrapper--label">Back to Activities</span>
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item md={12}>
              <Card
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid rgba(0,0,0,.1)",
                }}
                className="p-4"
              >
                <Grid container spacing={6} className="mb-4">
                  <Grid item md={12}>
                    <InputLabel
                      style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                      className="mb-3"
                      required={true}
                    >
                      {"Curriculum name"}
                    </InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={curriculumName}
                        type="text"
                        onChange={(e) => {
                          setCurriculumName(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} className="mt-4 pt-4">
                    <Grid container spacing={3}>
                      {themes.map((theme) => (
                        <ActivieThemeSection
                          key={theme.id}
                          theme={theme}
                          activityPool={activityPool.filter((act) =>
                            (theme.activityIds || []).find((o) => o === act.id)
                          )}
                          handleActivityType={handleActivityType}
                          handleUpdateCurriculumThemeName={
                            handleUpdateCurriculumThemeName
                          }
                          handleAddActivityDialogOpen={
                            handleAddActivityDialogOpen
                          }
                          setPreviewDialog={setPreviewDialog}
                          setPreviewDialogInfo={setPreviewDialogInfo}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item md={12}>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  variant="contained"
                  size="medium"
                  className="btn-success shadow-none ml-4"
                  disabled={!curriculumName}
                  onClick={handleCreateCurriculum}
                >
                  {loading && (
                    <span className="mr-2">
                      <CircleLoader size={10} color="#fff" />
                    </span>
                  )}
                  <span className="btn-wrapper--label">Create Curriculum</span>
                </Button>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog
        open={previewDialog}
        onClose={handlePreviewClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "none",
          }}
          onClose={handlePreviewClose}
          row={true}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "rgb(42, 42, 47)",
            }}
          >
            {previewDialogInfo ? `Preview: ${previewDialogInfo.name}` : ""}
          </div>
        </DialogTitle>
        <DialogContent dividers={true} className="pb-4 pt-4">
          {previewDialogInfo ? (
            <PreviewActivity activity={previewDialogInfo} />
          ) : null}
        </DialogContent>
      </Dialog>
      <AddActivityDialog
        activityPool={activityPool}
        addActivityDialog={addActivityDialog}
        addActivityDialogInfo={addActivityDialogInfo}
        handleAddActivityDialogClose={handleAddActivityDialogClose}
        themes={themes}
        setPreviewDialog={setPreviewDialog}
        setPreviewDialogInfo={setPreviewDialogInfo}
        handleUpdateCurriculumThemeActivities={
          handleUpdateCurriculumThemeActivities
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminEditCurriculum);
