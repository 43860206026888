import {
  View,
  ScrollView,
  SafeAreaView,
  Image,
  Text,
  Linking,
} from "react-native";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import { unstable_createElement as createElement } from "react-native-web";

const Video = (props) => {
  const attrs = {
    key: props.key,
    src: props.source,
    poster: props.poster,
    controls: true,
    style: props.style,
    className: props.className,
  };
  return createElement("video", attrs);
};

const Iframe = (props) => {
  if (props.source) {
    const attrs = {
      key: props.key,
      src: `https://youtube.com/embed/${props.source}`,
      style: props.style,
      className: props.className,
    };
    return createElement("iframe", attrs);
  }
};

const activityRender = (activity, language) => {
  if (!activity) {
    return null;
  }
  switch (activity.type) {
    // Video
    case 0:
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            {activity.video ? (
              <Video
                key={language}
                source={
                  activity.video ? activity.video.preview : activity.video
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                className="rounded"
              />
            ) : (
              <Iframe
                key={language}
                source={activity.videoYoutube}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                className="rounded"
              />
            )}
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Next"}
              </Text>
            </View>
          </View>
        </>
      );
    // Quiz
    case 1:
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ border: "1px solid #d3d3d3", borderRadius: 4 }}>
              <Image
                source={
                  activity.thumbnail.preview
                    ? activity.thumbnail.preview
                    : activity.thumbnail
                }
                style={{ width: "100%", height: 160, borderRadius: 4 }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Start Multiple Choice"}
              </Text>
            </View>
          </View>
        </>
      );
    case 2:
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ border: "1px solid #d3d3d3", borderRadius: 4 }}>
              <Image
                source={
                  activity.thumbnail.preview
                    ? activity.thumbnail.preview
                    : activity.thumbnail
                }
                style={{ width: "100%", height: 160, borderRadius: 4 }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Upload an image of your activity"}
              </Text>
            </View>
          </View>
        </>
      );
    case 3:
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ border: "1px solid #d3d3d3", borderRadius: 4 }}>
              <Image
                source={
                  activity.thumbnail.preview
                    ? activity.thumbnail.preview
                    : activity.thumbnail
                }
                style={{
                  width: "100%",
                  height: 160,
                  borderRadius: 4,
                }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            {activity.repeatable ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <FontAwesomeIcon
                  icon={["fas", "sync"]}
                  size={15}
                  color={"#3C947F"}
                />
                <Text
                  style={{
                    color: "#3C947F",
                    fontSize: 13,
                    lineHeight: 20,
                    marginLeft: 5,
                  }}
                >
                  {"Repeatable once a day"}
                </Text>
              </View>
            ) : null}
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Next"}
              </Text>
            </View>
          </View>
        </>
      );
    case 4:
      const items = activity.schoolFiles
        ? activity.schoolFiles.length
          ? activity.schoolFiles
          : []
        : [];

      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ borderRadius: 4, border: "1px solid #d3d3d3" }}>
              <Image
                source={{ uri: activity.thumbnail }}
                style={{
                  width: "100%",
                  height: 160,
                  borderRadius: 4,
                }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                paddingVertical: 30,
              }}
            >
              {items.map((item) => (
                <View
                  style={{
                    paddingHorizontal: 0,
                    paddingVertical: 15,
                    marginBottom: 10,
                  }}
                  key={item.id}
                  onPress={() => Linking.openURL(item.preview)}
                >
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-start" }}
                  >
                    <View style={{ paddingRight: 15 }}>
                      {item.fileType.includes("video/mp4") ? (
                        <div
                          style={{
                            border: "1px solid #d3d3d3",
                            borderRadius: 20,
                            width: 40,
                            height: 40,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "play"]}
                            size={40}
                            color={"rgba(0, 0, 0, 0.54)"}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            border: "1px solid #d3d3d3",
                            borderRadius: 20,
                          }}
                        >
                          <Image
                            source={
                              item.fileType.includes("application/pdf")
                                ? require("../../assets/images/pdfStock.png")
                                : item.file
                                ? item.file.preview
                                : item.preview
                            }
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                      )}
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        numberOfLines={2}
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        {item.name}
                      </Text>
                      <Text numberOfLines={1}>
                        {`Added on ${moment(item.time).format("L")}`}
                      </Text>
                    </View>
                    <View style={{ paddingLeft: 10 }}>
                      <FontAwesomeIcon
                        icon={["fas", "arrow-right"]}
                        size={35}
                        color={"rgba(0, 0, 0, 0.54)"}
                      />
                    </View>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Next"}
              </Text>
            </View>
          </View>
        </>
      );
    case 5:
      const linkItems = activity.links
        ? activity.links.length
          ? activity.links
          : []
        : [];
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ border: "1px solid #d3d3d3", borderRadius: 4 }}>
              <Image
                source={
                  activity.thumbnail.preview
                    ? activity.thumbnail.preview
                    : activity.thumbnail
                }
                style={{
                  width: "100%",
                  height: 160,
                  borderRadius: 4,
                }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                paddingVertical: 30,
              }}
            >
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Links"}
              </Text>
              {linkItems.map((linkItem, index) => (
                <View
                  style={{
                    paddingHorizontal: 0,
                    paddingTop: 15,
                    paddingBottom: index === 0 ? 0 : 15,
                  }}
                  key={linkItem.id}
                  onPress={() => Linking.openURL(linkItem.url)}
                >
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-start" }}
                  >
                    <View>
                      <Text
                        numberOfLines={2}
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#0073bb",
                        }}
                      >
                        {linkItem.text}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Next"}
              </Text>
            </View>
          </View>
        </>
      );
    case 6:
    case 7:
      return (
        <>
          <View
            style={{
              flex: 1,
              paddingTop: 38,
              paddingBottom: 100,
              paddingHorizontal: 20,
            }}
          >
            <View style={{ border: "1px solid #d3d3d3", borderRadius: 4 }}>
              <Image
                source={
                  activity.thumbnail.preview
                    ? activity.thumbnail.preview
                    : activity.thumbnail
                }
                style={{
                  width: "100%",
                  height: 160,
                  borderRadius: 4,
                }}
              />
            </View>
            <View style={{ marginTop: 30 }}>
              <Text style={{ fontWeight: "500", fontSize: 14, lineHeight: 25 }}>
                {"Description"}
              </Text>
              <Text style={{ fontSize: 13, fontWeight: "400", lineHeight: 20 }}>
                {parse(activity.information ? activity.information : "")}
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingVertical: 10,
                borderRadius: 10,
                marginTop: 50,
                backgroundColor: "#18A9A9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 25,
                  color: "#fff",
                  fontWeight: "500",
                }}
              >
                {"Next"}
              </Text>
            </View>
          </View>
        </>
      );
    default:
      return null;
  }
};

const PreviewApp = (props) => {
  const { activity, language } = props;

  return (
    <>
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <ScrollView style={{ flex: 1, backgroundColor: "#FFF" }}>
          <View
            style={{
              backgroundColor: "#18A9A9",
              paddingTop: 50,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color: "#fff",
              }}
            >
              {activity.name}
            </Text>
          </View>
          {activityRender(activity, language)}
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

export default PreviewApp;
