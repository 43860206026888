import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Document,
  pdf,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Chart from "react-apexcharts";
import Parent_Parties_Horizontal from "../../assets/Parent_Parties_Horizontal.png";
import {
  sceneSelector,
  appSelector,
  userSelector,
  ParentPartiesAPIs,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Grid,
  List,
  ListItem,
  Card,
  CardContent,
  Table,
  Avatar,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircleLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    width: "100%",
  },
  optionTextFieldFocused: {},
  optionTextFieldUnderline: {
    "&:after": {
      borderBottom: `1px solid transparent !important`,
    },
    "&:before": {
      borderBottom: `1px solid transparent`,
    },
    "&:hover:not($disabled):not($focused):before": {
      borderBottom: `1px solid #d3d3d3 !important`,
    },
  },
}));

function AccordionResponse(props) {
  const { responseMain, classes } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <Accordion expanded={expanded} onChange={handleExpanded}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ borderBottom: "1px solid #d3d3d3" }}
        >
          <div className="pb-1">
            <div className="text-black">{responseMain.activityName}</div>
            <small className="text-black-50">
              {responseMain.responses
                ? responseMain.responses.length
                  ? `(${responseMain.responses.length} response${
                      responseMain.responses.length > 1 ? "s" : ""
                    })`
                  : ""
                : ""}
            </small>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            {responseMain.responses.map((response, index) => (
              <Card
                style={
                  index === 0
                    ? {
                        marginTop: 10,
                        border: "1px solid rgb(233 232 232)",
                      }
                    : {
                        marginTop: 20,
                        border: "1px solid rgb(233 232 232)",
                      }
                }
                key={`${response.id}`}
              >
                <CardContent>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      {response ? (
                        response.avatar ? (
                          <Avatar
                            src={response.avatar}
                            style={{ width: 30, height: 30 }}
                            className="mr-2"
                          />
                        ) : null
                      ) : null}
                      <div className="text-black" style={{ fontSize: "14px" }}>
                        {response
                          ? response.username
                            ? response.username
                            : "Guardian"
                          : "Guardian"}
                      </div>
                    </div>
                    <Typography
                      style={{
                        color: "rgb(143, 143, 145)",
                        fontSize: "14px",
                        width: 200,
                        textAlign: "right",
                      }}
                      className="ml-2"
                    >
                      {moment(Number(response.time)).format("LLL")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    {response ? (
                      response.responses.url ? (
                        <div>
                          <a
                            href={response.responses.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            style={{
                              color: "#0073bb",
                              textDecoration: "underline",
                            }}
                          >
                            View Upload
                          </a>
                        </div>
                      ) : (
                        response.responses.map((question, index) => (
                          <div className={index !== 0 ? "mt-3" : ""}>
                            <div
                              style={{ fontSize: 14, color: "#000" }}
                              className="mb-1"
                            >
                              {question.question}
                            </div>
                            {question.options ? (
                              <FormControl
                                component="fieldset"
                                className="px-2 w-100"
                              >
                                <RadioGroup value={question.answer}>
                                  {question.options.map(
                                    (option, optionIndex) => (
                                      <FormControlLabel
                                        key={optionIndex}
                                        fullWidth={true}
                                        value={option.value}
                                        control={<Radio color="primary" />}
                                        style={
                                          option.id === question.answer
                                            ? {
                                                backgroundColor:
                                                  "rgba(24, 169, 169, 0.1)",
                                                width: "100%",
                                              }
                                            : { width: "100%" }
                                        }
                                        label={
                                          <div className="d-flex flex-row align-items-center justify-content-between">
                                            <div
                                              style={{ fontSize: 14 }}
                                              className="text-black-50"
                                            >
                                              {option.value}
                                            </div>
                                            {option.id === question.answer ? (
                                              <div>
                                                <Check
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                  }}
                                                  className="mr-2"
                                                  color="primary"
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        }
                                        checked={option.id === question.answer}
                                        disabled
                                        classes={{
                                          label: classes.formControlLabel,
                                        }}
                                      />
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            ) : (
                              <div
                                style={{ fontSize: 14 }}
                                className="text-black-50"
                              >
                                {question.answer}
                              </div>
                            )}
                            <Divider className="my-3" />
                          </div>
                        ))
                      )
                    ) : null}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: "30 20 30 20",
  },
});
const documentToPrint = (parentNames) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View>
            <Image
              style={{
                width: 205,
                height: 89,
              }}
              src={Parent_Parties_Horizontal}
            />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View>
              <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                Parent/Guardian Sign-in Sheet
              </Text>
            </View>
            <View style={{ padding: "10 0 0 0" }}>
              <Text style={{ fontSize: 15, fontWeight: "bold" }}>
                April 2024
              </Text>
            </View>
          </View>
        </View>
        <View style={{ padding: "20 0 20 0" }}>
          <View
            style={{
              borderTop: "1pt solid #d3d3d3",
              borderLeft: "1pt solid #d3d3d3",
              borderRight: "1pt solid #d3d3d3",
            }}
          >
            <View
              style={{
                borderBottom: "1pt solid #d3d3d3",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                  borderRight: "1pt solid #d3d3d3",
                  padding: "5 10 5 10",
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                  Printed Name
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  borderRight: "1pt solid #d3d3d3",
                  padding: "5 10 5 10",
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                  Children's Names
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  padding: "5 10 5 10",
                }}
              >
                <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                  Grade Level(s)
                </Text>
              </View>
            </View>
            {/* Parent Names */}
            {parentNames.map((parent, index) => (
              <View
                style={{
                  borderBottom: "1pt solid #d3d3d3",
                  display: "flex",
                  flexDirection: "row",
                }}
                key={parent.name}
              >
                <View
                  style={{
                    flex: 1,
                    borderRight: "1pt solid #d3d3d3",
                    padding: "5 10 5 10",
                  }}
                >
                  <Text style={{ fontSize: 10 }}>{`${index + 1}) ${
                    parent.name
                  }`}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    borderRight: "1pt solid #d3d3d3",
                    padding: "5 10 5 10",
                  }}
                >
                  <Text style={{ fontSize: 10 }}>{parent.children}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    padding: "5 10 5 10",
                  }}
                >
                  <Text style={{ fontSize: 10 }}>{parent.grades || "N/A"}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

let chartOptions = {
  stroke: {
    show: true,
    curve: "smooth",
    width: 2,
  },
  chart: {
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
    animations: {
      enabled: false,
    },
  },
  fill: {
    opacity: 1,
  },
  // labels: dateRange,
  xaxis: {
    type: "datetime",
    labels: {
      trim: false,
      rotate: 0,
      minHeight: 40,
      hideOverlappingLabels: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: "5",
    borderColor: "rgba(125, 138, 156, 0.3)",
  },
  legend: {
    show: true,
  },
  markers: {
    size: 0,
  },
  tooltip: {
    x: {
      format: "dd MMMM yyyy",
    },
  },
};

function SchoolMonthlyAnalytics(props) {
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [timeFrame, setTimeFrame] = useState("August");
  const dateRangeValue = {
    startDate: null,
    endDate: null,
  };

  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const [parentNames, setParentNames] = useState([]);
  const [responses, setResponses] = useState([]);
  const [parentUploads, setParentUploads] = useState([]);
  const [analytics, setAnalytics] = useState(null);

  const [responsesExpanded, setResponsesExpanded] = useState(false);
  const handleResponsesExpanded = () => {
    setResponsesExpanded(!responsesExpanded);
  };

  const [uploadsExpanded, setUploadsExpanded] = useState(false);
  const handleUploadsExpanded = () => {
    setUploadsExpanded(!uploadsExpanded);
  };

  const changeTimeFrame = async (timeFrame) => {
    const sceneId = props.scene ? props.scene.id : null;
    if (!sceneId) {
      return;
    }

    setTimeFrame(timeFrame);

    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const response = await ParentPartiesAPIs.getMonthlyData(
      sceneId,
      startDate,
      endDate
    );

    if (response.parentNames) {
      setParentNames(response.parentNames);
    }
    if (response.schoolActivityResponses) {
      setResponses(response.schoolActivityResponses);
    }
    if (response.parentUploads) {
      setParentUploads(response.parentUploads);
    }
  };

  useEffect(() => {
    async function getData(dateRange) {
      const goal = moment().set({ date: 15 }).valueOf();
      const closestMonth = dateRange.reduce((prev, curr) =>
        Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
      );

      setTimeFrame(closestMonth);

      const startDate = moment(closestMonth).startOf("month");
      const endDate = moment(closestMonth).endOf("month");

      const response = await ParentPartiesAPIs.getMonthlyData(
        sceneId,
        startDate,
        endDate
      );

      const result = await ParentPartiesAPIs.fetchSchoolDashboard(
        sceneId,
        startDate,
        endDate
      );

      setAnalytics(result);

      if (response.parentNames) {
        setParentNames(response.parentNames);
      }
      if (response.schoolActivityResponses) {
        setResponses(response.schoolActivityResponses);
      }
      if (response.parentUploads) {
        setParentUploads(response.parentUploads);
      }

      setInitialLoad(false);
    }

    if (sceneId && props.app.dateRange) {
      getData(props.app.dateRange);
    }
  }, [props.app.dateRange, props.scene, sceneId]);

  const handlePrint = async () => {
    pdf(documentToPrint(parentNames || []))
      .toBlob()
      .then((blob) => {
        window.open(URL.createObjectURL(blob));
      });
  };

  const simpleInterface = props.scene
    ? props.scene.data
      ? props.scene.data.simpleInterface
      : false
    : false;

  const [chartView, setChartView] = useState("Sessions");
  const changeChartView = (chartType) => {
    setChartView(chartType);
  };
  const handleRenderChart = (chartView) => {
    switch (chartView) {
      case "Sessions":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "Sessions",
                data: analytics
                  ? analytics.sessions
                    ? analytics.sessions.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "Points":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
            }}
            series={[
              {
                name: "Points",
                data: analytics
                  ? analytics.points
                    ? analytics.points.data
                    : []
                  : [],
              },
            ]}
            type="line"
            height={300}
          />
        );
      case "Activities Completed":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "Activities Completed",
                data: analytics
                  ? analytics.activitiesCompleted
                    ? analytics.activitiesCompleted.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      case "New Guardians Enrolled":
        return (
          <Chart
            options={{
              ...chartOptions,
              chart: {
                ...chartOptions.chart,
                toolbar: {
                  show: mobile ? false : true,
                },
                zoom: {
                  enabled: mobile ? false : true,
                },
                animations: {
                  enabled: true,
                },
              },
              tooltip: {
                ...chartOptions.tooltip,
                y: {
                  formatter: function (value) {
                    return Math.round(value);
                  },
                },
              },
            }}
            series={[
              {
                name: "New Guardians Enrolled",
                data: analytics
                  ? analytics.newGuardiansEnrolled
                    ? analytics.newGuardiansEnrolled.data
                    : []
                  : [],
              },
            ]}
            type="bar"
            height={300}
          />
        );
      default:
        return null;
    }
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                View Statistics
              </div>
            </div>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={12} xl={12}>
              {mobile ? null : (
                <List
                  component="div"
                  className="nav-line-alt nav-line d-flex align-items-center mt-3"
                >
                  <ListItem button selected className="pr-2">
                    <span className="font-size-sm">{"Monthly Analytics"}</span>
                    <div className="divider" />
                  </ListItem>
                  {simpleInterface ? null : (
                    <ListItem
                      button
                      component={NavLink}
                      to={`/analytics/${sceneType}/${sceneId}`}
                      className="px-2"
                    >
                      <span className="font-size-sm">{"Reports"}</span>
                      <div className="divider" />
                    </ListItem>
                  )}
                  {simpleInterface ? null : (
                    <ListItem
                      button
                      component={NavLink}
                      to={`/parent-responses/${sceneType}/${sceneId}`}
                      className="px-2"
                    >
                      <span className="font-size-sm">{"Parent Responses"}</span>
                      <div className="divider" />
                    </ListItem>
                  )}
                </List>
              )}
            </Grid>
            {simpleInterface ? (
              <Grid item md={12}>
                <Grid container spacing={3}>
                  <Grid item md={6} xl={3}>
                    <Card
                      className="p-3"
                      style={{
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(0,0,0,.1)",
                      }}
                    >
                      <div className="text-black-50 pb-2 font-size-sm">
                        Sessions
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h3 className="display-4 mb-0 text-black font-weight-bold">
                          {analytics
                            ? analytics.sessions
                              ? analytics.sessions.value
                              : 0
                            : 0}
                        </h3>
                        <div className="font-size-sm ml-2">
                          {/* {analytics
                              ? analytics.reviews
                                ? handlePercent(
                                    analytics.reviews.rating.percentDiff
                                  )
                                : ""
                              : ""} */}
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item md={6} xl={3}>
                    <Card
                      className="p-3"
                      style={{
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(0,0,0,.1)",
                      }}
                    >
                      <div className="text-black-50 pb-2 font-size-sm">
                        Points
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h3 className="display-4 mb-0 text-black font-weight-bold">
                          {analytics
                            ? analytics.points
                              ? analytics.points.value
                              : 0
                            : 0}
                        </h3>
                        <div className="font-size-sm ml-2">
                          {/* {analytics
                              ? analytics.menu
                                ? handlePercent(
                                    analytics.menu.views.percentDiff
                                  )
                                : ""
                              : ""} */}
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item md={6} xl={3}>
                    <Card
                      className="p-3"
                      style={{
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(0,0,0,.1)",
                      }}
                    >
                      <div className="text-black-50 pb-2 font-size-sm">
                        Activities Completed
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h3 className="display-4 mb-0 text-black font-weight-bold">
                          {analytics
                            ? analytics.activitiesCompleted
                              ? analytics.activitiesCompleted.value
                              : 0
                            : 0}
                        </h3>
                        <div className="font-size-sm ml-2">
                          {/* {analytics
                              ? analytics.deals
                                ? handlePercent(
                                    analytics.deals.clicks.percentDiff
                                  )
                                : ""
                              : ""} */}
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item md={6} xl={3}>
                    <Card
                      className="p-3"
                      style={{
                        borderRadius: "0.25rem",
                        border: "1px solid rgba(0,0,0,.1)",
                      }}
                    >
                      <div className="text-black-50 pb-2 font-size-sm">
                        New Guardians Enrolled
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h3 className="display-4 mb-0 text-black font-weight-bold">
                          {analytics
                            ? analytics.newGuardiansEnrolled
                              ? analytics.newGuardiansEnrolled.value
                              : 0
                            : 0}
                        </h3>
                        <div className="font-size-sm ml-2">
                          {/* {analytics
                              ? analytics.reviews
                                ? handlePercent(
                                    analytics.reviews.reviews.percentDiff
                                  )
                                : ""
                              : ""} */}
                        </div>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Card
                    className="mb-spacing-6-x2 p-3 mt-4"
                    style={{
                      borderRadius: "0.25rem",
                      border: "1px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ boxShadow: "none" }}
                    >
                      <Select
                        value={chartView}
                        onChange={(e) => {
                          changeChartView(e.target.value);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={"Sessions"} dense>
                          Sessions
                        </MenuItem>
                        <MenuItem value={"Points"} dense>
                          Points
                        </MenuItem>
                        <MenuItem value={"Activities Completed"} dense>
                          Activities Completed
                        </MenuItem>
                        <MenuItem value={"New Guardians Enrolled"} dense>
                          New Guardians Enrolled
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {handleRenderChart(chartView)}
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid item md={12}>
                  <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                    <div>
                      <div
                        className="heading-4 text-left mb-0"
                        style={{
                          color: "#000",
                          textTransform: "none",
                        }}
                      >
                        {`Monthly Sign-Ins`}
                      </div>
                    </div>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ boxShadow: "none" }}
                      className="ml-2"
                    >
                      <Select
                        autoWidth={false}
                        value={timeFrame}
                        renderValue={(value) => {
                          return value === "Custom"
                            ? `${
                                dateRangeValue.startDate
                                  ? moment(dateRangeValue.startDate).format(
                                      "ll"
                                    )
                                  : ""
                              }${
                                dateRangeValue.endDate
                                  ? `${
                                      dateRangeValue.startDate
                                        ? ` - ${moment(
                                            dateRangeValue.endDate
                                          ).format("ll")}`
                                        : moment(dateRangeValue.endDate).format(
                                            "ll"
                                          )
                                    }`
                                  : ""
                              }`
                            : value === "School Year"
                            ? "School Year"
                            : moment(value).format("MMMM YYYY");
                        }}
                        onChange={(e) => {
                          changeTimeFrame(e.target.value);
                        }}
                        classes={{
                          select: classes.selectStyles,
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: classes.fixInput,
                              focused: classes.focused,
                              notchedOutline: classes.notchedOutline,
                            }}
                          />
                        }
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {(props.app.dateRange ? props.app.dateRange : []).map(
                          (date) => (
                            <MenuItem value={date} dense key={date}>
                              {moment(date).format("MMMM YYYY")}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <Card>
                    <CardContent>
                      <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                        <div>
                          <div
                            className="heading-4 text-left mb-0"
                            style={{
                              color: "#000",
                              textTransform: "none",
                            }}
                          >
                            {`${
                              timeFrame ? moment(timeFrame).format("MMMM") : ""
                            } Parent Parties Engage Sign-Ins`}
                          </div>
                          <small className="text-black-50">
                            {parentNames
                              ? parentNames.length
                                ? `(${parentNames.length} parent${
                                    parentNames.length > 1 ? "s" : ""
                                  })`
                                : ""
                              : ""}
                          </small>
                        </div>
                        <Button
                          className="btn-primary shadow-none ml-4"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={handlePrint}
                        >
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon
                              style={{ fontSize: "12px" }}
                              icon={["fas", "file-export"]}
                            />
                          </span>
                          <span
                            className="btn-wrapper--label"
                            style={{ fontSize: "12px" }}
                          >
                            Print
                          </span>
                        </Button>
                      </div>
                      <div className="table-responsive-md mb-4">
                        <Table
                          className="table table-bordered"
                          style={{
                            tableLayout: "fixed",
                          }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Children's Names</th>
                              <th scope="col">Grade Level(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {parentNames.map((parent) => {
                              return (
                                <tr style={{ height: "60px" }}>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {parent.name}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {parent.children}
                                  </td>
                                  <td
                                    style={{
                                      overFlow: "hidden",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {parent.grades || "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  {responses ? (
                    responses.length ? (
                      <Accordion
                        expanded={responsesExpanded}
                        onChange={handleResponsesExpanded}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          style={{ borderBottom: "1px solid #d3d3d3" }}
                        >
                          <div className="d-flex flex-row align-items-center justify-content-between py-2 w-100">
                            <div>
                              <div
                                className="heading-4 text-left mb-0"
                                style={{
                                  color: "#000",
                                  textTransform: "none",
                                }}
                              >
                                Parent Responses to Activites
                              </div>
                            </div>
                            <div>
                              <Button
                                className="btn-primary shadow-none"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon
                                    style={{ fontSize: "12px" }}
                                    icon={[
                                      "fas",
                                      responsesExpanded
                                        ? "angle-up"
                                        : "angle-down",
                                    ]}
                                  />
                                </span>
                              </Button>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="pt-4">
                            {responses.map((responseMain, index) => (
                              <AccordionResponse
                                responseMain={responseMain}
                                key={responseMain.activityName}
                                classes={classes}
                              />
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                        <div>
                          <div
                            className="heading-4 text-left mb-0"
                            style={{
                              color: "#000",
                              textTransform: "none",
                            }}
                          >
                            Parent Responses to Activites
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="d-flex flex-row mb-4 align-items-center justify-content-between">
                      <div>
                        <div
                          className="heading-4 text-left mb-0"
                          style={{
                            color: "#000",
                            textTransform: "none",
                          }}
                        >
                          Parent Responses to Activites
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item md={12}>
                  {parentUploads ? (
                    parentUploads.length ? (
                      <Accordion
                        expanded={uploadsExpanded}
                        onChange={handleUploadsExpanded}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          style={{ borderBottom: "1px solid #d3d3d3" }}
                        >
                          <div className="d-flex flex-row align-items-center justify-content-between py-2 w-100">
                            <div>
                              <div
                                className="heading-4 text-left mb-0"
                                style={{
                                  color: "#000",
                                  textTransform: "none",
                                }}
                              >
                                Parent Uploads
                              </div>
                              <small className="text-black-50">
                                {parentUploads
                                  ? parentUploads.length
                                    ? `(${parentUploads.length} upload${
                                        parentUploads.length > 1 ? "s" : ""
                                      })`
                                    : ""
                                  : ""}
                              </small>
                            </div>
                            <div>
                              <Button
                                className="btn-primary shadow-none"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon
                                    style={{ fontSize: "12px" }}
                                    icon={[
                                      "fas",
                                      uploadsExpanded
                                        ? "angle-up"
                                        : "angle-down",
                                    ]}
                                  />
                                </span>
                              </Button>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <ImageList
                              variant="woven"
                              cols={3}
                              gap={8}
                              rowHeight={400}
                            >
                              {parentUploads.map((item) => (
                                <ImageListItem key={item.img}>
                                  <img
                                    src={item.img}
                                    alt={item.id}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar
                                    title={item.name}
                                    subtitle={
                                      <span>Activity: {item.activityName}</span>
                                    }
                                    position="bottom"
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : null
                  ) : null}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(SchoolMonthlyAnalytics);
