import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  appSelector,
  userSelector,
  ActivityServices,
  SchoolServices,
} from "../../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  DialogContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink } from "react-router-dom";
import PreviewActivity from "./components/previewActivity";
import { CircleLoader } from "react-spinners";
import update from "immutability-helper";

import AddActivityDialog from "../AdminEditCurriculum/components/addActivityDialog";
import ActivityCard from "./components/activityCard";

// Responsive //
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Bugsnag from "@bugsnag/js";

const styles = (theme) => ({
  rootNormal: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButtonNormal: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButton: {
    position: "static",
    color: theme.palette.grey[500],
  },
});

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 420,
//     fontSize: theme.typography.pxToRem(12),
//     textAlign: "center",
//     border: "1px solid #dadde9",
//   },
// }))(Tooltip);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, row, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={row ? classes.root : classes.rootNormal}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={row ? classes.closeButton : classes.closeButtonNormal}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ActivieThemeSection = (props) => {
  const {
    theme,
    activityPool,
    handleUpdateCurriculumThemeName,
    handleAddActivityDialogOpen,
    setPreviewDialog,
    setPreviewDialogInfo,
  } = props;

  return (
    <Grid item md={12} xl={12}>
      <Card
        className="p-0"
        style={{
          borderRadius: "0.25rem",
          border: "1px solid rgba(0,0,0,.1)",
        }}
      >
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <div className="inline-flex align-items-center">
                <div
                  className="heading-4 text-left text-black"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    marginBottom: 0,
                  }}
                >
                  {theme.subtext}
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-info shadow-none ml-4"
              onClick={() => handleAddActivityDialogOpen(theme.id)}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "edit"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Edit Activities
              </span>
            </Button>
          </div>
          <div className="mt-3">
            <InputLabel shrink={true} required>
              Theme
            </InputLabel>
            <FormControl variant="outlined">
              <TextField
                variant="outlined"
                fullWidth
                value={theme.name}
                onChange={(e) => {
                  handleUpdateCurriculumThemeName(theme.id, e.target.value);
                }}
                size="small"
                margin="dense"
                inputProps={{
                  style: { width: "250px" },
                }}
              />
            </FormControl>
          </div>
        </div>
        <div className="pt-1 px-3 pb-4">
          <Grid container spacing={4}>
            {activityPool.map((activity) => (
              <Grid item md={6} lg={6} xl={6} sm={12} key={activity.id}>
                <ActivityCard
                  activity={activity}
                  setPreviewDialog={setPreviewDialog}
                  setPreviewDialogInfo={setPreviewDialogInfo}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
    </Grid>
  );
};

function AdminActivities(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [activityPool, setActivityPool] = useState([]);

  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewDialogInfo, setPreviewDialogInfo] = useState(null);
  const handlePreviewClose = () => {
    setPreviewDialog(false);
  };

  const [addActivityDialog, setAddActivityDialog] = useState(false);
  const [addActivityDialogInfo, setAddActivityDialogInfo] = useState(null);
  const handleAddActivityDialogOpen = (themeId) => {
    setAddActivityDialogInfo(themeId);
    setAddActivityDialog(true);
  };
  const handleAddActivityDialogClose = () => {
    setAddActivityDialog(false);
  };

  const handleUpdateCurriculumThemeName = async (id, name) => {
    const idIndex = curriculum.themes.findIndex((theme) => theme.id === id);
    const newThemes = update(curriculum.themes, {
      [idIndex]: {
        name: { $set: name },
      },
    });
    await ActivityServices.updateCurriculum(curriculum.id, {
      themes: newThemes,
    });
    setCurriculum({ ...curriculum, themes: newThemes });
  };
  const handleUpdateCurriculumThemeActivities = async (id, activityIds) => {
    const idIndex = curriculum.themes.findIndex((theme) => theme.id === id);
    const newThemes = update(curriculum.themes, {
      [idIndex]: {
        activityIds: { $set: activityIds },
      },
    });
    const newActs = newThemes.map((theme) => theme.activityIds).flat();
    await ActivityServices.updateCurriculum(curriculum.id, {
      themes: newThemes,
      activities: newActs.filter((i) => i !== undefined),
    });
    setCurriculum({ ...curriculum, themes: newThemes });
    setAddActivityDialog(false);
  };

  /* Curriculum */
  const [curriculums, setCurriculums] = useState([]);
  const [curriculum, setCurriculum] = useState(null);
  const handleCurriculumChange = async (e) => {
    if (curriculums) {
      if (e.target.value) {
        // Did not change curriculum
        if (e.target.value === curriculum.id) {
          return;
        }
        // Changed curriculum
        try {
          const curriculum = curriculums.find(
            (curriculum) => curriculum.id === e.target.value
          );
          setCurriculum(curriculum);
        } catch (err) {
          console.log(err);
          Bugsnag.notify(err);
        }
      }
    }
  };

  const [deleteCurriculumDialog, setDeleteCurriculumDialog] = useState(false);
  const [deleteCurriculumDialogInfo, setDeleteCurriculumDialogInfo] = useState(
    null
  );
  const handleDeleteCurriculumClose = () => {
    setDeleteCurriculumDialog(false);
  };

  const handleDeleteCurriculum = async () => {
    if (curriculums) {
      try {
        if (deleteCurriculumDialogInfo) {
          if (deleteCurriculumDialogInfo.id) {
            await ActivityServices.deleteCurriculum(deleteCurriculumDialogInfo);
            const newCurriculums = curriculums.filter(
              (p) => p.id !== deleteCurriculumDialogInfo.id
            );
            setCurriculums(newCurriculums);
            setCurriculum(
              newCurriculums
                ? newCurriculums[0]
                  ? newCurriculums[0]
                  : null
                : null
            );
            setDeleteCurriculumDialogInfo(null);
            setDeleteCurriculumDialog(false);
          }
        }
      } catch (err) {
        console.log(err);
        Bugsnag.notify(err);
      }
    }
  };

  useEffect(() => {
    try {
      async function getAdminActivities() {
        const curriculums = await SchoolServices.readCurriculums();
        setCurriculums(curriculums);
        setCurriculum(curriculums[curriculums.length - 1]);

        const activityPool = await ActivityServices.readAllAdminActivities();

        setActivityPool(activityPool);
        setInitialLoad(false);
      }

      getAdminActivities();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleActivityType = (type) => {
    switch (type) {
      case 0:
        return "Video";
      case 1:
      case 6:
        return "Multiple Choice";
      case 2:
      case 3:
      case 7:
        return "General";
      case 4:
        return "File";
      case 5:
        return "Link";
      default:
        return null;
    }
  };

  return !props.user ? null : initialLoad || props.app.isLoading ? (
    <Grid container className="my-spacing-6">
      <Grid item xs={12}>
        <div className="d-flex flex-row justify-content-center">
          <CircleLoader
            className="d-flex align-self-center"
            color={"#18a9a9"}
            loading={true}
            size={40}
          />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              Admin Activities
            </div>
            <Button
              variant="contained"
              size="medium"
              className="btn-success shadow-none ml-4"
              component={NavLink}
              to={{
                pathname: "/admin-add-activity",
                type: "add",
              }}
            >
              <span className="btn-wrapper--icon mr-1">
                <FontAwesomeIcon
                  style={{ fontSize: "12px" }}
                  icon={["fas", "plus"]}
                />
              </span>
              <span className="btn-wrapper--label" style={{ fontSize: "12px" }}>
                Create Activity
              </span>
            </Button>
          </div>
        </Grid>
        <Grid item md={12} xl={12}>
          {mobile ? null : (
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Activities"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-parent-evaluations`}
              >
                <span className="font-size-sm">Parent Evaluations</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-weekly-scorecard`}
              >
                <span className="font-size-sm">Weekly Scorecard</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-resources-uploads`}
              >
                <span className="font-size-sm">Guardian Uploads</span>
                <div className="divider" />
              </ListItem>
              {/* <ListItem
                button
                disableRipple
                className="px-2"
                component={NavLink}
                to={`/admin-archived-activities`}
              >
                <span className="font-size-sm">Archive</span>
                <div className="divider" />
              </ListItem> */}
            </List>
          )}
        </Grid>
        {curriculum ? (
          <Grid item md={12} className="mt-4 pt-4">
            <div className="text-black">
              <div className="d-flex flex-row align-items-center">
                <div className="d-flex flex-row align-items-center">
                  <div>Curriculum: </div>
                  <FormControl size="small" variant="outlined" className="ml-2">
                    <Select
                      value={curriculum.id}
                      onChange={(e) => {
                        handleCurriculumChange(e);
                      }}
                    >
                      {curriculums.map((curriculum) => (
                        <MenuItem value={curriculum.id}>
                          {curriculum.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="d-flex flex-row ml-3">
                  <IconButton
                    className="mr-2"
                    variant="contained"
                    onClick={() => {
                      setDeleteCurriculumDialogInfo(curriculum);
                      setDeleteCurriculumDialog(true);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "trash"]}
                      className="font-size-sm text-danger"
                    />
                  </IconButton>
                  <IconButton
                    className="mr-2"
                    variant="contained"
                    component={NavLink}
                    to={{
                      pathname: `/admin-edit-curriculum/new`,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "plus"]}
                      className="font-size-sm text-success"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
        ) : null}
        {/* Themes */}
        <Grid item md={12} className="mt-4 pt-4">
          <Grid container spacing={3}>
            {curriculum
              ? curriculum.themes
                ? curriculum.themes.map((theme) => (
                    <ActivieThemeSection
                      key={theme.id}
                      theme={theme}
                      activityPool={activityPool.filter((act) =>
                        (theme.activityIds || []).find((o) => o === act.id)
                      )}
                      handleActivityType={handleActivityType}
                      setPreviewDialog={setPreviewDialog}
                      setPreviewDialogInfo={setPreviewDialogInfo}
                      handleUpdateCurriculumThemeName={
                        handleUpdateCurriculumThemeName
                      }
                      handleAddActivityDialogOpen={handleAddActivityDialogOpen}
                    />
                  ))
                : null
              : null}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={previewDialog}
        onClose={handlePreviewClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "none",
          }}
          onClose={handlePreviewClose}
          row={true}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "rgb(42, 42, 47)",
            }}
          >
            {previewDialogInfo ? `Preview: ${previewDialogInfo.name}` : ""}
          </div>
        </DialogTitle>
        <DialogContent dividers={true} className="pb-4 pt-4">
          {previewDialogInfo ? (
            <PreviewActivity activity={previewDialogInfo} />
          ) : null}
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteCurriculumDialog}
        onClose={handleDeleteCurriculumClose}
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          className: "p-3",
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "rgb(42, 42, 47)",
            borderBottom: "none",
          }}
        >
          {`Are you sure you want to delete curriculum${
            deleteCurriculumDialogInfo
              ? `: ${deleteCurriculumDialogInfo.name}`
              : ""
          }?`}
        </DialogTitle>
        <DialogActions style={{ borderTop: "none" }} className="mt-4">
          <Button onClick={handleDeleteCurriculumClose}>Cancel</Button>
          <Button
            className="btn-danger"
            onClick={handleDeleteCurriculum}
            autoFocus
          >
            {"Delete Curriculum"}
          </Button>
        </DialogActions>
      </Dialog>
      <AddActivityDialog
        activityPool={activityPool}
        addActivityDialog={addActivityDialog}
        addActivityDialogInfo={addActivityDialogInfo}
        handleAddActivityDialogClose={handleAddActivityDialogClose}
        themes={curriculum.themes}
        setPreviewDialog={setPreviewDialog}
        setPreviewDialogInfo={setPreviewDialogInfo}
        handleUpdateCurriculumThemeActivities={
          handleUpdateCurriculumThemeActivities
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  user: userSelector(state),
});

export default connect(mapStateToProps, null)(AdminActivities);
